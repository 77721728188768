import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService, SubsManager } from '@tcc/ui';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, map } from 'rxjs/operators';
import { Lease, LeaseBonusStatusFilter, OrgSummary } from '../api-client';
import { catchReportResume } from '../shared/catch-report-resume-operator';
import { PageInfo } from '../shared/page-info';
import { RouteUtilService } from '../shared/route-util.service';
import { LeaseAction } from './lease-action';
import { leaseBonusStatusUiItems } from './lease-bonus-status-ui-items';
import { LeasesService } from './leases.service';

interface LeasesComponentRouteData {
  customerNameFilter?: string;
  leaseBonusStatus?: LeaseBonusStatusFilter;
  org?: OrgSummary;
  reload?: number;
}

@Component({
  selector: 'app-leases',
  templateUrl: './leases.component.html',
  styles: []
})
export class LeasesComponent implements OnDestroy, OnInit {
  readonly bonusStatusItems = leaseBonusStatusUiItems;
  pagedData: { data: Lease[]; pageInfo: PageInfo };
  readonly pageSize = 15;
  state: 'loading' | 'ready';

  bonusStatusSource = new BehaviorSubject<LeaseBonusStatusFilter>(LeaseBonusStatusFilter.AllEligible);
  customerNameFilterSource = new BehaviorSubject<string>(undefined);
  orgSource = new BehaviorSubject<OrgSummary>(undefined);
  pageSource = new BehaviorSubject<number>(1);
  reloadSource = new BehaviorSubject<number>(undefined);

  private subsMgr = new SubsManager();

  constructor(
    private leasesSvc: LeasesService, private notificationsSvc: NotificationsService,
    private route: ActivatedRoute, private router: Router, private routeUtil: RouteUtilService) {

  }

  get isRequiredFiltersSet() {
    return (this.bonusStatusSource.value && this.orgSource.value && this.pageSource.value != null);
  }
  ngOnInit() {
    this.state = 'ready';

    this.subsMgr.subs[0] = this.route.data.subscribe((x: LeasesComponentRouteData) => {
      this.bonusStatusSource.next(x.leaseBonusStatus || LeaseBonusStatusFilter.AllEligible);
      this.customerNameFilterSource.next(x.customerNameFilter);
      this.orgSource.next(x.org);
      if (x.reload) { this.reloadSource.next(x.reload); }
    });
    this.subsMgr.subs[1] = combineLatest([
      this.bonusStatusSource.pipe(distinctUntilChanged()),
      this.customerNameFilterSource.pipe(distinctUntilChanged()),
      this.orgSource.pipe(distinctUntilChanged()),
      this.pageSource,
      this.reloadSource.pipe(distinctUntilChanged())
    ]).pipe(debounceTime(10))
      .subscribe(() => this.updateLeases());
  }

  ngOnDestroy() {
    this.subsMgr.onDestroy();
  }

  onItemEvent(evt: { lease: Lease; eventType: LeaseAction }) {
    switch (evt.eventType) {
      case 'deleteBonus':
        this.router.navigate([{ outlets: { popups: ['bonus-delete', { bonusId: evt.lease.bonusId }] } }],
          { relativeTo: this.route, queryParamsHandling: 'merge' });
        break;
      case 'hideLease':
        this.router.navigate(
          ['', { outlets: { popups: ['lease-hide', { leaseId: evt.lease.leasingActivityId }] } }],
            { relativeTo: this.route, queryParamsHandling: 'merge'  });
        break;
      case 'newBonus':
        this.router.navigate(
          ['', { outlets: { popups: ['requests-edit', { leaseId: evt.lease.leasingActivityId }] } }],
            { relativeTo: this.route, queryParamsHandling: 'merge'  });
        break;
      case 'viewBonus':
        this.router.navigate(['./requests-view', { bonusId: evt.lease.bonusId }],
          { relativeTo: this.route, queryParamsHandling: 'merge' });
        break;

    }
  }

  /**
   * sets the bonus status by updating the route
   */
  setBonusStatus(leaseBonusStatus: LeaseBonusStatusFilter) {
    this.routeUtil.updateRouteParams(this.route, { leaseBonusStatus }, true);
  }

  /**
   * sets the customer name filter by updating the route
   */
  setCustomerNameFilter(customerNameFilter: string) {
    this.routeUtil.updateRouteParams(this.route, { customerNameFilter }, true);
  }

  /** updates the route with the given org.  If no org is set, then unsets the current org in the route. */
  setOrg(org: OrgSummary | null | undefined) {
    this.router.navigate([], { relativeTo: this.route, queryParams: { orgCode: org?.orgCode ?? '' }, queryParamsHandling: 'merge' });
  }

  /**
   * sets the page number by updating the route
   */
  setPageNumber(pageNumber: number) {
    // maintaining pageNumber in route wasn't working
    this.pageSource.next(pageNumber);
  }

  private updateLeases() {
    this.subsMgr.cancel('updateLeases');
    this.state = 'ready';
    if (this.isRequiredFiltersSet) {
      const bonusStatus = this.bonusStatusSource.value;
      const org = this.orgSource.value;
      const pageNumber = this.pageSource.value;
      const customerNameFilter = this.customerNameFilterSource.value;

      this.state = 'loading';
      this.subsMgr.subs['updateLeases'] = this.leasesSvc.getLeases(
        [org.orgCode], bonusStatus, customerNameFilter, (pageNumber - 1) * this.pageSize, this.pageSize)
        .pipe(
          map(x => this.pagedData = x),
          catchReportResume(() => this.notificationsSvc.addError('Unable to load leases')),
          finalize(() => this.state = 'ready')
        ).subscribe();
    }
    else {
      this.pagedData = { data: [], pageInfo: new PageInfo(this.pageSize, 0, 0) };
    }
  }

}
