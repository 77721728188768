/* eslint-disable @typescript-eslint/member-ordering */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { PlacementArray } from '@ng-bootstrap/ng-bootstrap/util/positioning';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';


export interface FlexiListItem<T> {
  label?: string;
  value?: T;
}

export type FlexiListMode = 'combo' | 'select' | 'typeahead';

@Component({
  changeDetection: ChangeDetectionStrategy.Default,
  selector: 'tcc-flexi-list',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FlexiListControlComponent,
      multi: true
    }],
  styles: [`
.combo-mode > *:nth-child(1) { border-bottom-end-radius: 0; border-bottom-start-radius: 0; }
.combo-mode > *:nth-child(2) { border-top: 0; border-top-end-radius: 0; border-top-start-radius: 0; }
`],
  template: `
<span style="display: block; position: relative" [ngClass]="{'combo-mode': showTypeahead && showSelect}">
  <input type="text" *ngIf="showTypeahead"
          class="form-control form-control-sm"
          [ngClass]="cssClass"
          [disabled]="isDisabled"
          [ngModel]="models.view"
          [placeholder]="placeholder || ''"
          (ngModelChange)="setUncommitedModel($event);"
          (blur)="syncModelWithValuesSource(); propagateTouch();"
          [ngbTypeahead]="valuesTypeaheadSearch"
          (selectItem)="onTypeaheadSelect($event)"
          [placement]="typeaheadPlacement"
          (focus)="$any($event.target).select()" />
  <select *ngIf="showSelect"
          class="form-control form-control-sm"
          (blur)="propagateTouch()"
          [ngClass]="cssClass"
          [disabled]="isDisabled"
          [ngModel]="valuesSource.getMemberValue(models.data)"
          (ngModelChange)="onSelectModelChange($event)">
      <option *ngIf="nonMemberLabel" [ngValue]="undefined">{{nonMemberLabel}}</option>
      <option *ngFor="let v of valuesSource.members" [ngValue]="v.value">{{v.label}}</option>
  </select>
  <ng-container *ngIf="!showTypeahead && !showSelect">Invalid Source Mode: {{sourceMode || '(none)'}}</ng-container>
</span>
`
})
export class FlexiListControlComponent implements ControlValueAccessor {


  @Input()
  controlsClass: string;

  /** sets html input controls disabled attribute */
  isDisabled = false;

  /** the model that is being modified */
  models: { data: any; view?: string } = {
    data: undefined,
    view: undefined
  };

  /** the label to show for non-list members in "select" mode.  If undefined no-nonmember option is displayed. */
  @Input()
  nonMemberLabel: string;

  /** input placeholder text */
  @Input()
  placeholder: string;

  /** callback when any change occurs */
  propagateChange = (_: any) => { };
  /** callback when a touch event occurs */
  propagateTouch = () => { };

  @Input()
  sourceMode: FlexiListMode = 'select';

  valuesSource = new FlexiListSource();

  valuesTypeaheadSearch = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => this.valuesSource.getTypeaheadValues(term, this.typeaheadSize))
    );

  @ViewChild(NgbTypeahead) typeaheadInstance: NgbTypeahead;

  @Input() typeaheadPlacement: PlacementArray = 'auto';

  @Input() typeaheadSize = 10;

  constructor(private cd: ChangeDetectorRef, private el: ElementRef) {

  }

  /** css class from the host. */
  get cssClass() {
    const classes: string[] = [];
    if (this.el.nativeElement.className) {
      classes.push(this.el.nativeElement.className);
    }
    if (this.controlsClass) {
      classes.push(this.controlsClass);
    }
    return classes;
  }

  @Input()
  set itemSource(value: FlexiListItem<any>[] | string[]) {
    this.valuesSource.setSourceItems(value);
  }

  get showTypeahead() {
    return this.sourceMode === 'typeahead' || this.sourceMode === 'combo';
  }

  get showSelect() {
    return this.sourceMode === 'select' || this.sourceMode === 'combo';
  }

  onSelectModelChange(value: any) {
    this.setUncommitedModel(value);
    this.syncModelWithValuesSource();
  }

  onTypeaheadSelect(evt: { item: string; preventDefault: () => void }) {
    evt.preventDefault();
    this.setUncommitedModel(evt.item);
    this.syncModelWithValuesSource();
  }

  /** ControlValueAccessor: Registers propagateChange callback function */
  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  /** ControlValueAccessor: Registers propagateTouch callback function */
  registerOnTouched(fn: any) {
    this.propagateTouch = fn;
  }

  /**
   * ControlValueAccessor: set disabled for controls
   *
   * @param isDisabled
   */
  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  setUncommitedModel(value: string) {
    if (this.models.view !== value) {
      this.models.view = value;
      this.cd.detectChanges();
    }
  }
  /** Syncs the model with the values source */
  syncModelWithValuesSource() {
    const sourceValue = this.valuesSource.getMemberValue(this.models.view);
    const modelValue = (sourceValue) ? sourceValue : this.models.view;

    if (modelValue !== this.models.data) {
      this.models.data = modelValue;
      this.models.view = modelValue;
      this.propagateChange(this.models.data);
      this.cd.detectChanges();
    }
    else {
      this.setUncommitedModel(modelValue);
    }
  }
  /** Implementation of ControlValueAccessor.writeValue that sets the value of model */
  writeValue(obj: any) {
    this.models.data = obj;
    if (obj == null || typeof obj === 'string') {
      this.models.view = obj;
    }
    else {
      this.models.view = obj.toString();
    }

    this.cd.detectChanges();
  }

}

class FlexiListSource {
  members: FlexiListItem<any>[];

  /** index of all type ahead values referring to valuesAndNames */
  private valuesTypeaheadIndex = new Map<string, { isValue: boolean; index: number }>();

  /** all possible values for type ahead */
  private valuesTypeaheadSource: string[];

  /** performance degrades if the same list is getting set over and over.
   * Original values are stored so if they're resent on setSourceItems an update may be prevented */
  private originalItems: FlexiListItem<any>[] | string[];

  /**
   * in case a value doesn't match exactly, this will return the list member value as it is in the items collection
   */
  getMemberValue(value: string | number | any) {
    if (value == null) {
      return undefined;
    }
    if (typeof value === 'number') {
      value = value.toString();
    }
    if (typeof value === 'string' && value !== '') {
      value = value.trim().toLowerCase();
      if (this.valuesTypeaheadIndex.has(value)) {
        return this.members[this.valuesTypeaheadIndex.get(value).index].value;
      }
    }
    else {
      let found = this.members.find(x => x.value === value);
      if (!found) {
        const stringifiedValue = JSON.stringify(value);
        found = this.members.find(x => JSON.stringify(x) === stringifiedValue);
      }
      if (found) {
        return found.value;
      }
    }
    return undefined;
  }
  /**
   * returns typehead values from text;
   */
  getTypeaheadValues(term: string, maxItems: number) {
    if (!term || term === '') {
      return [];
    }
    term = term.trim().toLowerCase();
    const results: string[] = [];
    let indexItem: { isValue: boolean; index: number };
    let valueNameRecord: FlexiListItem<any>;

    for (const value of this.valuesTypeaheadSource) {
      if (value.indexOf(term) !== -1) {
        indexItem = this.valuesTypeaheadIndex.get(value);
        valueNameRecord = this.members[indexItem.index];
        results.push(indexItem.isValue ? valueNameRecord.value : valueNameRecord.label);
        if (results.length === maxItems) {
          break;
        }
      }
    }

    return results;
  }

  setSourceItems(items: FlexiListItem<any>[] | string[]) {
    if (items === this.originalItems || (this.isFlexiListItemArray(items) && this.isEqual(items, this.members))) {
      return;
    }

    this.valuesTypeaheadSource = [];
    this.valuesTypeaheadIndex.clear();

    if (!items || items.length === 0) {
      this.members = undefined;
      return;
    }

    let typeaheadValue: string;
    this.members = [];

    if (this.isFlexiListItemArray(items)) {
      this.setSourceItemsFromFlexiItemArray(items);
    }
    else {
      this.setSourceItemsFromStringArray(items as string[]);
    }

    this.valuesTypeaheadSource.sort((a, b) => a < b ? -1 : (a > b) ? 1 : 0);
  }

  /**
   * Adds a case insensitve value to typeaheads source if it doesn't already exist
   */
  private addTypeaheadValue(text: string, isValue: boolean, valuesAndNamesIndex: number) {
    if (text) {
      text = text.trim().toLowerCase();
      if (!this.valuesTypeaheadIndex.has(text)) {
        this.valuesTypeaheadSource.push(text);
        this.valuesTypeaheadIndex.set(text, { isValue, index: valuesAndNamesIndex });
      }
    }
  }

  private isEqual(a: FlexiListItem<any>[], b: FlexiListItem<any>[]) {
    if (!a && !b) {
      return true;
    }
    if (!a || !b || a.length !== b.length) {
      return false;
    }
    for (let i = 0; i < a.length; i++) {
      // eslint-disable-next-line eqeqeq
      if (a[i].label !== b[i].label && a[i].value != b[i].value) {
        return false;
      }
    }
    return true;
  }
  /**
   * returns true if items is an array whose first element isn't a string
   */
  private isFlexiListItemArray(items: string[] | FlexiListItem<any>[]): items is FlexiListItem<any>[] {
    return (items && (items.length === 0 || typeof items[0] !== 'string'));
  }

  private setSourceItemsFromStringArray(items: string[]) {
    for (const item of items) {
      this.members.push({ label: item, value: item });
      this.addTypeaheadValue(item, true, this.members.length - 1);
    }
  }

  private setSourceItemsFromFlexiItemArray(items: FlexiListItem<any>[]) {
    let itemTextValue: string;

    for (const item of items) {
      itemTextValue = (typeof item.value === 'string') ? item.value : (item.value as Record<string, unknown>).toString();
      this.members.push({ label: item.label || itemTextValue, value: item.value });
      this.addTypeaheadValue(itemTextValue, false, this.members.length - 1);
      this.addTypeaheadValue(item.label, false, this.members.length - 1);
    }
  }
}
