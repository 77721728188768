<table class="table table-striped table-bordered table-hover table-sm small">
  <thead>
    <tr>
      <th>Bonus ID</th>
      <th>Created On</th>
      <th>Bonus Type</th>
      <th>Lease Type</th>
      <th class="td-min">Status</th>
      <th class="td-min"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let row of listRows">
      <td [title]="row.hasFailedAudits ? 'This bonus has some audits issues.  View it for more details.' : ''">
        {{row.bonusId}}
         <i *ngIf="row.hasFailedAudits" class="fa-fw fas fa-exclamation-triangle text-warning"></i>
      </td>
      <td>{{row.createdOn | date: 'M/d/yyyy'}}</td>
      <td>{{(bonusTypeMap$ | async)?.get(row.bonusTypeId)?.name || 'Unknown' }}</td>
      <td>{{row.leaseType}}</td>
      <td class="text-center td-min">
        <span [ngSwitch]="row.bonusStatus" [title]="row.bonusStatus | tccHumanCase">
          <app-status-icon [status]="row.bonusStatus"></app-status-icon>
        </span>
      </td>
      <td class="td-min">
        <span class="btn-group btn-group-sm" style="vertical-align:top">
          <button type="button" class="btn px-1 py-0 btn-info" title="View Bonus"
                  (click)="emitEvent(row, 'viewBonus')">
            <i class="fa fa-list fa-fw"></i>
          </button>
          <button type="button" class="btn px-1 py-0 btn-danger" title="Delete Bonus"
                  (click)="emitEvent(row, 'deleteBonus')" *ngIf="!isPaid(row)">
            <i class="fa fa-trash fa-fw"></i>
          </button>
        </span>
      </td>
    </tr>
  </tbody>
</table>
