import { RewardType } from '../api-client';

export class RewardTypeUi {
  /** information about how the enum should appear in the ui. */
  static readonly rewardTypeUiInfo = [
    { rewardType: RewardType.Monetary, label: 'Monetary', format: '1.2-2', prefix: '$', batchIconClass: 'fas fa-cloud-download-alt fa-fw' },
    { rewardType: RewardType.PTO, label: 'PTO', format: '', prefix: 'Hrs', batchIconClass: 'far fa-arrow-alt-circle-down'  },
    { rewardType: RewardType.Unknown, label: 'Unknown', format: '', prefix: '' }
  ];

  /** rewardTypeUI keyed by enum */
  static readonly rewardTypeUiMap = new Map(RewardTypeUi.rewardTypeUiInfo.map(x => [x.rewardType, x]));

}

