import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { ApiClient, Employee, EmployeeSummaryDated } from '../api-client';

@Injectable()
export class EmployeesService {

  /** all current employee records */
  readonly employees$ = this.apiClient.allemployees(undefined).pipe(
    map(x => this.sortEmployees(x.result)),
    shareReplay(1)
  );

  /** employees mapped by employee id */
  readonly empMap$ = this.employees$.pipe(
    map(x => new Map(x.map(y => [y.employeeId, y]))),
    shareReplay(1)
  );

  readonly empEmailMap$ = this.employees$.pipe(
    map(x => new Map(x.filter(y => !!y.email).map(y => [y.email, y]))),
    shareReplay(1)
  );

  private activeOnEmployeeCache: { [timestamp: number]: Observable<EmployeeSummaryDated[]> } = {};
  constructor(private apiClient: ApiClient) { }

  getEmployee(employeeId: number) {
    return this.empMap$.pipe(map(m => m.get(employeeId)));
  }
  getEmployeesActiveOn(activeOn: Date) {
    const timestamp = activeOn.valueOf();
    if (!this.activeOnEmployeeCache[timestamp]) {
      this.activeOnEmployeeCache[timestamp] = this.apiClient.activeemployeesummary(activeOn).pipe(
        map(x => this.sortEmployeeSummary(x.result)),
        shareReplay(1)
      );
    }
    return this.activeOnEmployeeCache[timestamp];
  }

  private sortEmployees(emps: Employee[]) {
    return emps.sort((a, b) => {
      const aName = (a.name || '').toLowerCase();
      const bName = (b.name || '').toLowerCase();
      return (aName < bName) ? -1 : (aName > bName) ? 1 : 0;
    });
  }

  private sortEmployeeSummary(emps: EmployeeSummaryDated[]) {
    return emps.sort((a, b) => {
      const aName = (a.name || '').toLowerCase();
      const bName = (b.name || '').toLowerCase();
      return (aName < bName) ? -1 : (aName > bName) ? 1 : 0;
    });
  }
}
