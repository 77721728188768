<div *ngIf="allBonusTypes && allBonusTypes.length >= 1; else NoBonusTypes" class="row">
  <div ngbDropdown autoClose="outside" class="d-inline-block col">
    <button type="button" class="text-start form-control form-control-sm d-flex align-items-center " ngbDropdownToggle>
      <span class="flex-grow-1 flex-shrink-1">{{buttonLabel}}</span>
    </button>
    <div ngbDropdownMenu class="form-check pt-0 pb-0 overflow-auto " style="max-height: 15rem;">
      <ng-container *ngFor="let x of bonusTypesByCategoryMap | keyvalue">
        <label class="dropdown-item pt-0 pb-1 form-check-label">
          <input type="checkbox"
                 [id]="'cat-' + x.key"
                 #categoryCheckbox
                 (change)="handleCategoryToggle($event, x.key)" /> {{x.value[0].bonusType.categoryName}}
        </label>
        <label *ngFor="let y of x.value" class="dropdown-item form-check-label ps-5 pt-0 pb-2">
          <input type="checkbox" [(ngModel)]="y.checked" (ngModelChange)="handleBonusTypeToggle(x.key)" /> {{y.bonusType.name}}
        </label>
      </ng-container>
    </div>
  </div>
</div>
<ng-template #NoBonusTypes>
  None Available
</ng-template>
