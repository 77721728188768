import { Component, OnInit, OnDestroy } from '@angular/core';
import { take, map, finalize, switchMap, tap, filter, startWith, shareReplay, debounceTime } from 'rxjs/operators';
import { forkJoin, defer, BehaviorSubject } from 'rxjs';

import { OrgsService } from './orgs/orgs.service';
import { UsersService } from './users/users.service';
import { NotificationsService, SubsManager } from '@tcc/ui';
import { TransitionStateService } from './shared/transition-state.service';

import { UserAccess } from './users/user-access';
import { catchReportResume } from './shared/catch-report-resume-operator';
import { UserDetail } from './api-client';
import { environment } from '../environments/environment';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  hideNav: boolean;
  state$ = defer(() => this.preloadingCompleteSubject).pipe(
    filter(isComplete => isComplete),
    switchMap(() => this.transSvc.transition$.pipe(
      map((state) => (state.isTransitioning) ? 'transitioning' : 'ready')),
    ),
    startWith('preloading'),
    debounceTime(0),
    shareReplay(1)
  );
  access: UserAccess;

  private readonly preloadingCompleteSubject = new BehaviorSubject<boolean>(false);
  private readonly subsMgr = new SubsManager();

  constructor(
    private notificationsSvc: NotificationsService,
    private orgsSvc: OrgsService,
    private transSvc: TransitionStateService,
    private usersSvc: UsersService
  ) { }

  ngOnInit() {
    this.hideNav = true;

    this.subsMgr.addSub = this.usersSvc.me$.pipe(
      filter((u): u is Required<UserDetail> => !!u?.email),
      switchMap(() => forkJoin([
        this.orgsSvc.orgs$.pipe(take(1)),
        this.usersSvc.myAccess$.pipe(take(1))
      ])),
      tap(([_, access]) => {
        this.access = access;
        this.hideNav = false;
      }),
      finalize(() => this.preloadingCompleteSubject.next(true)),
      catchReportResume(err => {
        if (!err || !err['status'] || err['status'] != 401) {
          this.notificationsSvc.addError('There was an error loading the application.  Please refresh your browser.');
        }
      }),
      take(1)
      //tap(u => this.appInsightsRef.appInsights?.setAuthenticatedUserContext(u.email))
    ).subscribe();

    console.log("*****************************************");
    console.log(environment.apiBaseUrl);
    console.log(environment.appInsightsId);
    console.log(environment.envName);
    console.log(environment.production);    
  }

  ngOnDestroy() {
    this.subsMgr.onDestroy();
  }


}
