import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BatchesComponent } from './batches.component';
import { BatchesListComponent } from './batches-list.component';
import { ProcessingComponent } from './processing.component';
import { ProcessSummaryComponent } from './process-summary.component';
import { ProcessingService } from './processing.service';
import { ProcessTypeGuard } from './process-type.guard';
import { FormsModule } from '@angular/forms';
import { TccUiModule } from '@tcc/ui';
import { SharedModule } from '../shared/shared.module';
import { OrgsModule } from '../orgs/orgs.module';
import { BonusConfigModule } from '../bonus-config/bonus-config.module';
import { RouterModule } from '@angular/router';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { ProcessSummaryDetailComponent } from './process-summary-detail.component';
import { EmployeesModule } from '../employees/employees.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NgbPaginationModule,
    TccUiModule,
    SharedModule,
    BonusConfigModule,
    OrgsModule,
    EmployeesModule
  ],
  declarations: [
    BatchesComponent,
    BatchesListComponent,
    ProcessingComponent,
    ProcessSummaryComponent,
    ProcessSummaryDetailComponent
  ],
  providers: [
    ProcessingService,
    ProcessTypeGuard
  ]
})
export class ProcessingModule { }
