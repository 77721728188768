<tcc-table tableClass="table-sm small"
           [rows]="listRows"
           [columns]="listColumns">
  <ng-template cellTemplateName="customerName" let-row>
    <app-customer [customerName]="row.customerName" [customerCode]="row.customerCode"></app-customer>
  </ng-template>
  <ng-template cellTemplateName="bonusStatus" let-row>
    <app-status-icon [status]="row.bonusStatus"></app-status-icon>
  </ng-template>
  <ng-template cellTemplateName="actionButtons" let-row>
    <span class="btn-group btn-group-sm">
      <button type="button" class="btn px-1 py-0 btn-success" title="New Bonus" *ngIf="!hasBonus(row)"
              (click)="emitEvent(row, 'newBonus')">
         <i class="fa fa-plus fa-fw"></i>
      </button>
      <button type="button" class="btn px-1 py-0 btn-info" title="View Bonus" *ngIf="hasBonus(row)"
              (click)="emitEvent(row, 'viewBonus')">
        <i class="fa fa-list fa-fw"></i>
      </button>
      <button type="button" class="btn px-1 py-0 btn-warning" title="Hide Lease" *ngIf="!hasBonus(row)"
              (click)="emitEvent(row, 'hideLease')">
        <i class="far fa-eye-slash fa-fw"></i>
      </button>
      <button type="button" class="btn px-1 py-0 btn-danger" title="Delete Bonus" *ngIf="hasOpenBonus(row)"
              (click)="emitEvent(row, 'deleteBonus')">
        <i class="fa fa-trash fa-fw"></i>
      </button>
    </span>
  </ng-template>
</tcc-table>
