import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Employee } from '../api-client';
import { EmployeesService } from './employees.service';


@Injectable()
export class RecipientParamResolver implements Resolve<Employee | undefined>  {

  constructor(private empSvc: EmployeesService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<Employee | undefined> {
    const value = route.params['recipientId'];

    if (value) {
      return this.empSvc.empMap$.pipe(
        map(eMap => eMap.get(parseInt(value, 10)))
      );
    }
    return of(undefined);
  }
}
