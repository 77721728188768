<tcc-simplePanel>
  <div panel-header>
    Leases
  </div>
  <div panel-body>
    <div class="row pb-2">
      <label class="col-2 col-form-label" tccLabelForNext>Community</label>
      <div class="col">
        <app-org-selector [org]="orgSource.value" (orgChange)="setOrg($event)"></app-org-selector>
      </div>
    </div>
    <div class="row pb-2">
      <label class="col-2 col-form-label" tccLabelForNext>Status</label>
      <div class="col-4">
        <tcc-flexi-list [ngModel]="bonusStatusSource.value" (ngModelChange)="setBonusStatus($event)"
                       [itemSource]="bonusStatusItems" controlsClass="form-control form-control-sm"></tcc-flexi-list>
      </div>
      <label class="col-2 col-form-label" tccLabelForNext>Customer</label>
      <div class="col-4">
        <input type="text" class="form-control form-control-sm" [ngModel]="customerNameFilterSource.value" (ngModelChange)="setCustomerNameFilter($event)"
               [ngModelOptions]="{updateOn: 'blur'}" />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <app-leases-list [listRows]="pagedData?.data || []"
                         (itemEvent)="onItemEvent($event)">
        </app-leases-list>
      </div>
    </div>
    <tcc-loadingOverlay *ngIf="state != 'ready'"></tcc-loadingOverlay>
  </div>
  <div panel-footer>
    <div class="float-start">
      <ngb-pagination [collectionSize]="pagedData?.pageInfo?.totalCount || 0"
                      [page]="pagedData?.pageInfo?.pageNumber"
                      (pageChange)="setPageNumber($event)"
                      [pageSize]="pageSize"
                      [maxSize]="4" size="sm"></ngb-pagination>
    </div>
    <div class="float-end">
      <button type="button" class="btn btn-sm btn-primary" [disabled]="!orgSource.value?.orgCode"
              [routerLink]="['', { outlets: { popups: ['requests-edit'] } }]"
              [queryParams]="{ orgCode: orgSource.value?.orgCode  }" queryParamsHandling="merge">
        New Non-Lease Bonus
      </button>
    </div>
  </div>
</tcc-simplePanel>
<router-outlet></router-outlet>
