import { formatNumber } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { RewardType } from '../api-client';

/**
 * Creates a pipe that formats the reward type total
 *
 * @example {{ total | rewardTypeValue: rewardType }}
 */
@Pipe({ name: 'rewardTypeValue' })
export class RewardTypeValueConverterPipe implements PipeTransform {
  transform(value: number, rewardType: RewardType): string {
    switch (rewardType) {
      case RewardType.Monetary:
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
      default:
        return formatNumber(value, 'en-US', '1.0');
    }
  }
}
