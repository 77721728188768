/* eslint-disable @angular-eslint/no-output-rename */
/* eslint-disable @angular-eslint/no-input-rename */
import { Component, ElementRef, EventEmitter, forwardRef, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NotificationsService } from '@tcc/ui';
import { FileItem, FileLikeObject, FileUploader, FilterFunction } from 'ng2-file-upload';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { API_BASE_URL, ApiClient, Attachment } from '../api-client';
import { catchReportResume } from '../shared/catch-report-resume-operator';
import { FileSizePipe } from './file-size.pipe';
import { AttachmentsService } from './attachments.service';

@Component({
  selector: 'app-attachments',
  templateUrl: 'attachments.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AttachmentsComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AttachmentsComponent),
      multi: true
    }
  ]
})
export class AttachmentsComponent implements ControlValueAccessor, OnInit {

  @Input() attachments: Attachment[];
  @Input('label') labelText: string;
  @Input() readOnly: boolean;
  @Input() required: boolean;

  //@Output('uploader') exposedUploader = new EventEmitter<FileUploader>();
  @Output('onChange') uploadQueueChange = new EventEmitter<FileItem[]>();
  @Output() completeAll = new EventEmitter();

  @ViewChild('fileInput') fileInput: ElementRef;


  maxFileSize: number | undefined = environment.attachmentMaxSize;

  model: any;
  uploader: FileUploader = new FileUploader({
    allowedMimeType: environment.attachmentMimeTypes,
    disableMultipart: false,
    maxFileSize: this.maxFileSize,
    url: this.baseUrl + '/api/v1/attachments'
  });

  private fileSizePipe: FileSizePipe = new FileSizePipe();

  constructor(private apiClient: ApiClient, private attachmentSvc: AttachmentsService,
    private notificationsSvc: NotificationsService,
    @Inject(API_BASE_URL) private baseUrl: string) { }

  deleteAttachment(bonusAttachementId: number) {
    this.apiClient.deleteAttachment(bonusAttachementId).pipe(
      tap(() => {
        this.attachments = this.attachments.filter(x => x.bonusAttachementId === bonusAttachementId);
        this.notificationsSvc.addSuccess('Attachment Deleted');
      }),
      catchReportResume(() => this.notificationsSvc.addError('Failed deleting attachment'))
    ).subscribe();
  }
  handleRemove(item: FileItem) {
    this.uploader.removeFromQueue(item);
    this.emitCurrentQueue();
  }

  handleRemoveAll() {
    this.uploader.clearQueue();
    this.emitCurrentQueue();
  }

  ngOnInit() {
    this.uploader.onAfterAddingFile = () => {
      this.emitCurrentQueue();
    };
    this.uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: FilterFunction) => {
      let message = 'Couldn\'t add the selected file.';
      switch (filter.name) {
        case 'fileSize':
          const fileSize = this.fileSizePipe.transform(this.maxFileSize);
          message = `The selected file cannot be attached because it exceeds the maximum file size limit of ${fileSize}.`;
          break;
        case 'mimeType':
          message = 'The file type of the selected file is not allowed.';
      }
      this.notificationsSvc.addWarning(message);
    };
    this.uploader.onErrorItem = (item: FileItem) => {
      this.notificationsSvc.addError(`An error occured while trying to upload "${item.file.name}".`);
    };

    //

    this.uploader.onCompleteAll = () => this.completeAll.next();
  }

  /** Because the File Input is ugly we have a button that will then execute the FileUpload behavior. */
  openFileSelectDialog() {
    (this.fileInput.nativeElement as HTMLElement).click();
  }

  /** callback when any change occurs */
  propagateChange = (_: any) => { };

  /** callback when a touch event occurs */
  propagateTouch = () => { };

  /** ControlValueAccessor: Registers propagateChange callback function */
  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  /** ControlValueAccessor: Registers propagateTouch callback function */
  registerOnTouched(fn: any) {
    this.propagateTouch = fn;
  }

  validate() {
    return this.isValid()
      ? null
      : {
        requiredButNoFilesChosen: {
          valid: false
        }
      };
  }

  /** Implementation of ControlValueAccessor.writeValue that sets the value of model */
  writeValue() {
    // Intentionally empty.  Needed to satisfy interface.
  }

  private emitCurrentQueue() {
    this.propagateChange(this.uploader.queue.length);
    this.uploadQueueChange.emit(this.uploader.getNotUploadedItems());
  }

  private isValid() {
    return !this.required || (this.required && this.uploader.queue.length !== 0);
  }

}
