import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Employee } from '../api-client';
import { EmployeesService } from './employees.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-employee-dropdown',
  template: `

<ng-container [ngSwitch]="((employees$ | async) || []).length">
  <div class="form-control-plaintext form-control-sm" *ngSwitchCase="0">No Employees</div>
  <div class="form-control-plaintext form-control-sm" *ngSwitchCase="1">{{employee.name}}</div>
  <select class="form-control form-control-sm" *ngSwitchDefault
          [ngModel]="employee" (ngModelChange)="onSelectedEmployeeChange($event)">
    <option [ngValue]="undefined">{{noEmployeeLabel}}</option>
    <option *ngFor="let x of employees$ | async" [ngValue]="x">
      {{x.name}}
    </option>
  </select>
</ng-container>
`
})
export class EmployeeDropdownComponent {

  @Input()
  noEmployeeLabel = ' --select one -- ';

  @Input()
  employee: Employee;

  @Output()
  readonly employeeChange = new EventEmitter<Employee>();

  employees$ = this.empSvc.employees$.pipe(tap(x => this.setEmployees(x)));


  constructor(private empSvc: EmployeesService) { }

  onSelectedEmployeeChange(employee: Employee) {
    if (employee !== this.employee) {
      this.employee = employee;
      this.employeeChange.emit(employee);
    }
  }

  private setEmployees(employees: Employee[]) {
    if (!employees) {
      this.onSelectedEmployeeChange(undefined);

    }
 else if (employees.length === 1) {
      this.onSelectedEmployeeChange(employees[0]);

    }
 else if (employees && this.employee) {
      const matchedUser = employees.find(x => x.employeeId === this.employee.employeeId);
      this.onSelectedEmployeeChange(matchedUser);
    }
  }
}
