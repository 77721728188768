import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Attachment } from '../api-client';
import { AttachmentsService } from './attachments.service';

const blankFileUrl = '/images/blank-file.png';

@Component({
  selector: 'app-attachment-thumbnail',
  styleUrls: ['attachment-thumbnail.component.css'],
  templateUrl: 'attachment-thumbnail.component.html'
})
export class AttachmentThumbnailComponent implements OnChanges, OnInit {

  @Input() attachment: Attachment;
  @Input() readOnly: boolean;

  @Output() deleteConfirmed = new EventEmitter<Attachment>();

  isDeleting = false;
  thumbnailSrc: any;
  state: 'initializing' | 'loading' | 'ready' | 'error' = 'initializing';

  private window: Window;

  constructor(private attachmentSvc: AttachmentsService, @Inject(DOCUMENT) document: Document) {
    this.window = document.defaultView;
  }

  deleteComplete() {
    this.deleteConfirmed.emit(this.attachment);
  }

  deleteStart() {
    this.isDeleting = true;
    setTimeout(() => this.isDeleting = false, 10000);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['attachment'] && this.attachment) {
      if (this.attachment.isImage) {
        this.attachmentSvc.getSanitizedUrl(this.attachment.bonusAttachementId, true)
          .subscribe(url => this.thumbnailSrc = url, err => this.thumbnailSrc = blankFileUrl);
      }
 else {
        this.thumbnailSrc = blankFileUrl;
      }
    }
  }

  ngOnInit() {
    this.state = 'ready';
  }

  openAttachment() {
    this.state = 'loading';
    this.attachmentSvc.getUrl(this.attachment.bonusAttachementId)
      .subscribe(url => {
        this.state = 'ready';
        this.window.open(url);
      }, err => {
        this.state = 'error';
      });
  }

}
