import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeaseHideModalComponent } from './lease-hide-modal.component';
import { LeasesComponent } from './leases.component';
import { LeasesListComponent } from './leases-list.component';
import { LeaseParamResolver } from './lease-param.resolver';
import { LeasesService } from './leases.service';
import { SharedModule } from '../shared/shared.module';
import { TccUiModule } from '@tcc/ui';
import { OrgsModule } from '../orgs/orgs.module';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NgbPaginationModule,
    TccUiModule,
    SharedModule,
    OrgsModule
  ],
  declarations: [
    LeaseHideModalComponent,
    LeasesComponent,
    LeasesListComponent
  ],
  providers: [
    LeaseParamResolver,
    LeasesService
  ]
})
export class LeasesModule { }
