import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { OrgSummary } from '../api-client';
import { OrgsService } from './orgs.service';

@Injectable()
export class OrgParamResolver implements Resolve<OrgSummary> {
  private static readonly orgCodeCacheKey = 'OrgParamResolve/OrgCode';

  // retains the last selected org to use in case there is no org selected
  private cachedOrgCode: string;

  constructor(private orgsSvc: OrgsService, private router: Router) { }

  resolve(route: ActivatedRouteSnapshot) {
    if (('orgCode' in route.queryParams)) {
      const value = route.queryParams['orgCode'];
      if (value) {
        return this.orgsSvc.getOrgByOrgCode(value).pipe(tap(org => this.cacheOrgCode(org)));
      }
      else {
        // nullish is a valid result unsetting the cached value
        this.cacheOrgCode();
        return of(undefined);
      }
    }

    if (!this.cachedOrgCode) {
      // try to get orgCode from localStorage
      this.cachedOrgCode = localStorage.getItem(OrgParamResolver.orgCodeCacheKey);
    }

    if (this.cachedOrgCode) {
      return this.orgsSvc.getOrgByOrgCode(this.cachedOrgCode);
    }

    return of(undefined);
  }

  /**
   * Sets or removes cached org code depending on if org is set.
   */
  private cacheOrgCode(org?: OrgSummary) {
    if (org) {
      this.cachedOrgCode = org.orgCode;
      localStorage.setItem(OrgParamResolver.orgCodeCacheKey, org.orgCode);
    }
    else {
      this.cachedOrgCode = undefined;
      localStorage.removeItem(OrgParamResolver.orgCodeCacheKey);
    }
  }
}
