import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { BatchSummary, RewardType } from '../api-client';
import { TableColumn } from '@tcc/ui';
import { RewardTypeUi } from '../shared/reward-type-ui';
import { PayoutTypeUi } from '../shared/payout-type-ui';
import { BatchEventType } from './batches.component';

@Component({
  selector: 'app-batches-list',
  templateUrl: './batches-list.component.html',
  styles: []
})
export class BatchesListComponent implements OnInit {
  /** occurrs when page changed */
  @Output()
  readonly itemEvent = new EventEmitter<{ batch: BatchSummary; eventType: BatchEventType; rewardType?: RewardType }>();

  /** currently visible leases */
  @Input()
  listRows: BatchSummary[] = [];

  readonly listColumns: TableColumn<BatchSummary>[] = [
    { name: 'name', title: 'Name' },
    { name: 'isExportDisabled', title: 'Locked' },
    { name: 'createdOn', title: 'Created' },
    { name: 'lastExportOn', title: 'Last Export' },
    { name: 'total', title: 'Total', colClass: 'text-end' },
    { name: 'actionButtons', title: ' ', colClass: 'td-min pe-2 ps-2' }
  ];

  readonly payoutTypeUI = PayoutTypeUi.rewardTypeUiMap;
  readonly rewardTypeUi = RewardTypeUi.rewardTypeUiInfo.filter(x => x.rewardType !== RewardType.Unknown);
  readonly rewardTypeUiMap = RewardTypeUi.rewardTypeUiMap;

  constructor() { }

  ngOnInit() {

  }


  /**
   *
   * @param lease
   * @param eventName
   */
  emitEvent(batch: BatchSummary, eventType: BatchEventType, rewardType?: RewardType) {
    this.itemEvent.next({ batch, eventType, rewardType });
  }

  hasRewardPayouts(batch: BatchSummary, rewardType: RewardType) {
    return batch.rewardTypeSummary.some(x => x.rewardType === rewardType);
  }

}
