import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, forkJoin, combineLatest } from 'rxjs';
import { OrgSummary, EventType, PayoutType, RewardType } from '../api-client';
import { ActivatedRoute, Router } from '@angular/router';
import { BonusesService } from './bonuses.service';
import { BonusConfigService } from '../bonus-config/bonus-config.service';
import { BonusSummaryCreator, BonusSummaryReport, RecipientAndBonus } from './bonus-summary-creator';
import { TableColumn, TableComponent, FileDownloadService, NotificationsService, SubsManager } from '@tcc/ui';
import { SortUtil } from '../shared/sort-util';
import { DatePipe, DecimalPipe } from '@angular/common';
import { NoCommaPipe } from '../shared/no-comma.pipe';
import { map, finalize, debounceTime, tap } from 'rxjs/operators';
import { GetBonusesParams } from './get-bonuses-params';
import { RewardTypeUi } from '../shared/reward-type-ui';
import { PayoutTypeUi } from '../shared/payout-type-ui';

declare type DateType = 'CreatedOn' | 'ApprovedOn' | 'PaidOn';

@Component({
  selector: 'app-bonus-summary',
  templateUrl: './bonus-summary.component.html',
  styles: [`
.custom-checkbox {
  padding-top: 2.4rem;
  padding-start: 2rem;
}
`]
})
export class BonusSummaryComponent implements OnDestroy, OnInit {

  readonly longDateFormatter = ((dp) => (dt: Date) => dp.transform(dt, 'M/d/yyyy h:mm a'))(new DatePipe('en-US'));
  readonly amountFormatter =
    ((dp, nc) => (amt: number) => nc.transform(dp.transform(amt, '1.2-2')))(new DecimalPipe('en-US'), new NoCommaPipe());
  readonly rewardTypeUi = RewardTypeUi.rewardTypeUiInfo;
  readonly payoutTypeUiMap = PayoutTypeUi.payoutTypeUiMap;

  payoutTypes: PayoutType[] = [];
  payoutTypeMap: Map<number, PayoutType>;

  readonly recipientCols: TableColumn<RecipientAndBonus>[] = [
    {
      name: 'name', title: 'Name', contentResolver: (_, row) => row.recipient.name,
      sort: true, sorter: SortUtil.caseInsensitiveStringSortFactory<RecipientAndBonus>(x => x.recipient.name)
    },
    {
      name: 'bonusId', title: 'Bonus Id', contentResolver: (_, row) => row.bonus.bonusId.toString(),
      sort: true, sorter: SortUtil.numericSortFactory<RecipientAndBonus>(x => x.bonus.bonusId)
    },
    {
      name: 'bonusTypeName', title: 'Bonus Type', contentResolver: (_, row) => row.bonus.bonusTypeName,
      sort: true, sorter: SortUtil.caseInsensitiveStringSortFactory<RecipientAndBonus>(x => x.bonus.bonusTypeName)
    },
    {
      name: 'payoutType', title: 'Payout Type', contentResolver: (_, row) => this.payoutTypeMap.get(row.recipient.payoutTypeId).name,
      sort: true, sorter: SortUtil.valueSortFactory<RecipientAndBonus>(x => this.payoutTypeMap.get(x.recipient.payoutTypeId))
    },
    {
      name: 'title', title: 'Description', contentResolver: (_, row) => row.bonus.title,
      sort: true, sorter: SortUtil.caseInsensitiveStringSortFactory<RecipientAndBonus>(x => x.bonus.title)
    },
    {
      name: 'createdOn', title: 'Created', contentResolver: (_, row) => this.longDateFormatter(row.bonus.createdOn),
      sort: true, sorter: SortUtil.valueSortFactory<RecipientAndBonus>(x => x.bonus.createdOn)
    },
    {
      name: 'approvedOn',
      title: 'Approved',
      contentResolver: (_, row) => this.longDateFormatter(row.bonus.eventDates.get(EventType.Approved)),
      sort: true,
      sorter: SortUtil.valueSortFactory<RecipientAndBonus>(x => x.bonus.eventDates.get(EventType.Approved))
    },
    {
      name: 'deniedOn', title: 'Denied', contentResolver: (_, row) => this.longDateFormatter(row.bonus.eventDates.get(EventType.Denied)),
      sort: true, sorter: SortUtil.valueSortFactory<RecipientAndBonus>(x => x.bonus.eventDates.get(EventType.Denied))
    },
    {
      name: 'paidOn', title: 'Paid', contentResolver: (col, row) => this.longDateFormatter(row.bonus.eventDates.get(EventType.Paid)),
      sort: true, sorter: SortUtil.valueSortFactory<RecipientAndBonus>(x => x.bonus.eventDates.get(EventType.Paid))
    },
    {
      name: 'amount', title: 'Amount', contentResolver: (col, row) => this.amountFormatter(row.recipient.amount), colClass: 'text-end',
      sort: true, sorter: SortUtil.numericSortFactory<RecipientAndBonus>(x => x.recipient.amount)
    }
  ];

  @ViewChild(TableComponent)
  recipientTable: TableComponent;
  report: BonusSummaryReport;
  state: 'loading' | 'ready';

  dateTypeSource = new BehaviorSubject<DateType>('CreatedOn');
  maxDateSource = new BehaviorSubject<Date>(undefined);
  minDateSource = new BehaviorSubject<Date>(undefined);
  orgSource = new BehaviorSubject<OrgSummary>(undefined);
  showBilledByOther = new BehaviorSubject<boolean>(undefined);

  private resolvedParams: { dateType?: DateType; maxDate?: Date; minDate?: Date; org?: OrgSummary; billedByOther?: boolean } = {};
  private subsMgr = new SubsManager();

  constructor(private bonusConfigSvc: BonusConfigService, private bonusesSvc: BonusesService, private fileDownloadSvc: FileDownloadService,
    private notificationsSvc: NotificationsService, private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit() {
    this.state = 'loading';
    this.initDates();

    this.state = 'ready';
    this.subsMgr.addSub = this.route.data.subscribe((x: { org?: OrgSummary }) => this.orgSource.next(x.org));
    this.subsMgr.addSub = combineLatest([
      this.dateTypeSource,
      this.maxDateSource,
      this.minDateSource,
      this.orgSource,
      this.showBilledByOther
    ])
      .pipe(debounceTime(200))
      .subscribe(([dateType, maxDate, minDate, org, billedByOther]) => {
        this.resolvedParams = { dateType, maxDate, minDate, org, billedByOther };
        this.updateReport();
      });
  }
  ngOnDestroy() {
    this.subsMgr.onDestroy();
  }

  doExport() {
    const data = this.recipientTable.exportCsv();
    const fileName = `Recipient Detail for ${this.orgSource.value.orgCode}.csv`;
    this.fileDownloadSvc.startRawDownload(fileName, data, 'text/csv');
    this.notificationsSvc.addInfo(`'${fileName}' was created.  If download does not begin, make sure popups are allowed.`);
  }

  getRewardType(id: number) {
    return this.payoutTypes.find(x => x.payoutTypeId === id).rewardType;
  }

  /** updates the route with the given org.  If no org is set, then unsets the current org in the route. */
  setOrg(org: OrgSummary | null | undefined) {
    this.router.navigate([], { relativeTo: this.route, queryParams: { orgCode: org?.orgCode ?? '' }, queryParamsHandling: 'merge' });
  }

  private initDates() {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const pastDate = new Date(currentDate);
    pastDate.setMonth(currentDate.getMonth() - 1);
    this.maxDateSource.next(currentDate);
    this.minDateSource.next(pastDate);
  }
  private updateReport() {
    this.subsMgr.cancel('updateReport');
    this.state = 'ready';
    this.report = undefined;
    const hasDateParam = this.resolvedParams.minDate || this.resolvedParams.maxDate || false;
    if (this.resolvedParams.org && (!hasDateParam || this.resolvedParams.dateType)) {
      this.state = 'loading';
      const params: GetBonusesParams = {
        orgCodes: [this.resolvedParams.org.orgCode],
        includeResponsibleOrg: this.resolvedParams.billedByOther,
        projectionDetail: true
      };

      if (hasDateParam) {
        // make maxDate apply all the way to the max time of day.
        const maxDate = new Date(this.resolvedParams.maxDate.getFullYear(),
          this.resolvedParams.maxDate.getMonth(), this.resolvedParams.maxDate.getDate(), 23, 59, 59);
        switch (this.resolvedParams.dateType) {
          case 'ApprovedOn':
            params.approvedOnMin = this.resolvedParams.minDate;
            params.approvedOnMax = maxDate;
            break;
          case 'CreatedOn':
            params.createdOnMin = this.resolvedParams.minDate;
            params.createdOnMax = maxDate;
            break;
          case 'PaidOn':
            params.paidOnMin = this.resolvedParams.minDate;
            params.paidOnMax = maxDate;
            break;
        }
      }
      this.subsMgr.subs['updateReport'] = forkJoin(
        this.bonusesSvc.getBonuses(params, 0, 100000),
        this.bonusConfigSvc.bonusTypeMap$,
        this.bonusConfigSvc.payoutType$.pipe(tap(x => this.payoutTypes = x)),
        this.bonusConfigSvc.payoutTypeMap$.pipe(tap(x => this.payoutTypeMap = x)),
      ).pipe(
        map(([bonuses, bonusTypeMap]) => {
          const summaryCalc = new BonusSummaryCreator(bonusTypeMap, this.payoutTypeMap);
          this.report = summaryCalc.calculate(bonuses.data);
        }),
        finalize(() => this.state = 'ready')
      ).subscribe();
    }
  }
}



