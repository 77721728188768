import { Injectable } from '@angular/core';
import { AuthConfig } from '@tcc/ui';
import { environment } from '../environments/environment';

@Injectable()
export class ConfigService {

    private values: Configs = new Configs();
    constructor() { }

    load = async <D>(env: D): Promise<D> => {
        try {
                const response = fetch('assets/configs.json');
                const configs: D = await (await response).json();
                for (const key in configs) {
                if (Object.prototype.hasOwnProperty.call(configs, key)) {
                    const targetProp = env[key];
                    if (targetProp !== undefined) {
                        env[key] = configs[key];

                        switch(key) {
                            case 'apiBaseUrl':
                                this.values.apiBaseUrl = configs[key];
                                break;
                            case 'appInsightsId':
                                this.values.appInsightsId = configs[key];
                                break;
                            case 'envName':
                                this.values.envName = configs[key];
                                break;
                            case 'production':
                                this.values.production = configs[key];
                                break;
                            case 'attachmentMaxSize':
                                this.values.attachmentMaxSize = configs[key];
                                break;
                            case 'attachmentMimeTypes':
                                this.values.attachmentMimeTypes = configs[key];
                                break;
                        }
                    }
                }
            }
        }
        catch (e) {
            console.log(e);
        }
        return env;
    };

    // Gets a value of specified property in the configuration file
    get(key: any) {
        switch(key) {
            case 'apiBaseUrl':
                return this.values.apiBaseUrl;
            case 'appInsightsId':
                return this.values.appInsightsId;
            case 'envName':
                return this.values.envName;
            case 'production':
                return this.values.production;
            case 'attachmentMaxSize':
                return this.values.attachmentMaxSize;
            case 'attachmentMimeTypes':
                return this.values.attachmentMimeTypes;
        }
    }

    getAuthConfig(): AuthConfig {
        const authConfig = {
            clientId: 'acd2460a-2599-4636-acf5-49b44f11932b',
            logLevel: 2,
            silent_redirect_uri: window.location.origin + '/auth-silent-callback.html',
            protectedResourceMap: new Map([
              [
                this.values.apiBaseUrl + '/api',
                ['48b0d4a9-ac50-44f1-910b-c22a80624031/user_impersonation']
              ]
            ])
          } as AuthConfig;

        return authConfig;
    }
}

export function configFactory(config: ConfigService) {
    return () => config.load(environment);
}

export class Configs {
    apiBaseUrl: any;
    appInsightsId: any;
    envName: any;
    production: any;
    attachmentMaxSize: any;
    attachmentMimeTypes: any;
}
