import { Injectable } from '@angular/core';
import { of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiClient, ApiException, BonusCreationInfo } from '../api-client';
import { SwaggerExceptionWithServerInfo } from '../shared/exception-info';
import { PageInfo } from '../shared/page-info';
import { GetBonusesParams } from './get-bonuses-params';

@Injectable()
export class BonusesService {

  constructor(private apiClient: ApiClient) { }

  /**
   * deletes a bonus with the matching bonusId
   */
  deleteBonus(bonusId: number) {
    return this.apiClient.deleteBonus(bonusId).pipe(map(x => x.result));
  }

  /**
   * returns bonus matching bonusId.  If 404 is result from service returns null.
   *
   * @param bonusId
   */
  getBonus(bonusId: number) {
    return this.apiClient.getBonusById(bonusId).pipe(
      map(x => x.result),
      catchError(err => { //catchError<BonusDetail, BonusDetail>(err => {
        if (ApiException.isApiException(err)) {
          if (err.status === 404) {
            return of(undefined);
          }
        }
        return throwError(err);
      })
    );
  }

  getBonuses(params: GetBonusesParams, skip: number, top: number) {
    return this.apiClient.getBonuses(params.orgCodes, params.bonusStatus, params.bonusLeaseType, params.bonusTypeIds,
      params.recipientNameFilter, params.createdOnMin, params.createdOnMax,
      params.approvedOnMin, params.approvedOnMax, params.paidOnMin, params.paidOnMax, params.includeResponsibleOrg, params.recipientIds,
      params.projectionDetail, skip, top, undefined, true)
      .pipe(
        map(x => ({
          data: x.result,
          pageInfo: new PageInfo(top, skip, x.headers['$count'])
        }))
      );
  }

  submitBonus(bonus: BonusCreationInfo) {
    return this.apiClient.postBonus(bonus).pipe(
      map(x => x.result.id),
      catchError(err => {
        if (ApiException.isApiException(err) && err.status === 403) {
          return throwError(new SwaggerExceptionWithServerInfo(err));
        }
        return throwError(err);
      })
    );
  }
}
