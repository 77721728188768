import { Component, OnDestroy, Input } from '@angular/core';
import { RequestModelCommon, RequestVm } from './request-vm';
import { LeasesService } from '../leases/leases.service';
import { Lease } from '../api-client';
import { SubsManager } from '@tcc/ui';


@Component({
  selector: 'app-request-lease',
  templateUrl: './request-lease.component.html',
  styles: []
})
export class RequestLeaseComponent implements OnDestroy {

  @Input()
  set request(value: RequestModelCommon<any>) {
    this.updateRequest(value);
  }
  get request(): RequestModelCommon<any> {
    return this._request;
  }

  leaseDuplicates: Lease[];

  private _request;
  private subsMgr = new SubsManager();

  constructor(private leasesSvc: LeasesService) { }

  ngOnDestroy() {
    this.subsMgr.onDestroy();
  }

  private updateRequest(request: RequestModelCommon<any>) {
    this._request = request;
    this.leaseDuplicates = undefined;
    this.subsMgr.cancel('updatePossibleDups');
    if (request && request.lease && request.lease.isPossibleDuplicate) {
      this.subsMgr['updatePossibleDups'] = this.leasesSvc.getLeaseDuplicates(request.lease.leasingActivityId)
        .subscribe(x => this.leaseDuplicates = x);
    }
  }
}
