import { Observable, of, OperatorFunction } from 'rxjs';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: environment.appInsightsId
  }
});
/**
 * catches an error, reports it to application insights, and allows functionality to resume
 *
 * @param action a function that takes the error and does something with it.  Could return observable or value.
 */
export function catchReportResume<T>(action?: (err: any) => any): OperatorFunction<T, T> {
  return function(source$: Observable<T>): Observable<T> {
    return source$.pipe(catchError(err => {
      let result: T | Observable<T>;
      if (action) {
        result = action(err) || undefined;
      }
      if (err instanceof ErrorEvent) {
        if (err.error && err instanceof Error) {
          appInsights.trackException({ exception: err.error });
        }
 else {
          let msg: string;
          if (err.colno != null && err.lineno != null) {
            msg = `Error in HTML at ${err.lineno},${err.colno}`;
            if (err.message) {
              msg += ': ' + err.message;
            }
            appInsights.trackException({ error: new Error(msg) });
          }
          else if (err.message) {
            appInsights.trackException({ error: new Error(err.message) });
          }
        }
      }
      else if (err instanceof Error) {
        appInsights.trackException({ error: err });
      }
      else if (typeof err === 'string') {
        appInsights.trackException({ error: new Error(err) });
      }
      console.error(err);
      return (result instanceof Observable) ? result : of(result);
    }));
  };
}

