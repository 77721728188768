<tcc-simplePanel>
  <div panel-header>
    Batches
  </div>
  <div panel-body>
    <div class="row pb-2">
      <div class="col ">
        <div class="form-check">
          <label class="form-check-label">
            <input type="checkbox" class="form-check-input" [ngModel]="showLockedSource.value" (ngModelChange)="showLockedSource.next($event)">
            Show Locked Batches
          </label>
        </div>
      </div>
    </div>
    <app-batches-list [listRows]="pagedData?.data || []"
                      (itemEvent)="onItemEvent($event)"></app-batches-list>
    <tcc-loadingOverlay *ngIf="state != 'ready'"></tcc-loadingOverlay>
  </div>
  <div panel-footer>
    <ngb-pagination [collectionSize]="pagedData?.pageInfo?.totalCount || 0"
                    [page]="pagedData?.pageInfo?.pageNumber"
                    (pageChange)="setPageNumber($event)"
                    [pageSize]="pageSize"
                    [maxSize]="4" size="sm"></ngb-pagination>
  </div>
</tcc-simplePanel>
<app-modal *ngIf="batchToLock" [title]="'Lock ' + batchToLock.name + '?'" (modalResult)="onLockModalResult($event)">
  Are you sure you want lock batch {{batchToLock.name}}, created on {{batchToLock.createdOn | date : 'M/d/yyyy'}}?
  This will prevent the batch from being exported any further.
</app-modal>
