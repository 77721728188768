import { Injectable } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { filter, map, scan, shareReplay, startWith } from 'rxjs/operators';

export interface TransitionState {
  /** boolean indicating if the router is in the process of transitioning. */
  isTransitioning: boolean;
  /** The route that is being transitioned from, or was just transitioned from. */
  fromRoute: string;
  /** The route that is being transitioned to, or was just transitioned to. */
  toRoute: string;
}

/** Keeps track of everytime the route is change. */
@Injectable({
  providedIn: 'root'
})
export class TransitionStateService {

  private static readonly initialState: TransitionState = { isTransitioning: false, fromRoute: '', toRoute: '' };

  /** Notifies when the route is change and when the changes are complete. */
  readonly transition$ = this.router.events.pipe(
    filter((evt): evt is NavigationStart | NavigationEnd | NavigationCancel | NavigationError => evt instanceof NavigationStart
      || evt instanceof NavigationEnd || evt instanceof NavigationCancel || evt instanceof NavigationError),
    scan((state, evt) => {
      const nextState = { isTransitioning: (evt instanceof NavigationStart), fromRoute: state.priorUrl, toRoute: evt.url };
      const priorUrl = !nextState.isTransitioning ? nextState.toRoute : state.priorUrl;
      return { state: nextState, priorUrl };
    }, { state: TransitionStateService.initialState, priorUrl: '' }),
    map((internalState) => internalState.state),
    startWith({ ...TransitionStateService.initialState }),
    shareReplay(1)
  );

  constructor(private router: Router) {
    // subscribe so no events are missed.
    this.transition$.subscribe();
  }
}
