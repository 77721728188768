<ng-container *ngIf="request && request.lease; else NoLease">
  <div class="form-group row">
    <label class="col-2">Community</label>
    <div class="col">
      {{request.org?.name}} ({{request.org?.orgCode}})
    </div>
  </div>
  <div class="form-group row">
    <label class="col-2">Lease</label>
    <div class="col">
      {{request.lease.leaseType}} lease for
      <app-customer [customerName]="request.lease?.customerName" [customerCode]="request.lease?.customerCode" [showCode]="true">
      </app-customer>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-2">Term</label>
    <div class="col-4">{{request.lease.leaseFrom | date: 'M/d/y'}} - {{request.lease.leaseTo | date: 'M/d/y'}}</div>
    <label class="col-2 text-end">Occurred</label>
    <div class="col-4">{{request.lease.occurredOn | date: 'M/d/y'}}</div>
  </div>
  <div *ngIf="request.lease.spaceOption" class="form-group row">
    <label class="col-2">Space Opt</label>
    <div class="col">{{request.lease.spaceOption}}</div>
  </div>
  <div *ngIf="request.lease.isPossibleDuplicate" class="alert alert-danger text-center p-2" role="alert">
    <i class="fas fa-exclamation-triangle"></i> Lease may be a duplicate.  Please see possible duplicates below.
    <div>
      <table class="w-100" *ngIf="leaseDuplicates; else DuplicatesLoading">
        <tr *ngFor="let lease of leaseDuplicates">
          <td>{{lease.leasingActivityId}}</td>
          <td><app-customer [customerName]="lease.customerName" [customerCode]="lease.customerCode"></app-customer></td>
          <td>{{lease.leaseFrom | date: 'M/d/y'}} - {{lease.leaseTo | date: 'M/d/y'}}</td>
          <td><app-status-icon [status]="lease.bonusStatus"></app-status-icon></td>
        </tr>
      </table>
      <ng-template #DuplicatesLoading><i class="fa fa-spinner fa-pulse"></i> loading...</ng-template>
    </div>
  </div>
  <div *ngIf="request.lease?.isSameDayShowAndSign && request.bonusSchedule?.sameDayShowAndSignRate != 1"
    class="alert alert-warning text-center p-2" role="alert">
    Lease is Same Day Show and Sign -
    <ng-container *ngIf="request.bonusSchedule; else noSchedule">payout amounts may be adjusted by {{request.bonusSchedule.sameDayShowAndSignRate | percent:'1.0-2'}}.</ng-container>
    <ng-template #noSchedule>payout amounts may be higher.</ng-template>
  </div>
</ng-container>
<ng-template #NoLease>
  <div class="form-group row">
    <label class="col-2">Lease</label>
    <div class="col">Non-Lease Bonus</div>
  </div>
</ng-template>
