import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { NotificationsService } from '@tcc/ui';
import { forkJoin } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AccessType, Lease, OrgSummary } from '../api-client';
import { LeaseParamResolver } from '../leases/lease-param.resolver';
import { OrgParamResolver } from '../orgs/org-param.resolver';
import { UsersService } from '../users/users.service';


/**
 * This resolver acts as a guard as well as a resolver.
 * Gets required parameters for request editor, and ensures that the user can create a request given their user access.
 * */
@Injectable()
export class RequestEditorParamsResolver implements Resolve<{ lease: Lease; nonLeaseOrg: OrgSummary }> {

  constructor(
    private leaseParamResolver: LeaseParamResolver,
    private notificationsSvc: NotificationsService,
    private orgParamResolver: OrgParamResolver,
    private route: ActivatedRoute,
    private router: Router,
    private usersSvc: UsersService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return forkJoin([
      this.leaseParamResolver.resolve(route),
      this.orgParamResolver.resolve(route),
      this.usersSvc.myAccess$.pipe(take(1))
    ]).pipe(
      map(([lease, nonLeaseOrg, userAccess]) => {
        if (!lease && !nonLeaseOrg) {
          return this.handleError('Unable to find lease or organization to begin request.');
        }

        const orgCode = (lease) ? lease.orgCode : nonLeaseOrg.orgCode;
        if (userAccess.query(AccessType.Creator, undefined, orgCode).length === 0) {
          return this.handleError('You do not have access to edit or create bonus.');
        }

        return { lease, nonLeaseOrg };
      })
    );

  }

  private handleError(message: string) {
    this.notificationsSvc.addError(message);
    this.router.navigate(['/..'], { relativeTo: this.route });
    return undefined as { lease: Lease; nonLeaseOrg: OrgSummary };
  }

}
