import { RewardTypeUi } from './../shared/reward-type-ui';
import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { ProcessSummary } from './process-summary';
import { RewardType } from '../api-client';
import { PayoutTypeUi } from '../shared/payout-type-ui';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-process-summary-detail',
  template: `
<table class="table table-sm small table-striped text-end">
  <thead>
    <tr>
      <th></th>
      <ng-container *ngFor="let rt of rewardTypeUi">
        <th>{{rt.label + ' Total'}}</th>
      </ng-container>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let agg of processSummary.aggregates">
      <td class="text-start">{{agg.title}}</td>
      <ng-container *ngFor="let rt of rewardTypeUi">
        <td>
          {{agg.rewardTypes.get(rt.rewardType).total | rewardTypeValue:rt.rewardType}}
          {{payoutTypeUiMap.get(rt.rewardType).suffix}}
        </td>
      </ng-container>
    </tr>
  </tbody>
</table>
`
})
export class ProcessSummaryDetailComponent implements OnInit {
  @Input()
  processSummary: ProcessSummary;

  readonly payoutTypeUiMap = PayoutTypeUi.rewardTypeUiMap;
  readonly rewardTypeUi = RewardTypeUi.rewardTypeUiInfo.filter(x => x.rewardType !== RewardType.Unknown);

  constructor() { }

  ngOnInit() {
  }

}
