import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { concat, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiClient, BatchUpdateModel, EventType, ProcessBatch, ProcessResult, ProcessResultType, PayoutType } from '../api-client';
import { PageInfo } from '../shared/page-info';
import { BonusProcessAggregateState, BonusProcessState } from './bonus-process-state';
import { ProcessSummary } from './process-summary';

@Injectable()
export class ProcessingService {

  /** the maximum number of bonuses processed at once */
  bonusesPerBatch = 20;
  payoutTypes: PayoutType[] = [];

  private readonly dateToBatchIdFormatter =
    ((datePipe: DatePipe) => (dt: Date) => datePipe.transform(dt, 'yyyyMMdd'))(new DatePipe('en-US'));
  constructor(private apiClient: ApiClient) { }

  /**
   * Calculates ProcessSummary from bonuses
   *
   * @param bonuses all visible bonuses
   * @param totalBonusCount count of all bonuses
   * @param isProcessing are bonuses currently being processed, so that bonuses toAppend or toDeny are considered queued.
   */
  calculateProcessSummary(bonuses: BonusProcessState[], totalBonusCount: number, isProcessing: boolean, payoutTypes: PayoutType[]) {
    this.payoutTypes = payoutTypes;
    const summary = new ProcessSummary();
    const summaryMap = new Map<string, BonusProcessAggregateState>();
    const allAgg = getOrCreateAggregate('All Bonuses');
    const notProcessedAgg = getOrCreateAggregate('Not Processed');
    const approveProcessedAgg = getOrCreateAggregate('Processed Approvals');
    const denyProcessedAgg = getOrCreateAggregate('Processed Denials');
    if (!bonuses || !bonuses.length) {
      summary.hidden = totalBonusCount;
      return summary;
    }
    const queuedIncrement = isProcessing ? 1 : 0;

    summary.hidden = totalBonusCount - bonuses.length;
    for (const b of bonuses) {
      appendBonusAmounts(allAgg, b);
      if (b.processResultType === ProcessResultType.Succeeded) {
        summary.processed += 1;
        if (b.processAction === EventType.Approved || b.processAction === EventType.Paid) {
          appendBonusAmounts(approveProcessedAgg, b);
        }
        else if (b.processAction === EventType.Denied) {
          appendBonusAmounts(denyProcessedAgg, b);
        }
      }
      else if (b.processResultType === ProcessResultType.ValidationFailed || b.processResultType === ProcessResultType.Excepted) {
        summary.failed += 1;
        appendBonusAmounts(getOrCreateAggregate('Failure: ' + b.processResultMessage), b);
      }
      else if (b.processAction === EventType.Approved || b.processAction === EventType.Paid) {
        summary.toApprove += 1;
        summary.queued += queuedIncrement;
        appendBonusAmounts(notProcessedAgg, b);
      }
      else if (b.processAction === EventType.Denied) {
        summary.toDeny += 1;
        summary.queued += queuedIncrement;
        appendBonusAmounts(notProcessedAgg, b);
      }
      else {
        summary.noAction += 1;
      }
    }
    return summary;

    function getOrCreateAggregate(title: string) {
      if (!summaryMap.has(title)) {
        const aggState = summary.addBonusAggregate(title);
        summaryMap.set(title, aggState);
      }
      return summaryMap.get(title);
    }
    function appendBonusAmounts(appendTo: BonusProcessAggregateState | BonusProcessState, source: BonusProcessState) {
      for (const [payoutTypeId, amount] of source.payouts) {
        const rewardType = payoutTypes.find(x => x.payoutTypeId === payoutTypeId).rewardType;
        const value = appendTo.rewardTypes.get(rewardType)?.total || 0;
        appendTo.rewardTypes.set(rewardType, { total: value + amount });
      }
    }

  }

  /**
   * gets paged batches
   */
  getBatches(getLockedBatches: boolean, skip: number, top: number) {
    const isExportDisabled = (getLockedBatches) ? undefined : false;
    return this.apiClient.batches(isExportDisabled, skip, top, undefined, true).pipe(
      map(x => ({
        data: x.result,
        pageInfo: new PageInfo(top, skip, x.headers['$count'])
      }))
    );
  }

  getBatchDetail(batchId: number) {
    return this.apiClient.getBatchDetail(batchId).pipe(map(x => x.result));
  }


  processBonuses(bonuses: BonusProcessState[]) {
    const state: ProcessingAggregateState = {
      batchCount: 0,
      bonusCount: 0,
      failures: 0,
      processedBatchCount: 0,
      processedBonusCount: 0
    };

    const batches: ProcessBatch[] = [];
    // For approved bonuses the EventType on processAction is always Approved.
    // If the processType is Payouts then change the process action to Approved

    const groupedIds = new Map<EventType, number[]>();
    bonuses
      .filter(x => x.processAction !== undefined)
      .forEach(x => (groupedIds.get(x.processAction) || groupedIds.set(x.processAction, []).get(x.processAction)).push(x.bonusId));
    for (const [eventType, bonusIds] of Array.from(groupedIds.entries())) {
      batches.push(... this.bonusesToBatches(bonusIds, eventType));
      state.bonusCount += bonusIds.length;
    }
    state.batchCount = batches.length;

    const batchProcesses = batches.map(batch => this.apiClient.postBatch(batch).pipe(
        map(r => {
          state.processedBonusCount += r.result.results.length;
          state.processedBatchCount += 1;
          state.failures += r.result.results.filter(x => x.processResultType !== ProcessResultType.Succeeded).length;
          return { ...state, batchResults: r.result.results } as ProcessBatchesWithAggregateResults;
        }),
        catchError(err => {
          state.processedBonusCount += batch.bonusIds.length;
          state.processedBatchCount += 1;
          state.failures += batch.bonusIds.length;
          return of<ProcessBatchesWithAggregateResults>({
            ...state,
            batchResults: batch.bonusIds.map(
              x => ({ bonusId: x, processResultType: ProcessResultType.Excepted, message: 'Server Exception Occurred' } as ProcessResult))
          });
        })
      ));
    const queue = concat(...batchProcesses);
    return queue;
  }

  /** Updates a batch, all missing fields are ignored */
  updateBatchPartial(batchId: number, batch: Partial<BatchUpdateModel>) {
    return this.apiClient.patchBatch(batchId, { isExportDisabled: undefined, lastExportOn: undefined, name: undefined, ...batch })
      .pipe(map(x => x.result));
  }

  private bonusesToBatches(bonusIds: number[], eventType: EventType) {
    const batchName = this.dateToBatchIdFormatter(new Date());
    const batches: ProcessBatch[] = [];
    const remainingBonusIds = bonusIds.splice(0);

    while (remainingBonusIds.length > 0) {
      const batchBonusIds = remainingBonusIds.splice(0, this.bonusesPerBatch);
      batches.push({
        batchName,
        bonusIds: batchBonusIds,
        eventType
      });
    }
    return batches;
  }

}

export interface ProcessingAggregateState {
  batchCount: number;
  bonusCount: number;
  failures: number;
  processedBatchCount: number;
  processedBonusCount: number;
}

export interface ProcessBatchesWithAggregateResults extends ProcessingAggregateState {
  batchResults: ProcessResult[];
}
