import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { OrgsService } from './orgs.service';
import { OrgSummary } from '../api-client';
import { tap } from 'rxjs/operators';
import { SubsManager } from '@tcc/ui';

@Component({
  selector: 'app-org-selector',
  templateUrl: './org-selector.component.html'
})
export class OrgSelectorComponent implements OnInit, OnDestroy {
  private _org: OrgSummary;
  private subsMgr = new SubsManager();

  @Input()
  noOrgLabel = ' --select one -- ';

  @Input()
  get org() { return this._org; }
  set org(value: OrgSummary) {
    // set org to an org with match orgCode from list, or keep original value if no match is found.
    this._org = value && this.orgs.find(x => x.orgCode === value.orgCode) || value;
  }

  orgs: OrgSummary[] = [];

  @Output()
  readonly orgChange = new EventEmitter<OrgSummary>();

  constructor(private orgsSvc: OrgsService) { }

  ngOnInit() {
    this.subsMgr.addSub = this.orgsSvc.orgs$.pipe(tap(x => this.setOrgs(x))).subscribe();
  }

  ngOnDestroy() {
    this.subsMgr.onDestroy();
  }

  onSelectedOrgChange(org: OrgSummary) {
    if (org !== this.org) {
      this.org = org;
      this.orgChange.emit(org);
    }
  }

  private setOrgs(orgs: OrgSummary[]) {
    this.orgs = orgs || [];

    if (!orgs) {
      this.onSelectedOrgChange(undefined);
    }
    else if (orgs.length === 1) {
      this.onSelectedOrgChange(orgs[0]);
    }
    else if (this.org) {
      const matchedOrg = orgs.find(x => x.orgCode === this.org.orgCode);
      this.onSelectedOrgChange(matchedOrg);
    }
  }
}
