import { Injectable } from '@angular/core';
import { ApiClient, ApiException } from '../api-client';
import { UserAccess } from './user-access';
import { map, shareReplay, switchMap, catchError } from 'rxjs/operators';
import { AuthService } from '@tcc/ui';
import { of, throwError } from 'rxjs';

@Injectable()
export class UsersService {
  
  /** just me.  This is a live stream that can change as user access changes. */
  readonly me$ = this.authSvc.authResult$.pipe(
    switchMap(x => x ? this.apiClient.getCurrentUser() : of(undefined)),
    map(x => x?.result),
    catchError(err =>
      (ApiException.isApiException(err) && err.status === 401)
        ? of(undefined)
        : throwError(err)
    ),
    shareReplay(1)
  );

  /** my access.  This is a live continous stream that can change when user access changes */
  readonly myAccess$ = this.me$.pipe(map(x => new UserAccess(x)), shareReplay(1));

  /** array of all users */
  readonly users$ = this.apiClient.users().pipe(map(x => x.result), shareReplay(1));

  /** map of users keyed by userId. */
  readonly userMap$ = this.users$.pipe(
    map(x => new Map(x.map(y => [y.userId, y]))),
    shareReplay(1)
  );

  constructor(private apiClient: ApiClient, private authSvc: AuthService) { }

}
