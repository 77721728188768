import { Component, OnInit, OnDestroy } from '@angular/core';
import { OrgsService } from './orgs.service';
import { Observable, of } from 'rxjs';
import { OrgSummary, OrgOverview, LeaseBonusStatusFilter, LeaseType, BonusLeaseTypeFilter, BonusStatus } from '../api-client';
import { UsersService } from '../users/users.service';
import { ProcessType } from '../processing/process-type';
import { map, take } from 'rxjs/operators';
import { SubsManager } from '@tcc/ui';
import { Router } from '@angular/router';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styles: []
})
export class OverviewComponent implements OnInit, OnDestroy {
  overviews: (OrgOverview & OrgSummary)[];
  state: 'loading' | 'ready';

  private subsMgr = new SubsManager();

  constructor(private orgsSvc: OrgsService, private router: Router, private usersSvc: UsersService) {
  }

  /** number of days the overview looks back */
  get lookbackDays() {
    return this.orgsSvc.overviewLookbackDays;
  }

  ngOnInit() {
    this.state = 'loading';
    this.subsMgr.subs[0] = this.orgsSvc.getOverviewsFromLookbackDays().pipe(
      map(x => {
        this.overviews = x;
        this.state = 'ready';
      })
    ).subscribe();
  }

  ngOnDestroy() {
    this.subsMgr.onDestroy();
  }

  getLeasesWithoutBonusLink() {
    return ['/leases', { leaseBonusStatus: LeaseBonusStatusFilter.NoBonus }];
  }

  getNonLeaseBonusLink() {
    return ['/bonuses', { leaseType: BonusLeaseTypeFilter.NonLease }];
  }

  getUnapprovedLink$() {
    return this.usersSvc.myAccess$.pipe(
      take(1),
      map(x =>
        (x.isApprover)
          ? ['/approvals']
          : ['/bonuses', { bonusStatus: BonusStatus.Unapproved }]
      ));
  }

  getUnpaidLink$() {
    return this.usersSvc.myAccess$.pipe(
      take(1),
      map(x =>
        (x.isPayer)
          ? ['/payouts']
          : ['/bonuses', { bonusStatus: BonusStatus.Approved }]
      ));
  }
}
