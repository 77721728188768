import { ChangeDetectionStrategy, Component, OnInit, Input } from '@angular/core';
import { ProcessSummary } from './process-summary';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-process-summary',
  templateUrl: './process-summary.component.html',
  styleUrls:  ['./process-summary.component.css']
})
export class ProcessSummaryComponent implements OnInit {
  @Input()
  summary: ProcessSummary;

  constructor() { }

  ngOnInit() {
  }

  getBackgroundCssClass(ctx: ItemContext) {
    const suffix = this.getCssSuffix(ctx);
    return (suffix) ? 'bg-' + suffix : '';
  }
  getBorderCssClass(ctx: ItemContext) {
    return (!ctx.value || !ctx.valueCssSuffix)
      ? 'border border-top-0 border-start-0 border-end-0'
      : 'border border-' + ctx.valueCssSuffix;
  }
  getTextCssClass(ctx: ItemContext) {
    const suffix = this.getCssSuffix(ctx);
    return (suffix) ? 'text-' + suffix : '';
  }

  private getCssSuffix(ctx: ItemContext) {
    if (!ctx.value) {
      return 'muted';
    }
    else if (ctx.valueCssSuffix) {
      return ctx.valueCssSuffix;
    }
  }
}

interface ItemContext {
  label?: string;
  value?: number;
  valueCssSuffix?: string;
}
