import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService, SubsManager } from '@tcc/ui';
import { BehaviorSubject, combineLatest, merge, of, Subject, timer } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, mapTo, skip, switchMap, takeUntil, tap } from 'rxjs/operators';
import { BonusDetail, BonusLeaseTypeFilter, BonusStatus, BonusType, OrgSummary } from '../api-client';
import { catchReportResume } from '../shared/catch-report-resume-operator';
import { PageInfo } from '../shared/page-info';
import { RouteUtilService } from '../shared/route-util.service';
import { BonusAction } from './bonus-action';
import { bonusLeaseTypeUiItems } from './bonus-lease-type-ui-items';
import { bonusStatusUiItems } from './bonus-status-ui-items';
import { BonusesService } from './bonuses.service';
import { GetBonusesParams } from './get-bonuses-params';

interface BonusesComponentFilter {
  bonusStatus: BonusStatus;
  bonusTypes: BonusType[];
  leaseType: BonusLeaseTypeFilter;
  org?: OrgSummary;
  reload?: number;
  recipientNameFilter: string;
}
type BonusesComponentParameterData = Partial<BonusesComponentFilter>;

@Component({
  selector: 'app-bonuses',
  templateUrl: './bonuses.component.html',
  styles: []
})
export class BonusesComponent implements OnInit, OnDestroy {
  readonly bonusStatusItems = bonusStatusUiItems;

  readonly leaseTypeItems = bonusLeaseTypeUiItems;
  pagedData: { data: BonusDetail[]; pageInfo: PageInfo };
  readonly pageSize = 15;
  state: 'loading' | 'ready';

  readonly listParams = new BehaviorSubject<BonusesComponentFilter>({
    bonusStatus: BonusStatus.Unknown,
    bonusTypes: [],
    leaseType: BonusLeaseTypeFilter.All,
    org: undefined,
    recipientNameFilter: undefined,
    reload: undefined
  });

  readonly pageSource = new BehaviorSubject<number>(0);
  readonly recipientNameFilterSubject = new Subject<string>();
  readonly recipientNameFilterDebounceStartSubject = new Subject<string>();

  private subsMgr = new SubsManager();

  constructor(
    private bonusesSvc: BonusesService, private notificationsSvc: NotificationsService,
    private route: ActivatedRoute, private router: Router, private routeUtil: RouteUtilService) {

  }

  ngOnInit() {
    this.state = 'ready';
    this.subsMgr.addSub = this.route.params.subscribe(p => this.onParamsChange(p));


    this.subsMgr.addSub = this.route.data.pipe(
      tap((data: BonusesComponentParameterData) => {
        this.listParams.next({
          ...data,
          bonusStatus: data.bonusStatus || BonusStatus.Unknown,
          bonusTypes: data.bonusTypes || [],
          leaseType: data.leaseType || BonusLeaseTypeFilter.All,
          recipientNameFilter: data.recipientNameFilter || ''
        });
      })
    ).subscribe();

    /** Keep track of recipient filter changes */
    this.subsMgr.addSub = merge(
      this.recipientNameFilterSubject,
      this.recipientNameFilterDebounceStartSubject.pipe(
        switchMap(initial => timer(1000).pipe( // this is a cancellable debounceTime basically.  Should be an easier way
          mapTo(initial),
          takeUntil(this.recipientNameFilterSubject.pipe(skip(1)))
        ))
      )
    ).pipe(
      map(x => x.trim()),
      distinctUntilChanged(),
      tap(recipientNameFilter => this.routeUtil.updateRouteParams(this.route, { recipientNameFilter }, true))
    ).subscribe();

    this.subsMgr.addSub = combineLatest([
      this.listParams,
      this.pageSource
    ]).pipe(
      debounceTime(10),
      switchMap(([{ bonusStatus, bonusTypes, leaseType, org, recipientNameFilter }, pageNumber]) => {
        if (bonusStatus && org && pageNumber && leaseType) {
          this.state = 'loading';
          const getParams: GetBonusesParams = {
            bonusStatus,
            bonusTypeIds: bonusTypes.length ? bonusTypes.map(x => x.bonusTypeId) : undefined,
            bonusLeaseType: leaseType,
            orgCodes: [org.orgCode],
            recipientNameFilter: recipientNameFilter || '',
          };
          return this.bonusesSvc.getBonuses(getParams, (pageNumber - 1) * this.pageSize, this.pageSize);
        }
        else {
          return of({ data: [], pageInfo: new PageInfo(this.pageSize, 0, 0) });
        }
      }),
      tap(x => this.pagedData = x),
      catchReportResume(() => this.notificationsSvc.addError('Unable to load bonuses.  Please reload the page and try again.')),
      tap(() => this.state = 'ready')
    ).subscribe();
  }

  ngOnDestroy() {
    this.subsMgr.onDestroy();
  }

  onItemEvent(evt: { bonus: BonusDetail; eventType: BonusAction }) {
    switch (evt.eventType) {
      case 'deleteBonus':
        this.router.navigate([{ outlets: { popups: ['bonus-delete', { bonusId: evt.bonus.bonusId }] } }]);
        break;
      case 'viewBonus':
        this.router.navigate(['./requests-view', { bonusId: evt.bonus.bonusId }], { relativeTo: this.route });
        break;

    }
  }
  setBonusStatus(bonusStatus: BonusStatus) {
    this.routeUtil.updateRouteParams(this.route, { bonusStatus }, true);
  }
  setBonusTypes(bonusTypes: BonusType[]) {
    const bonusTypeIds = bonusTypes.map(x => x.bonusTypeId);
    this.routeUtil.updateRouteParams(this.route, { bonusTypeIds }, true);
  }
  setLeaseType(leaseType: BonusLeaseTypeFilter) {
    this.routeUtil.updateRouteParams(this.route, { leaseType }, true);
  }

  /** updates the route with the given org.  If no org is set, then unsets the current org in the route. */
  setOrg(org: OrgSummary | null | undefined) {
    this.router.navigate([], { relativeTo: this.route, queryParams: { orgCode: org?.orgCode ?? '' }, queryParamsHandling: 'merge' });
  }

  setPageNumber(pageNumber: number) {
    this.routeUtil.updateRouteParams(this.route, { pageNumber }, true);
  }

  private onParamsChange(params: { pageNumber?: number }) {
    if (params.pageNumber !== undefined && (this.pageSource.value == null || this.pageSource.value !== params.pageNumber)) {
      this.pageSource.next(params.pageNumber);
    }
  }
}
