import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { Lease } from '../api-client';

/**
 * Displays a customer name from a lease and a link to the PMS if customer code is present.
 */
@Component({
  selector: 'app-customer',
  template: `
<ng-container *ngIf="customerName">
  <ng-container *ngIf="customerCode; else NoCode">
    <ng-container *ngIf="showCode; else CodeHidden">
      {{customerName}} (<ng-container *ngTemplateOutlet="Link; context: { $implicit: customerCode }"></ng-container>)
    </ng-container>
    <ng-template #CodeHidden>
      <ng-container *ngTemplateOutlet="Link; context: { $implicit: customerName }"></ng-container>
    </ng-template>
  </ng-container>
  <ng-template #NoCode>
    {{customerName}}
  </ng-template>
</ng-container>
<ng-template #Link let-content>
  <a [href]="pmsUrl" target="_blank" title="View {{customerName}} in Property Management System">{{content}}</a>
</ng-template>
`,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerComponent {
  /** The customer code to redirect to display */
  @Input()
  customerCode: string;

  /** The customer name to display */
  @Input()
  customerName: string;

  /** If true and the customer code is present, displays customer code alongside the name and makes it linked. */
  @Input()
  showCode: boolean;

  constructor() { }

  /** returns the link to the PMS for the customer */
  get pmsUrl() {
    return (this.customerCode)
      ? `https://tccredirects.azurewebsites.net/?method=customer&customerCode=${this.customerCode}`
      : undefined;
  }

}
