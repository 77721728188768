<div *ngIf="failedAudits.length !== 0" class="alert alert-warning">
  <div class="text-center fw-bold">
    Failed
    <ng-container [ngPlural]="failedAudits.length">
      <ng-template ngPluralCase="one">Audit</ng-template>
      <ng-template ngPluralCase="other">Audits</ng-template>
    </ng-container>
    <i class="fa-fw fas fa-exclamation-triangle text-warning"></i>
  </div>
  <div *ngFor="let x of failedAudits" class="my-2">{{x.message}}</div>
</div>
