import { Component, Input } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ReferralForm } from '../../api-client';
import { EmployeesService } from '../../employees/employees.service';
import { RequestVm } from '../request-vm';

@Component({
  selector: 'app-referral-bonus-view',
  templateUrl: './referral-bonus-view.component.html',
  styles: []
})
export class ReferralBonusViewComponent {
  private requestVmChangeSubject = new BehaviorSubject<RequestVm<ReferralForm>>(undefined);

  referred$ = this.requestVmChangeSubject.pipe(
    switchMap(r =>
      (!r || !r.meta || !r.meta.referredEmployeeEmail)
        ? of(undefined)
        : this.empsSvc.empEmailMap$.pipe(
          map(empEmailMap => ({ originalEmail: r.meta.referredEmployeeEmail, emp: empEmailMap.get(r.meta.referredEmployeeEmail) }))
        )
    )
  );

  constructor(private empsSvc: EmployeesService) {

  }

  @Input()
  set request(value: RequestVm<ReferralForm>) {
    this.requestVmChangeSubject.next(value);
  };


}
