
  <form [formGroup]="referralForm">
    <div class="form-group row">
      <div class="col-3">
        <label tccLabelForNext>Referred Employee</label>
      </div>
      <div class="col">
        <tcc-flexi-list formControlName="referredEmail"
                       [itemSource]="employeeEmailItems"
                       controlsClass="form-control form-control-sm"
                       sourceMode="typeahead"
                       [typeaheadPlacement]="['bottom', 'top']"
                       [appBootstrapValidity]></tcc-flexi-list>
        <div class="invalid-tooltip">You must select the referred employeee.</div>
        <small class="form-text text-muted">
          Enter the email of referred employee.
          If they're not active in payroll please try to be accurate as possible when entering the address.
        </small>
      </div>
    </div>
  </form>
