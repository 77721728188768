import { Directive, Input, forwardRef } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, Validators } from '@angular/forms';

@Directive({
  selector: '[appNotEmpty][formControlName],[appNotEmpty][formControl], [appNotEmpty][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: forwardRef(() => NotEmptyValidator), multi: true }]
})
export class NotEmptyValidator implements Validator {

  validate(control: AbstractControl): { [key: string]: any } {
    if (control.value == null || (control.value.length != null && control.value.length === 0)) {
      return {
        appNotEmpty: false
      };
    }
    return null;
  }
}
