<div class="form-group row">
  <div class="col-2">
    <label>Referred</label>
  </div>
  <ng-container *ngIf="(referred$ | async) as referred; else noReferral">
    <div class="col">
      <ng-container *ngIf="referred.emp; else noEmp">
        {{referred.emp.name}}
      </ng-container>
      <ng-template #noEmp>
        {{referred.originalEmail}} <span class="text-danger">(could not find employee record)</span>
      </ng-template>
    </div>
  </ng-container>
  
  <ng-template #noReferral>
    <div class="col">No referral information present</div>
  </ng-template>
</div>
