import { Component, OnDestroy, OnInit, Input, Output, EventEmitter, QueryList, ElementRef, ViewChildren } from '@angular/core';
import { BonusType } from '../api-client';
import { BonusConfigService } from './bonus-config.service';
import { SubsManager } from '@tcc/ui';

interface BonusTypeCheckbox {
  bonusType: BonusType;
  checked?: boolean;
}

@Component({
  selector: 'app-bonus-types-selector',
  templateUrl: './bonus-types-selector.component.html',
  styles: []
})
export class BonusTypesSelectorComponent implements OnDestroy, OnInit {

  @Output()
  readonly bonusTypesChange = new EventEmitter<BonusType[]>();

  @ViewChildren('categoryCheckbox')
  categoryCheckboxes: QueryList<ElementRef>;

  /** view model for selecting bonus types */
  allBonusTypes: BonusTypeCheckbox[];
  bonusTypesByCategoryMap: { [categoryId: number]: BonusTypeCheckbox[] } = {};

  private savedInputBonusTypes: BonusType[];
  private subsMgr = new SubsManager();

  constructor(private bonusConfigSvc: BonusConfigService) {

  }


  @Input()
  set bonusTypes(value: BonusType[]) {
    this.setSelections(value);
  }

  get buttonLabel() {
    const allBonusTypes = this.allBonusTypes || [];
    const checkedBonusTypes = allBonusTypes.filter(x => !!x.checked).map(x => x.bonusType);
    switch (checkedBonusTypes.length) {
      case 0:
        return 'No Selection';
      case 1:
        return checkedBonusTypes[0].name;
      default:
        const firstBonusType = checkedBonusTypes[0];
        if (checkedBonusTypes.every(x => x.categoryId === firstBonusType.categoryId)) {
          const totalCategoryCount = allBonusTypes.filter(x => x.bonusType.categoryId === firstBonusType.categoryId).length;
          const prefix = (totalCategoryCount === checkedBonusTypes.length)
            ? 'All'
            : checkedBonusTypes.length.toString();
            return `${prefix} ${firstBonusType.categoryName}`;
        }
        return `${checkedBonusTypes.length} selected`;
    }
  }

  handleBonusTypeToggle(categoryIdValue: number | string) {
    const categoryId = typeof categoryIdValue === 'string' ? parseInt(categoryIdValue, 10) : categoryIdValue;
    // Find checkbox representing entire category.
    const categoryCheckbox = this.categoryCheckboxes
      .toArray()
      .find(x => x.nativeElement.id === `cat-${categoryId}`);

    const allChecked = this.bonusTypesByCategoryMap[categoryId].every(x => x.checked);
    const allUnchecked = this.bonusTypesByCategoryMap[categoryId].every(x => !x.checked);

    // Set the "indeterminate" state depending on the checked state of all the types in this category.
    if (allChecked || allUnchecked) {
      categoryCheckbox.nativeElement.indeterminate = false;
      categoryCheckbox.nativeElement.checked = allChecked;
    }
    else {
      categoryCheckbox.nativeElement.indeterminate = true;
    }
    this.updateSelections();
  }

  /**
   * Set the checked state of all the corresponding bonus types to a particular category.
   */
  handleCategoryToggle(event: Event, categoryIdValue: number | string) {
    const categoryId = typeof categoryIdValue === 'string' ? parseInt(categoryIdValue, 10) : categoryIdValue;
    this.bonusTypesByCategoryMap[categoryId]
      .forEach(x => x.checked = (event.target as HTMLInputElement).checked);
    this.updateSelections();
  }

  ngOnInit() {
    this.subsMgr.subs.bonusTypes = this.bonusConfigSvc.userBonusTypes$.subscribe(x => this.setChoices(x));
  }

  ngOnDestroy() {
    this.subsMgr.onDestroy();
  }

  updateSelections() {
    const selectedTypes = this.allBonusTypes.filter(x => x.checked).map(x => x.bonusType);
    this.bonusTypesChange.emit(selectedTypes);
  }

  private addItemToArrMap<T extends string | number | symbol, U>(dict: Record<T, U[]>, key: T, val: U): typeof dict {
    if (dict[key]) {
      dict[key].push(val);
    }
    else {
      dict[key] = [val];
    }
    return dict;
  };

  private setChoices(allTypes: BonusType[]) {
    const existingSelections = (this.allBonusTypes || []).filter(x => x.checked).map(x => x.bonusType.bonusTypeId);
    this.allBonusTypes = allTypes.map(x => ({ checked: false, bonusType: x }));
    this.bonusTypesByCategoryMap = this.allBonusTypes
      .reduce((map, type) => this.addItemToArrMap(map, type.bonusType.categoryId, type), {});

    if (this.savedInputBonusTypes !== undefined) {
      this.setSelections(this.savedInputBonusTypes);
      this.savedInputBonusTypes = undefined;
    }
    else if (existingSelections.length > 0) {
      const toReCheck = this.allBonusTypes.filter(x => existingSelections.indexOf(x.bonusType.bonusTypeId) !== -1);
      for (const bonusTypeModel of toReCheck) {
        bonusTypeModel.checked = true;
      }
      if (existingSelections.length !== toReCheck.length) {
        this.updateSelections();
      }
    }
  }

  /**
   * sets the value of checked for all bonusTypes in allBonusTypes from selectedTypes parameter
   */
  private setSelections(selectedTypes: BonusType[] = []) {
    if (!this.allBonusTypes) {
      this.savedInputBonusTypes = selectedTypes;
      return;
    }

    let isChanged = false;
    for (const bonusTypeModel of this.allBonusTypes) {
      const isChecked = selectedTypes.some(x => x.bonusTypeId === bonusTypeModel.bonusType.bonusTypeId);
      if (bonusTypeModel.checked !== isChecked) {
        bonusTypeModel.checked = isChecked;
        isChanged = true;
      }
    }

    if (isChanged) {
      this.updateSelections();
    }
  }
}
