import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { map, switchMap } from 'rxjs/operators';
import { API_BASE_URL } from '../api-client';
import { AuthService } from '@tcc/ui';

@Injectable()
export class AttachmentsService {


  constructor(
    private authSvc: AuthService,
    private httpClient: HttpClient,
    private sanitizer: DomSanitizer,
    @Inject(API_BASE_URL) private baseUrl: string,
    @Inject(DOCUMENT) private document: Document
  ) {


  }

  /**
   *
   *
   * Returns a URL for an attachment.  This URL will not work in the UI; use getSanitizedUrl instead.
   *
   *
   *
   * @param attachmentId
   * @param thumb  - If true, gets the thumbnail for an attachment.
   */
  getUrl(attachmentId: number, thumb: boolean = false) {
    const headers = new HttpHeaders();
    return this.authSvc.authResult$
      .pipe(
        switchMap(token => {
          headers.append('authorization', 'Bearer ' + token.accessToken);
          return this.httpClient.get(`${this.baseUrl}/api/v1/attachments/${attachmentId}?thumb=${thumb}`, {
            headers,
            responseType: 'blob'
          });
        }),
        map(res => this.document.defaultView.URL.createObjectURL(res as Blob))
      );
  }

  /**
   *
   *
   * Gets sanitized URL from attachment.
   *
   *
   *
   * @param attachmentId
   * @param thumb  - If true, gets the thumbnail for an attachment.
   */
  getSanitizedUrl(attachmentId: number, thumb: boolean = false) {
    return this.getUrl(attachmentId, thumb)
      .pipe(
        map(url => this.sanitizer.bypassSecurityTrustResourceUrl(url as string))
      );
  }

}
