import { BonusDetail, BonusStatus, BonusType, EventType, PayoutType, Recipient, RewardType } from '../api-client';
import { RewardTypeUi } from '../shared/reward-type-ui';

export interface BonusExpanded extends BonusDetail {
  bonusTypeName: string;
  eventDates: Map<EventType, Date>;
}

export interface RecipientAndBonus {
  bonus: BonusExpanded;
  recipient: Recipient;
}

export interface BonusSummaryReport {
  bonusTypeSummary: { summaryGroups: SummaryGroup[]; total: SummaryGroup };
  recipients: RecipientAndBonus[];
  recipientSummary: { summaryGroups: SummaryGroup[]; total: SummaryGroup };
}

export interface SummaryGroup {
  aggregates: Map<BonusStatus, SummaryAmounts>;
  name: string;
}
export interface SummaryAmounts {
  /** Aggregated payout totals keyed by PayoutTypeId */
  payoutTypeAmounts: Map<number, number>;
  /** The count of bonuses */
  count: number;
  /** The totals by reward type. */
  rewardTypeAmounts: Map<RewardType, number>;
}

export class BonusSummaryCreator {
  static readonly amountHeadings = [BonusStatus.Unknown, BonusStatus.Approved, BonusStatus.Denied, BonusStatus.Paid];

  readonly rewardTypeUi = RewardTypeUi.rewardTypeUiInfo;

  bonusTypes: BonusType[] = [];
  recipient: Recipient[];
  payoutTypes: PayoutType[] = [];

  constructor(private bonusTypeMap: Map<number, BonusType>, private payoutTypeMap: Map<number, PayoutType>) {

  }

  calculate(bonuses: BonusDetail[]): BonusSummaryReport {
    const bonusesExpanded = bonuses.map(
      bonus => ({
        ...bonus,
        bonusTypeName: this.bonusTypeMap.get(bonus.bonusTypeId).name,
        eventDates: new Map(bonus.events.map<[EventType, Date]>(x => [x.eventType, x.createdOn]))
      } as BonusExpanded));
    const recipients = this.flattenBonusRecipients(bonusesExpanded);
    return {
      bonusTypeSummary: this.summarizeByBonusType(bonusesExpanded),
      recipients,
      recipientSummary: this.summarizeByRecipients(recipients)
    };
  }

  /**
   * Adds to target values from bonus
   */
  private accumulateFromBonus(src: BonusDetail, target: SummaryAmounts) {

    src.payouts.forEach((payout) => {
      const payoutType = this.payoutTypeMap.get(payout.payoutTypeId);
      target.payoutTypeAmounts.set(payout.payoutTypeId, (target.payoutTypeAmounts.get(payout.payoutTypeId) || 0) + payout.amount);
      target.rewardTypeAmounts.set(payoutType.rewardType, (target.rewardTypeAmounts.get(payoutType.rewardType) || 0) + payout.amount);
    });

    target.count += 1;
  }
  /**
   * Adds to target values from other amounts
   */
  private accumulateFromOtherAmounts(src: SummaryAmounts, target: SummaryAmounts) {
    target.count += src.count;
    src.payoutTypeAmounts.forEach((amt, payoutTypeId) =>
      target.payoutTypeAmounts.set(payoutTypeId, (target.payoutTypeAmounts.get(payoutTypeId) || 0) + amt));
    src.rewardTypeAmounts.forEach((amt, rewardType) =>
      target.rewardTypeAmounts.set(rewardType, (target.rewardTypeAmounts.get(rewardType) || 0) + amt));
  }

  /**
   * Adds to target values from recipient
   */
  private accumulateFromRecipient(src: Recipient, target: SummaryAmounts) {
    const payoutType = this.payoutTypeMap.get(src.payoutTypeId);

    target.payoutTypeAmounts.set(src.payoutTypeId, (target.payoutTypeAmounts.get(src.payoutTypeId) || 0) + src.amount);
    target.rewardTypeAmounts.set(payoutType.rewardType, (target.rewardTypeAmounts.get(payoutType.rewardType) || 0) + src.amount);
    target.count += 1;

  }

  /**
   * creates an array of recipient with bonus
   */
  private flattenBonusRecipients(bonuses: BonusExpanded[]) {
    const flattened: RecipientAndBonus[] = [];
    for (const bonus of bonuses) {
      flattened.push(...bonus.recipients.map(recipient => ({ bonus, recipient })));
    }
    return flattened;
  }

  /**
   * Generic method to create a map with an array of items that is keyed by groupFunc.
   */
  private group<TSrc, TKey>(items: TSrc[], groupFunc: (item: TSrc) => TKey) {
    let key: TKey;
    let members: TSrc[];
    const groupMap = new Map<TKey, TSrc[]>();
    for (const item of items) {
      key = groupFunc(item);
      members = groupMap.get(key) || groupMap.set(key, []).get(key);
      members.push(item);
    }
    return groupMap;
  }
  /**
   * creates a new summary group
   */
  private initSummaryGroup(name: string): SummaryGroup {
    const aggregates = new Map(
      BonusSummaryCreator.amountHeadings.map(
        status => [status, {
          count: 0,
          payoutTypeAmounts: new Map(Array.from(this.payoutTypeMap.keys()).map(x => [x, 0])),
          rewardTypeAmounts: new Map(Object.keys(RewardType).map(x => [RewardType[x], 0]))
        }]));
    return { aggregates, name };
  }

  /**
   * Creates summaries by bonusType
   */
  private summarizeByBonusType(bonusesWithTypeName: BonusExpanded[]) {
    const bonusTypeGrouped = this.group(bonusesWithTypeName, d => d.bonusTypeName);
    return this.summarizeMapGroup(bonusTypeGrouped, (x, y) => this.accumulateFromBonus(x, y), x => x.bonusStatus);
  }

  /**
   * Creates summaries by recipients
   */
  private summarizeByRecipients(recipients: RecipientAndBonus[]) {
    const recipientsGrouped = this.group(recipients, x => x.recipient.name);
    return this.summarizeMapGroup(recipientsGrouped, (x, y) => this.accumulateFromRecipient(x.recipient, y), x => x.bonus.bonusStatus);
  }

  /**
   * Uses a Map Group (map with array of items) to calculate Summaries by the group keys as well as the total of all groups.
   */
  private summarizeMapGroup<TVal>(
    groups: Map<string, TVal[]>, accumulator: (src: TVal, target: SummaryAmounts) => void, statusRetriever: (src: TVal) => BonusStatus) {
    const summaryGroups: SummaryGroup[] = [];
    let groupSummary: SummaryGroup;

    for (const [key, value] of Array.from(groups.entries())) {
      groupSummary = this.initSummaryGroup(key);
      summaryGroups.push(groupSummary);
      for (const member of value) {
        accumulator(member, groupSummary.aggregates.get(BonusStatus.Unknown));
        switch (statusRetriever(member)) {
          case BonusStatus.Approved:
            accumulator(member, groupSummary.aggregates.get(BonusStatus.Approved));
            break;
          case BonusStatus.Denied:
            accumulator(member, groupSummary.aggregates.get(BonusStatus.Denied));
            break;
          case BonusStatus.Paid:
            accumulator(member, groupSummary.aggregates.get(BonusStatus.Approved));
            accumulator(member, groupSummary.aggregates.get(BonusStatus.Paid));
            break;
        }
      }
    }

    const total = this.totalSummaryGroups(summaryGroups);
    return { summaryGroups, total };
  }

  /**
   * Creates a Total SummaryGroup from passed groups.
   */
  private totalSummaryGroups(groups: SummaryGroup[]) {
    const totalGroup = this.initSummaryGroup('Total');
    for (const grp of groups) {
      for (const [key, value] of Array.from(grp.aggregates.entries())) {
        this.accumulateFromOtherAmounts(value, totalGroup.aggregates.get(key));
      }
    }
    return totalGroup;
  }
}
