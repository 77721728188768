<div *ngIf="visibleEmployees; else NoEmployees">
  <div >
    <div class="float-start">{{getSelectedCountLabel()}}</div>
    <div class="float-end btn-group btn-group-sm">
      <button type="button" class="btn btn-skinny btn-link" *ngIf="employeesSourceFilter == 'org'"
              [disabled]="isDisabled" (click)="setEmployeeSourceFilter('all')">
        Show All TMs
      </button>
      <button type="button" class="btn btn-skinny btn-link" *ngIf="employeesSourceFilter == 'all'"
              [disabled]="isDisabled" (click)="setEmployeeSourceFilter('org')">
        Community TMs
      </button>
      <button type="button" class="btn btn-skinny btn-warning"
              [disabled]="isDisabled" (click)="clearSelection()">
        Clear Selection
      </button>
    </div>
  </div>
  <select class="form-control form-control-sm" [ngModel]="model" (ngModelChange)="onModelChange($event)" [ngClass]="cssClass"
          [disabled]="isDisabled" [multiple]="isMultiple" (click)="propagateTouch()">
    <option *ngFor="let e of visibleEmployees" [ngValue]="e.employeeId" [ngStyle]="{'color': e.isCurrentyTerminated ? 'red' : null}">{{getEmployeeLabel(e)}}</option>
  </select>
</div>
<ng-template #NoEmployees>
  <span class="text-muted">Loading ...</span>
</ng-template>
