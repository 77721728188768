import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { NotificationsService } from '@tcc/ui';
import { UsersService } from './users.service';
import { map, take } from 'rxjs/operators';
import { AccessType } from '../api-client';

@Injectable()
export class UserAccessGuard implements CanActivate {
  /** the key in route data the UserAccessGuard uses to find its configuration .*/
  static readonly routeDataKey = 'user-access';

  constructor(private notificationsSvc: NotificationsService, private usersSvc: UsersService) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const config: UserAccessGuardConfig = next.data[UserAccessGuard.routeDataKey];

    if (!config || !config.accessType) {
      return of(true);
    }

    const accessTypes = (Array.isArray(config.accessType)) ? config.accessType : [config.accessType];

    // check to see if accessTypes intersects with user's access types.
    return this.usersSvc.myAccess$.pipe(
      take(1),
      map(access => {
        const hasAccess = accessTypes.some(x => access.hasAccessType(x));
        if (!hasAccess) {
          this.notificationsSvc.addError('You do not have access to this tab.');
        }
        return hasAccess;
      }));
  }
}

export interface UserAccessGuardConfig {
  /** required access type - can be a single value, or array of values.  if null/undefined then nothing is checked */
  accessType?: AccessType | AccessType[];
}
