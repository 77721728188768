<tcc-simplePanel>
  <div panel-header>
    Bonuses
  </div>
  <div panel-body>
    <div class="row pb-2">
      <label class="col-2 col-form-label" tccLabelForNext>Community</label>
      <div class="col">
        <app-org-selector [org]="(listParams | async).org" (orgChange)="setOrg($event)"></app-org-selector>
      </div>
    </div>
    <div class="row pb-2">
      <label class="col-2 col-form-label" tccLabelForNext>Status</label>
      <div class="col-4">
        <tcc-flexi-list [ngModel]="(listParams | async).bonusStatus" (ngModelChange)="setBonusStatus($event)"
                       [itemSource]="bonusStatusItems" controlsClass="form-control form-control-sm"></tcc-flexi-list>
      </div>

      <label class="col-2 col-form-label pe-0" tccLabelForNext>Lease Type</label>
      <div class="col-4">
        <tcc-flexi-list [ngModel]="(listParams | async).leaseType" (ngModelChange)="setLeaseType($event)"
                       [itemSource]="leaseTypeItems" controlsClass="form-control form-control-sm"></tcc-flexi-list>
      </div>
    </div>
    <div class="row pb-2">
      <label class="col-2 col-form-label pe-0" tccLabelForNext>Bonus Type</label>
      <div class="col-4">
        <app-bonus-types-selector [bonusTypes]="(listParams | async).bonusTypes" (bonusTypesChange)="setBonusTypes($event)"></app-bonus-types-selector>
      </div>
      <label class="col-2 col-form-label pe-0" tccLabelForNext>Recipient</label>
      <div class="col-4">
        <input type="text" class="form-control form-control-sm" [value]="(listParams | async).recipientNameFilter"
          (keyup)="recipientNameFilterDebounceStartSubject.next($any($event.target).value)"
          (blur)="recipientNameFilterSubject.next($any($event.target).value)" />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <app-bonuses-list [listRows]="pagedData?.data || []"
                          (itemEvent)="onItemEvent($event)">
        </app-bonuses-list>
      </div>
    </div>
    <tcc-loadingOverlay *ngIf="state != 'ready'"></tcc-loadingOverlay>
  </div>
  <div panel-footer>
    <div class="float-start">
      <ngb-pagination [collectionSize]="pagedData?.pageInfo?.totalCount || 0"
                      [page]="pagedData?.pageInfo?.pageNumber"
                      (pageChange)="setPageNumber($event)"
                      [pageSize]="pageSize"
                      [maxSize]="4" size="sm"></ngb-pagination>
    </div>
    <div class="float-end">
      <button type="button" class="btn btn-sm btn-primary" [disabled]="!(listParams | async).org"
        [routerLink]="['', { outlets: { popups: ['requests-edit'] } }]"
        [queryParams]="{ orgCode: (listParams | async).org?.orgCode}" queryParamsHandling="merge">
        New Non-Lease Bonus
      </button>
    </div>
  </div>
</tcc-simplePanel>
<router-outlet></router-outlet>
