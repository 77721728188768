import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { of } from 'rxjs';
import { Lease } from '../api-client';
import { LeasesService } from './leases.service';

@Injectable()
export class LeaseParamResolver implements Resolve<Lease> {

  constructor(private leasesSvc: LeasesService) { }

  resolve(route: ActivatedRouteSnapshot) {
    const value = route.params['leaseId'];

    if (value) {
      return this.leasesSvc.getLease(parseInt(value, 10));
    }

    return of<Lease>(undefined);
  }

}
