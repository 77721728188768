<tcc-simplePanel [size]="'full'" [options]="{respondsToMainMenu: true}">
  <div panel-header>
    Bonuses Summary Report
  </div>
  <div panel-body>
    <div class="row">
      <div class="col-auto">
        <div class="form-row">
          <div class="form-group col-auto">
            <label tccLabelForNext>Community</label>
            <app-org-selector [org]="orgSource | async" (orgChange)="setOrg($event)"></app-org-selector>
          </div>
          <div class="form-group col-auto">
            <label tccLabelForNext>Min Date</label>
            <input type="date" class="form-control form-control-sm" style="width:12rem" useValueAsDate
                   [ngModel]="minDateSource | async" (ngModelChange)="minDateSource.next($event)" />
          </div>
          <div class="form-group col-auto">
            <label tccLabelForNext>Max Date</label>
            <input type="date" class="form-control form-control-sm" style="width:12rem" useValueAsDate
                   [ngModel]="maxDateSource | async" (ngModelChange)="maxDateSource.next($event)" />
          </div>
          <div class="form-group col-auto">
            <label tccLabelForNext>Date Type</label>
            <select class="form-control form-control-sm"
                    [ngModel]="dateTypeSource | async" (ngModelChange)="dateTypeSource.next($event)">
              <option value="CreatedOn">Created On</option>
              <option value="ApprovedOn">Approved On</option>
              <option value="PaidOn">Paid On</option>
            </select>
          </div>
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="showChargedByOtherOrg"
                   [ngModel]="showBilledByOther | async" (ngModelChange)="showBilledByOther.next($event)">
            <label class="custom-control-label" for="showChargedByOtherOrg">Show Bonuses Charged by other Communities</label>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="report?.recipients.length; else NoRecipients">
      <h2>Bonus Type Summary</h2>
      <app-bonus-summary-group [groups]="report.bonusTypeSummary.summaryGroups" [total]="report.bonusTypeSummary.total"></app-bonus-summary-group>
      <h2>Employee Summary</h2>
      <app-bonus-summary-group [groups]="report.recipientSummary.summaryGroups" [total]="report.recipientSummary.total"></app-bonus-summary-group>
      <h2>Recipient Details</h2>
      <tcc-table tableClass="table-sm small" [rows]="report?.recipients"
                 [columns]="recipientCols" [enableClientSort]="true">
        <ng-template cellTemplateName="createdOn" let-row>{{row.bonus.createdOn | date:'M/d/yyyy'}}</ng-template>
        <ng-template cellTemplateName="approvedOn" let-row>{{row.bonus.eventDates.get('Approved') | date:'M/d/yyyy'}}</ng-template>
        <ng-template cellTemplateName="deniedOn" let-row>{{row.bonus.eventDates.get('Denied') | date:'M/d/yyyy'}}</ng-template>
        <ng-template cellTemplateName="paidOn" let-row>{{row.bonus.eventDates.get('Paid')| date:'M/d/yyyy'}}</ng-template>
        <ng-template cellTemplateName="amount" let-row>
          {{row.recipient.amount | rewardTypeValue:getRewardType(row.recipient.payoutTypeId)}}{{payoutTypeUiMap.get(row.recipient.payoutTypeId).suffix}}
        </ng-template>
      </tcc-table>
    </ng-container>
    <ng-template #NoRecipients>
      No data available for given report parameters.
    </ng-template>
    <tcc-loadingOverlay *ngIf="state != 'ready'"></tcc-loadingOverlay>
  </div>
  <div panel-footer>
    <div class="float-end">
      <button type="button" class="btn btn-sm btn-primary" (click)="doExport()" [disabled]="!report?.recipients.length">
        Export Recipient Details
      </button>
    </div>
  </div>
</tcc-simplePanel>

