import { LeaseBonusStatusFilter } from '../api-client';

export const leaseBonusStatusUiItems = [
  { label: 'All', value: LeaseBonusStatusFilter.AllEligible },
  { label: 'No Bonus', value: LeaseBonusStatusFilter.NoBonus },
  { label: 'With Bonus', value: LeaseBonusStatusFilter.WithBonus },
  { label: 'Unapproved', value: LeaseBonusStatusFilter.Unapproved },
  { label: 'Approved', value: LeaseBonusStatusFilter.Approved },
  { label: 'Denied', value: LeaseBonusStatusFilter.Denied },
  { label: 'Paid', value: LeaseBonusStatusFilter.Paid }
];
