import { RewardTypeUi } from './../shared/reward-type-ui';
import { Component, OnInit, ViewChild, OnDestroy, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService, AuthService, SubsManager } from '@tcc/ui';
import { FileUploader } from 'ng2-file-upload';
import { Observable, of, forkJoin } from 'rxjs';
import { OrgSummary, Lease, LeaseType, PayoutType, API_BASE_URL, AttachmentType, RewardType, BaseMetaForm, EmployeeSummaryDated } from '../api-client';
import { BonusConfigService, MergedBonusSchedule } from '../bonus-config/bonus-config.service';
import { BonusesService } from '../bonuses/bonuses.service';
import { EmployeesService } from '../employees/employees.service';
import { SwaggerExceptionWithServerInfo } from '../shared/exception-info';
import { FlexiListItem } from '../shared/flexi-list-control.component';
import { OrgsService } from '../orgs/orgs.service';
import { calculatRequestTotals, RequestCreationInfo } from './request-vm';
import { RequestsService } from './requests.service';
import { map, mergeMap, finalize, retry, tap, switchMap } from 'rxjs/operators';
import { catchReportResume } from '../shared/catch-report-resume-operator';
import { AttachmentsComponent } from '../attachments/attachments.component';

@Component({
  selector: 'app-request-editor',
  templateUrl: './request-editor.component.html'
})

export class RequestEditorComponent implements OnInit, OnDestroy {

  @ViewChild(NgForm)
  myForm: NgForm;

  @ViewChild(AttachmentsComponent) public attachComponent: AttachmentsComponent;

  attachmentUploader: FileUploader;
  bonusScheduleItems: FlexiListItem<MergedBonusSchedule>[] = [];
  employeesSource: EmployeeSummaryDated[];
  payoutTypes: PayoutType[] = [];
  responsibleOrgItems: FlexiListItem<OrgSummary>[] = [];
  request: RequestCreationInfo<BaseMetaForm>;
  state: 'loading' | 'ready';

  private subsMgr = new SubsManager();

  readonly rewardTypeUi = RewardTypeUi.rewardTypeUiInfo.filter(x => x.rewardType !== RewardType.Unknown);
  readonly rewardTypeUiMap = RewardTypeUi.rewardTypeUiMap;

  constructor(
    private bonusesSvc: BonusesService, private bonusConfigSvc: BonusConfigService, private empsSvc: EmployeesService,
    private notificationsSvc: NotificationsService, private orgsSvc: OrgsService,
    private reqSvc: RequestsService, private route: ActivatedRoute, private router: Router,
    private authSvc: AuthService, @Inject(API_BASE_URL) private apiBaseUrl: string
  ) {


  }

  /**
   * Displays the attachment functionality based upon the attachment type
   */
  get hideAttachments() {
    return !this.request || !this.request.bonusSchedule || this.request.bonusSchedule.attachmentType === AttachmentType.None;
  }

  get isAmountsDisabled() {
    return (!this.request || !this.request.bonusSchedule || !this.request.bonusSchedule.isEditingAmountsAllowed);
  }

  get isEmployeesDisabled() {
    return (!this.request || !this.request.bonusSchedule);
  }

  get ableToViewDescription() {
    if (this.request.bonusSchedule) {
      if (this.request.bonusSchedule.description != null) {
        return this.request.bonusSchedule.description;
      }
    }
  }

  ngOnDestroy() {
    this.subsMgr.onDestroy();
  }

  ngOnInit() {
    this.subsMgr.addSub = this.route.data.subscribe((x: RequestEditorParams) => {
      this.updateState(x);
    });
  }
  
  getRecipientsContainsTerminatedEmployees() {
    var result = false;
    this.request.payouts.forEach(payout => {
      payout.recipients.forEach(recipientId => {
        if (this.employeesSource.filter(emp => emp.employeeId === recipientId && emp.isCurrentyTerminated == true).length > 0) {
          result = true;
        }
      });
    });
    return result;
  }

  /** returns the reward type of an item. */
  getRewardType<T extends { rewardType?: RewardType }>(_: number, item: T) {
    return item.rewardType;
  }

  /**
   * Ensures total amount is in sync based on the payout amounts.
   */
  onBonusScheduleChange() {
    // Won't work in ngOnInit or ngAfterViewChecked.
    this.attachmentUploader = this.attachComponent
      ? this.attachComponent.uploader
      : undefined;

    // save previous payouts for adding to current bonus
    const prevPayouts = this.request.payouts;

    if (this.request) {

      if (this.request.bonusSchedule) {
        const sched = this.request.bonusSchedule;
        const sameDayShowAndSignRate = (this.request.lease?.isSameDayShowAndSign) ? sched.sameDayShowAndSignRate : 1;

        this.request.payouts = sched.payouts.map(x => ({
          amount: x.amount,
          name: this.payoutTypes.find(y => y.payoutTypeId === x.payoutTypeId).name,
          payoutTypeId: x.payoutTypeId,
          recipients: [],
          rewardType: this.payoutTypes.find(y => y.payoutTypeId === x.payoutTypeId).rewardType
        }));

        // filter employees out by orgCode
        const filteredEmps = this.employeesSource.filter(emp => emp.orgCode === this.request.org.orgCode);

        for (const payout of this.request.payouts) {
          payout.amount *= sameDayShowAndSignRate;

          // use PayoutType's regex (found with payoutTypeId) to further filter employees
          const payoutType = this.payoutTypes.find(pt => pt.payoutTypeId === payout.payoutTypeId);
          const ptRegex = payoutType.defaultRecipientRegex;
          const regexFilter = (ptRegex != null) ? new RegExp(ptRegex, 'gi') : null;

          // add employees that are a match using the regex
          const preSelectedRecipients = (regexFilter) ? filteredEmps.filter(x => x.deptCode.match(regexFilter)) : [];
          payout.recipients = Array.from(new Set(payout.recipients.concat(preSelectedRecipients.map(x => x.employeeId))));

          // if a previousPayout type matches current payout type, set recipients
          const prevPayoutMatch = prevPayouts.find(x => x.payoutTypeId === payout.payoutTypeId);
          if (prevPayoutMatch) {
            payout.recipients = prevPayoutMatch.recipients;
          }
        }
      }
      else {
        for (const payout of this.request.payouts) {
          payout.amount = 0;
        }
      }

      if (!this.request.bonusSchedule || !this.request.bonusSchedule.isResponsibilityAssigned) {
        this.request.responsibleOrg = undefined;
      }
      else if (this.request.bonusSchedule && !this.request.responsibleOrg) {
        this.request.responsibleOrg = this.responsibleOrgItems.map(x => x.value).find(x => x.orgCode === this.request.org.orgCode);
      }
    }
    calculatRequestTotals(this.request);
  }

  onPayoutAmountChanged() {
    calculatRequestTotals(this.request);
  }

  /**
   * Submit button action- if the attachment type is not required we allow the form to be submissable.
   */
  onSubmit() {
    // TODO:
    // 1) have a submit observable.
    // 2) throttle submissions so multiple submissions don't start.
    // 3) pipe submissions from that observable to this code.
    this.subsMgr.cancel('submitBonus');
    this.state = 'ready';
    this.myForm.control.updateValueAndValidity();

    if (this.myForm.valid) {
      if ((this.request.bonusSchedule.attachmentType !== AttachmentType.Required) || !this.attachComponent.uploader
        || this.attachComponent.uploader.queue.length >= 1) {
        this.state = 'loading';

        this.subsMgr.subs['submitBonus'] = this.reqSvc.requestToBonusCreationInfo(this.request).pipe(
          switchMap(bonus => this.bonusesSvc.submitBonus(bonus)),
          switchMap(
            (bonusId) => this.authSvc.authResult$.pipe(
              map(authToken => ({ bonusId, authToken })))),
          tap(({ bonusId, authToken }) => {
            if (!this.hideAttachments && this.attachComponent.uploader.queue && this.attachComponent.uploader.queue.length) {

              this.attachComponent.uploader.queue
                .forEach(y => y.url = `${this.apiBaseUrl}/api/v1/bonuses/${bonusId}/attachments`);
              this.attachComponent.uploader.authToken = 'Bearer ' + authToken.accessToken;
              this.attachComponent.uploader.uploadAll();

            }
            else {
              this.submissionCompletedCommon();
            }
          })
        ).subscribe(undefined, (err) => {
          let additionalInfo = '';
          if (SwaggerExceptionWithServerInfo.isSwaggerExceptionWithServerInfo(err)
            && SwaggerExceptionWithServerInfo.hasServerMessage(err)) {
            additionalInfo += ': ' + err.serverException.message;
          }
          this.notificationsSvc.addError('Failed saving bonus' + additionalInfo);
        });
      }
      else {
        this.notificationsSvc.addWarning('Please attach supporting documentation.');
      }
    }
    else {
      this.notificationsSvc.addWarning('Please complete all required fields before submitting.');
    }
  }

  onUploadsComplete() {
    const failure = this.attachComponent.uploader.queue.find(x => !x.isSuccess);

    if (failure) {
      this.notificationsSvc.addWarning('Bonus saved.  Some attachments could not be saved.');
      this.close(true);
      this.state = 'ready';
    }
    else {
      this.submissionCompletedCommon();
    }
  }
  /**
   * Handles all panelButton click actions
   *
   * @param action
   */
  panelButtonClick(action: string) {
    switch (action) {
      case 'close':
        this.close(false);
        break;
    }
  }

  /**
   * Sets employeesSource with employees active on the appliesOnDate
   */
  updateEmployees() {
    this.subsMgr.cancel('updateEmployees');

    this.employeesSource = [];
    if (this.request && this.request.appliesOn) {
      this.subsMgr.subs['updateEmployees'] = this.createEmployeeSourceUpdate$(this.request.appliesOn)
        .subscribe();
    }
  }

  /**
   * Creates an observable that updates employeeSource
   */
  private createEmployeeSourceUpdate$(activeOn: Date) {
    return this.empsSvc.getEmployeesActiveOn(activeOn).pipe(
      retry(3),
      map(emps => this.employeesSource = emps)
    );
  }
  /**
   * Creates a new request based on parameters
   */
  private initRequest(lease: Lease, org: OrgSummary) {
    const request: RequestCreationInfo<BaseMetaForm> = { payouts: [], totals: [] };
    request.bonusSchedule = undefined;
    request.responsibleOrg = undefined;
    request.org = org;
    if (lease) {
      request.description = `${lease.leaseType} lease bonus for ${lease.customerName} (${lease.customerCode})`;
      request.lease = lease;
      request.appliesOn = (lease.occurredOn && lease.occurredOn < new Date()) ? lease.occurredOn : new Date();
    }
    else {
      request.appliesOn = new Date();
    }
    this.request = request;
  }

  private submissionCompletedCommon() {
    this.notificationsSvc.addSuccess('Bonus saved.');
    this.close(true);
    this.state = 'ready';
  }

  private updateState(params: RequestEditorParams) {
    let update$: Observable<[MergedBonusSchedule[], OrgSummary, OrgSummary[], PayoutType[]]>;
    const { lease, nonLeaseOrg } = params.config;
    this.subsMgr.cancel('updateEmployees');
    this.subsMgr.cancel('updateRequest');
    this.request = undefined;
    this.employeesSource = [];
    this.payoutTypes = [];

    if (lease) {
      update$ = forkJoin(
        this.bonusConfigSvc.getUserBonusSchedule(lease.orgCode, lease.leaseType, lease.unitTypeCode),
        this.orgsSvc.orgMap$.pipe(map(x => x.get(lease.orgCode))),
        of([]),
        this.bonusConfigSvc.payoutType$
      );
    }
    else if (nonLeaseOrg) {
      update$ = forkJoin(
        this.bonusConfigSvc.getUserBonusSchedule(nonLeaseOrg.orgCode, LeaseType.NonLease),
        of(nonLeaseOrg),
        this.orgsSvc.allOrgs$,
        this.bonusConfigSvc.payoutType$
      );
    }

    if (update$) {
      this.state = 'loading';
      this.subsMgr.subs['updateRequest'] = update$.pipe(
        tap(([bonusTypeItems, org, responsibleOrgs, payoutTypes]) => {
          this.bonusScheduleItems = bonusTypeItems.map(y => ({ label: y.bonusTypeName, value: y }));
          this.responsibleOrgItems = responsibleOrgs.map(x => ({ label: x.name, value: x }));
          this.payoutTypes = payoutTypes;
          this.initRequest(lease, org);
        }),
        mergeMap(() => this.createEmployeeSourceUpdate$(this.request.appliesOn)),
        catchReportResume(() => this.notificationsSvc.addError('Unable to start request.  Please refresh the page and try again')),
        finalize(() => this.state = 'ready')
      ).subscribe();
    }
    else {
      this.request = undefined;
      this.state = 'loading';
    }
  }

  private close(reload: boolean): void {
    const queryParams = reload ? { reload: Date.now() } : undefined;
    this.router.navigate([{ outlets: { popups: null } }], { queryParamsHandling: 'merge', queryParams });
  }
}

interface RequestEditorParams {
  config: {
    lease?: Lease;
    nonLeaseOrg?: OrgSummary;
  };
}
