import { Injectable } from '@angular/core';
import { combineLatest, forkJoin, Observable } from 'rxjs';
import { map, shareReplay, take, tap } from 'rxjs/operators';
import { AccessType, ApiClient, BonusSchedule, BonusType, LeaseType } from '../api-client';
import { SortUtil } from '../shared/sort-util';
import { UserAccess } from '../users/user-access';
import { UsersService } from '../users/users.service';


export type MergedBonusSchedule = BonusSchedule & BonusType & { bonusTypeName: string };

@Injectable()
export class BonusConfigService {

  /** all bonus types */
  readonly bonusTypes$ = this.apiClient.getBonusTypes().pipe(
    map(x => x.result),
    shareReplay(1)
  );

  /** all bonus types mapped by BonusTypeId. */
  readonly bonusTypeMap$ = this.bonusTypes$.pipe(
    map(x => new Map(x.map<[number, BonusType]>(y => [y.bonusTypeId, y]))),
    shareReplay(1)
  );

  /** all schedule items */
  readonly scheduleItemsAll$ = forkJoin(
    this.apiClient.getBonusSchedules().pipe(map(x => x.result)),
    this.bonusTypes$.pipe(map(x => new Map(x.map<[number, BonusType]>(y => [y.bonusTypeId, y]))))
  ).pipe(
    map(([bonusSchedules, bonusTypeMap]) =>
      bonusSchedules.map<MergedBonusSchedule>(x => {
        const bonusType = bonusTypeMap.get(x.bonusTypeId);
        return { ...bonusType, ...x, bonusTypeName: bonusType.name };
      })
    ),
    shareReplay(1)
  );

  /** array of all payout types. */
  readonly payoutType$ = this.apiClient.getPayoutTypes().pipe(
    map(x => x.result),
    shareReplay(1)
  );

  /** all payout types mapped by PayoutTypeId. */
  readonly payoutTypeMap$ = this.payoutType$.pipe(
    map(x => new Map(x.map(y => [y.payoutTypeId, y]))),
    shareReplay(1)
  );

  /** all schedules not associated with an organization */
  readonly scheduleItemsBase$ = this.scheduleItemsAll$.pipe(
    map(x => x.filter(y => !y.orgCode && !y.unitTypeCode)),
    shareReplay(1)
  );

  /** all bonusTypes that the user can access */
  readonly userBonusTypes$ = combineLatest(this.bonusTypes$, this.usersSvc.myAccess$).pipe(
    map(([bonusTypes, userAccess]) => bonusTypes.filter(x => userAccess.hasCategory(x.categoryId))),
    shareReplay(1)
  );

  readonly userBonusTypeMap$ = this.userBonusTypes$.pipe(
    map(x => new Map(x.map<[number, BonusType]>(y => [y.bonusTypeId, y]))),
    shareReplay(1)
  );

  /** caches all org schedules */
  private readonly orgScheduleCache: { [orgCode: string]: Observable<MergedBonusSchedule[]> } = {};

  constructor(private apiClient: ApiClient, private usersSvc: UsersService) { }

  /**
   * Gets best possible bonusSchedule that would be associated with the passed parameters
   */
  getApplicableBonusSchedule(bonusTypeId: number, orgCode: string, leaseType: LeaseType, unitTypeCode?: string) {
    return this.scheduleItemsAll$.pipe(
      map(scheduleItem => {
        const bonusTypeSchedules = scheduleItem.filter(y => y.bonusTypeId === bonusTypeId
          && (!y.orgCode || y.orgCode === orgCode)
          && (!y.unitTypeCode || y.unitTypeCode === unitTypeCode)
        );
        return bonusTypeSchedules.find(x => x.orgCode === orgCode && x.unitTypeCode === unitTypeCode && x.leaseType === leaseType)
          || bonusTypeSchedules.find(x => x.orgCode === orgCode && x.leaseType === leaseType)
          || bonusTypeSchedules.find(x => x.orgCode === orgCode && x.unitTypeCode === unitTypeCode)
          || bonusTypeSchedules.find(x => x.orgCode === orgCode)
          || bonusTypeSchedules.find(x => x.leaseType === leaseType)
          || bonusTypeSchedules[0];
      })
    );
  }
  /**
   * returns the bonus schedule for the given combination of org, leaseType and unitTypeCode
   */
  getUserBonusSchedule(orgCode: string, leaseType: LeaseType, unitTypeCode?: string) {
    // all schedule items for an organization
    const orgSchedule$ = this.getOrgSchedule(orgCode);
    // all bonus types that correspond to lease type
    const filteredBonusTypeMap$ = forkJoin([this.usersSvc.myAccess$.pipe(take(1)), this.bonusTypes$]).pipe(
      map(([userAccess, bonusTypes]) => {
        const allowedCategoryIds = userAccess.query(AccessType.Creator, undefined, orgCode).map(y => y.bonusTypeCategoryId);
        if (!allowedCategoryIds.some(x => x === UserAccess.categoryWildCard)) {
          bonusTypes = bonusTypes.filter(x => allowedCategoryIds.indexOf(x.categoryId) !== -1);
        }
        return this.filterBonusTypesByLeaseType(bonusTypes, leaseType);
      })
    );

    return forkJoin([orgSchedule$, this.scheduleItemsBase$, filteredBonusTypeMap$]).pipe(
      map(([allOrgItems, baseSchedule, btMap]) => {
        const groupedOrgSchedules = allOrgItems
          .reduce((grp, x) => {
            (grp.get(x.bonusTypeId) ?? grp.set(x.bonusTypeId, []).get(x.bonusTypeId)).push(x);
            return grp;
          }, new Map<number, MergedBonusSchedule[]>());
        const bonusSchedules = Array.from(btMap.keys())
          .map(bonusTypeId => {
            if (groupedOrgSchedules.has(bonusTypeId)) {
              const orgSchedules = groupedOrgSchedules.get(bonusTypeId);
              return orgSchedules.find(x => x.unitTypeCode === unitTypeCode) || orgSchedules.find(x => !x.unitTypeCode)
                || baseSchedule.find(x => x.bonusTypeId === bonusTypeId);
            }
            return baseSchedule.find(x => x.bonusTypeId === bonusTypeId);
          })
          .filter((x): x is MergedBonusSchedule => !!x)
          .sort(SortUtil.caseInsensitiveStringSortFactory<MergedBonusSchedule>(x => x.name));
        return bonusSchedules;
      }));
  }

  /**
   * Creates a map of bonus types for a given leaseType, keyed by bonusTypeId
   */
  private filterBonusTypesByLeaseType(bonusTypes: BonusType[], leaseType: LeaseType) {
    return new Map(
      bonusTypes
        .filter(bt => !bt.leaseType || bt.leaseType === LeaseType.Unknown || bt.leaseType === leaseType)
        .map<[number, BonusType]>(bt => [bt.bonusTypeId, bt])
    );
  }

  /**
   * gets schedule items for a given orgCode
   */
  private getOrgSchedule(orgCode: string) {
    if (!this.orgScheduleCache[orgCode]) {
      this.orgScheduleCache[orgCode] = this.scheduleItemsAll$.pipe(
        map(x => x.filter(y => y.orgCode === orgCode)),
        shareReplay(1)
      );
    }
    return this.orgScheduleCache[orgCode];
  }
}
