import { Pipe, PipeTransform } from '@angular/core';

/**
 * Takes the comma out of strings.  Useful after DecimalPipe and you want to take out the thousands seperator.
 */
@Pipe({
  name: 'noComma'
})
export class NoCommaPipe implements PipeTransform {

  transform(val: any): string {
    return (typeof val === 'string') ? val.replace(',', '') : val;
  }
}
