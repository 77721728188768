<div class="row">
  <label class="col-12">
    {{labelText}}
    <ng-container *ngIf="!readOnly">
      <small *ngIf="maxFileSize">(Max file size {{maxFileSize | fileSize}})</small>

      <button type="button" class="btn btn-primary btn-sm float-end" (click)="openFileSelectDialog()">
        <i class="fa fa-upload"></i> Select File
      </button>

      <input name="attachments" class="d-none" type="file" #fileInput ng2FileSelect [uploader]="uploader" multiple />
    </ng-container>
  </label>
</div>

<div class="row" *ngIf="attachments?.length">
  <div class="col-12">
    <app-attachment-thumbnail *ngFor="let a of attachments" [attachment]="a" [readOnly]="readOnly"
      (deleteConfirmed)="deleteAttachment(a.bonusAttachementId)"></app-attachment-thumbnail>
  </div>
</div>

<div class="row" *ngIf="uploader.queue">
  <div class="col-12">
    <ul class="list-unstyled pe-0" [hidden]="!uploader.queue.length && !uploader.progress">
      <li *ngFor="let item of uploader.queue" class="pb-1">

        <button type="button" class="btn btn-danger btn-sm ms-3 me-2" (click)="handleRemove(item)">
          <span class="fa fa-trash"></span>
        </button>

        <strong>{{ item.file?.name }}</strong>
      </li>
    </ul>
  </div>
</div>
