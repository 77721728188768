<ng-container [ngSwitch]="orgs.length">
  <div class="form-control-plaintext form-control-sm" *ngSwitchCase="0">No Organizations</div>
  <div class="form-control-plaintext form-control-sm" *ngSwitchCase="1">{{org.name}} ({{org.orgCode}})</div>
  <select class="form-control form-control-sm" *ngSwitchDefault
          [ngModel]="org" (ngModelChange)="onSelectedOrgChange($event)">
    <option [ngValue]="undefined">{{noOrgLabel}}</option>
    <option *ngFor="let x of orgs" [ngValue]="x">
      {{x.name}} ({{x.orgCode}})
    </option>
  </select>
</ng-container>
