import { Directive, HostBinding, HostListener, Input, Optional, Self } from '@angular/core';
import { AbstractControl, FormGroupDirective, NgControl, NgForm, NgModel } from '@angular/forms';

@Directive({
  selector: '[appBootstrapValidity]'
})
export class BootstrapValidityDirective {

  /** Manually set the control if this directive isn't on a form control. */
  @Input('appBootstrapValidity')
  linkedControl?: AbstractControl | NgModel;

  /** Will show validation result even if not validated when this is true. */
  @Input() formSubmitted?: boolean;

  /** If true will not wait for submission to validate. */
  @Input() validateAfterFirstBlur = false;

  private beenBlured = false;

  constructor(
    @Optional() @Self() private ctrl?: NgControl,
    @Optional() private formDirective?: FormGroupDirective,
    @Optional() private form?: NgForm
  ) {

  }

  @HostBinding('class.is-invalid')
  get isClassInvalid() {
    const submitted = this.getSubmissionState();
    const { dirty, invalid } = this.getControlStatus();
    const normalCond = (!this.validateAfterFirstBlur && invalid && (dirty || submitted));
    const afterBlurCond = (this.validateAfterFirstBlur && invalid && (this.beenBlured || submitted));
    return normalCond || afterBlurCond;
  }

  private getControlStatus() {
    const ctrl = this.linkedControl || this.ctrl;
    return (ctrl)
      ? { dirty: ctrl.dirty || false, invalid: ctrl.invalid || false }
      : { dirty: false, invalid: false };
  }

  /** Gets form submission state, gives priority to when form is manually set. */
  private getSubmissionState() {
    if (this.formSubmitted != null) {
      return this.formSubmitted;
    }
    if (this.form) {
      return this.form.submitted;
    }
    if (this.formDirective) {
      return this.formDirective.submitted;
    }
    return false;
  }

  @HostListener('blur', ['$event']) onblur() {
    this.beenBlured = true;
    return;
  }
}
