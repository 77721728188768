import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Lease, LeaseBonusStatusFilter } from '../api-client';
import { TableColumn } from '@tcc/ui';
import { LeaseAction } from './lease-action';
import { TitleCasePipe } from '@angular/common';

@Component({
  selector: 'app-leases-list',
  templateUrl: './leases-list.component.html',
  styles: []
})
export class LeasesListComponent implements OnInit {
  /** occurrs when page changed */
  @Output()
  readonly itemEvent = new EventEmitter<{ lease: Lease; eventType: LeaseAction }>();

  /** currently visible leases */
  @Input()
  listRows: Lease[] = [];

  readonly listColumns: TableColumn<Lease>[] = [
    { name: 'leasingActivityId', title: 'Lease' },
    //{ name: 'orgCode', title: 'Community' },
    { name: 'customerName', title: 'Customer' },
    { name: 'leaseType', title: 'Type' },
    {
      name: 'agentName',
      title: 'Agent',
      contentResolver: (col, row) => {
        const match = /^(.+?)\.(.+?)@/.exec(row.agentName);
        if (match && match[1] && match[2]) {
          return this.nameFormatter(match[1] + ' ' + match[2]);
        }
        return row.agentName;
      }
    },
    { name: 'bonusStatus', title: 'Bonus', colClass: 'td-min text-center' },
    { name: 'actionButtons', title: ' ', colClass: 'td-min pe-2 ps-2' }
  ];

  private readonly nameFormatter = ((up) => (text: string) => up.transform(text))(new TitleCasePipe());
  constructor() { }

  ngOnInit() {

  }

  /**
   * returns true if a lease has a bonusId
   */
  hasBonus(lease: Lease) {
    return (lease.bonusId && true);
  }

  /**
   * The lease has a bonus that still has a pending decision to be made
   */
  hasOpenBonus(lease: Lease) {
    return (lease.bonusId && lease.bonusStatus !== LeaseBonusStatusFilter.Paid);
  }

  /**
   *
   * @param lease
   * @param eventName
   */
  emitEvent(lease: Lease, eventType: LeaseAction) {
    this.itemEvent.next({ lease, eventType });
  }
}
