import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrgSelectorComponent } from './org-selector.component';
import { OverviewComponent } from './overview.component';
import { OrgParamResolver } from './org-param.resolver';
import { OrgsService } from './orgs.service';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TccUiModule } from '@tcc/ui';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    TccUiModule
  ],
  declarations: [
    OrgSelectorComponent,
    OverviewComponent
  ],
  exports: [
    OrgSelectorComponent,
    OverviewComponent
  ],
  providers: [
    OrgParamResolver,
    OrgsService
  ]
})
export class OrgsModule { }
