import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { BonusDetail, BonusStatus } from '../api-client';
import { BonusAction } from './bonus-action';
import { TableColumn } from '@tcc/ui';
import { BonusConfigService } from '../bonus-config/bonus-config.service';

@Component({
  selector: 'app-bonuses-list',
  templateUrl: './bonuses-list.component.html',
  styles: []
})
export class BonusesListComponent implements OnInit {
  /** this is used to show the correct bonus type from the bonus type id. */
  readonly bonusTypeMap$ = this.bonusConfigSvc.bonusTypeMap$;

  /** occurrs when page changed */
  @Output()
  readonly itemEvent = new EventEmitter<{ bonus: BonusDetail; eventType: BonusAction }>();

  /** currently visible leases */
  @Input()
  listRows: BonusDetail[] = [];

  constructor(private bonusConfigSvc: BonusConfigService) { }

  ngOnInit() {

  }

  emitEvent(bonus: BonusDetail, eventType: BonusAction) {
    this.itemEvent.next({ bonus, eventType });
  }

  isPaid(bonus: BonusDetail) {
    return bonus.bonusStatus === BonusStatus.Paid;
  }
}
