import { Injectable } from '@angular/core';
import { ApiClient, OrgSummary, OrgOverview } from '../api-client';
import { forkJoin } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Injectable()
export class OrgsService {
  readonly overviewLookbackDays = 30;

  /** global source of all orgs */
  readonly allOrgs$ = this.apiClient.getAllOrgs().pipe(
    map(x => x.result.sort((a, b) => (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0)),
    shareReplay(1)
  );

  /** global source of all orgs for the user */
  readonly orgs$ = this.apiClient.getOrgs().pipe(
    map(x => x.result.sort((a, b) => (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0)),
    shareReplay(1)
  );

  /** global source of all orgs for the user as a map*/
  readonly orgMap$ = this.allOrgs$.pipe(
    map(x => new Map(x.map(y => [y.orgCode, y]))),
    shareReplay(1)
  );

  constructor(private apiClient: ApiClient) {
  }

  getOrgByOrgCode(orgCode: string) {
    return this.orgMap$.pipe(map(om => om.get(orgCode)));
  }


  getOverviewsFromLookbackDays() {
    const dt = new Date();
    dt.setDate(dt.getDate() - this.overviewLookbackDays);
    return forkJoin([this.apiClient.orgOverviews(dt, undefined), this.orgMap$])
      .pipe(map(([orgOverviews, orgMap]) =>
        orgOverviews.result
          .filter(x => orgMap.has(x.orgCode))
          .map(x => ({ ...x, ...orgMap.get(x.orgCode) } as OrgOverview & OrgSummary))
          .sort((a, b) => (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0)
      ));

  }
}
