import { DatePipe, DecimalPipe } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { FileDownloadService, covertArrayToFlatFile, NotificationsService, SubsManager, CSV_FORMAT } from '@tcc/ui';
import { BehaviorSubject, forkJoin, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, map, mergeMap, tap } from 'rxjs/operators';
import { BatchSummary, PayoutType, RewardType } from '../api-client';
import { catchReportResume } from '../shared/catch-report-resume-operator';
import { ModalComponentResultType } from '../shared/modal.component';
import { NoCommaPipe } from '../shared/no-comma.pipe';
import { PageInfo } from '../shared/page-info';
import { UsersService } from '../users/users.service';
import { BonusConfigService } from './../bonus-config/bonus-config.service';
import { BatchesConfig } from './batches-config';
import { ProcessingService } from './processing.service';

export type BatchEventType = 'audit' | 'lock' | 'export';

@Component({
  selector: 'app-batches',
  templateUrl: './batches.component.html',
  styles: []
})
export class BatchesComponent implements OnDestroy, OnInit {
  batchToLock: BatchSummary;
  pagedData: { data: BatchSummary[]; pageInfo: PageInfo };
  readonly pageSize = 15;
  state: 'loading' | 'ready' = 'loading';

  pageSource = new BehaviorSubject<number>(1);
  showLockedSource = new BehaviorSubject<boolean>(false);

  payoutTypeMap: Map<number, PayoutType>;

  /** formats amounts on exports */
  private readonly amountFormatter =
    ((dp, nc) => (amt: number) => nc.transform(dp.transform(amt, '1.2-2')))(new DecimalPipe('en-US'), new NoCommaPipe());
  /** formats dates on exports */
  private readonly dateFormatter = ((datePipe) => (dt: Date) => datePipe.transform(dt, 'M/d/yyyy'))(new DatePipe('en-US'));
  private readonly subsMgr = new SubsManager();

  constructor(
    private bonusConfigSvc: BonusConfigService,
    private fileDownloadSvc: FileDownloadService,
    @Inject(LOCALE_ID) private locale: string,
    private notificationsSvc: NotificationsService,
    private processingSvc: ProcessingService,
    private usersSvc: UsersService) { }

  ngOnInit() {
    this.state = 'ready';
    this.subsMgr.subs[0] = merge(
      this.showLockedSource.pipe(distinctUntilChanged()),
      this.pageSource,
      this.bonusConfigSvc.payoutTypeMap$.pipe(tap(x => this.payoutTypeMap = x))
    ).pipe(debounceTime(10))
      .subscribe(() =>
        this.updateBatches(),
      );
  }

  ngOnDestroy() {
    this.subsMgr.onDestroy();
  }

  onItemEvent(evt: { batch: BatchSummary; eventType: BatchEventType; rewardType?: RewardType }) {
    switch (evt.eventType) {
      case 'audit':
        this.createAuditReport(evt.batch.batchId);
        break;
      case 'export':
        this.exportBatch(evt.batch.batchId, evt.rewardType);
        break;
      case 'lock':
        this.batchToLock = evt.batch;
    }
  }

  onLockModalResult(resultType: ModalComponentResultType) {
    if (resultType === 'cancel') {
      this.batchToLock = undefined;
    }
    else if (resultType === 'confirm') {
      const batchName = this.batchToLock.name;
      const batchId = this.batchToLock.batchId;
      this.batchToLock = undefined;
      this.state = 'loading';
      this.processingSvc.updateBatchPartial(batchId, { isExportDisabled: true }).pipe(
        map(() => this.notificationsSvc.addSuccess(`Batch ${batchName} successfully locked.`)),
        catchReportResume(() => this.notificationsSvc.addError(`Locking of batch ${batchName} failed.`)),
        finalize(() => {
          this.state = 'ready';
          this.updateBatches();
        })
      ).subscribe();

    }
  }

  setPageNumber(pageNumber: number) {
    // maintaining pageNumber in route wasn't working
    this.pageSource.next(pageNumber);
  }

  private createAuditReport(batchId: number) {
    this.subsMgr.cancel('createAuditReport');
    this.state = 'loading';
    this.subsMgr.subs['createAuditReport'] = forkJoin([this.processingSvc.getBatchDetail(batchId), this.usersSvc.userMap$])
      .pipe(
        catchReportResume(() => this.notificationsSvc.addError('Failed generating report.  Please reload page and try again.')),
        finalize(() => this.state = 'ready')
      ).subscribe(([detail, userMap]) => {
        const header = ['Payout #', 'Bonus #', 'Batch ID', 'Emp ID', 'Emp Name', 'Department', 'Earnings Code', 'RewardType',
          'Amount', 'Creator', 'Created', 'Applies On', 'Approver', 'Approved', 'Payer', 'Paid'];
        const rows = detail.payouts.map(
          x => [x.payoutId, x.bonusId, detail.name, x.fileNumber, x.empName, x.department,
            x.earningsCode, this.payoutTypeMap.get(x.payoutTypeId).rewardType,
          this.amountFormatter(x.amount), userMap.get(x.creatorId).name, this.dateFormatter(x.createdOn), this.dateFormatter(x.appliesOn),
          userMap.get(x.approverId).name, this.dateFormatter(x.approvedOn), userMap.get(x.payerId).name, this.dateFormatter(x.paidOn)
          ]);
        this.exportCsvCommon(header, rows, `Batch ${detail.name} audit.csv`, 'Audit Report');
      });
  }

  private exportBatch(batchId: number, rewardType: RewardType) {
    this.subsMgr.cancel('exportBatch');
    this.state = 'loading';
    this.subsMgr.subs['exportBatch'] = this.processingSvc.getBatchDetail(batchId).pipe(
      mergeMap(detail => this.processingSvc.updateBatchPartial(batchId, { lastExportOn: new Date() }).pipe(map(() => detail))),
      catchReportResume(() => this.notificationsSvc.addError('Failed generating report.  Please reload page and try again.')),
      finalize(() => {
        this.state = 'ready';
        this.updateBatches();
      })
    ).subscribe(detail => {
      // filtered payouts by rewardType
      const filteredPayouts = detail.payouts.filter(x => this.payoutTypeMap.get(x.payoutTypeId).rewardType === rewardType);
      const exportData = BatchesConfig.exportMap.get(rewardType).exporter(detail.createdOn, filteredPayouts, this.locale);
      const fileName = BatchesConfig.exportMap.get(rewardType).fileNameFormat(detail.name, rewardType);
      // export filtered data to csv
      this.exportCsvCommon(undefined, exportData, fileName, 'Export');
    });
  }

  /**
   * converts header and rows to csv text, initiates download, and displays notification
   */
  private exportCsvCommon(header: any[], rows: any[][], fileName: string, title: string) {
    const allData = (header && header.length) ? [header, ...rows] : rows;
    const text = covertArrayToFlatFile(allData, CSV_FORMAT);
    this.fileDownloadSvc.startRawDownload(fileName, text, 'text/csv');
    this.notificationsSvc.addInfo(
      `${title} '${fileName}' was created.  If download does not begin, make sure popups are allowed.`);
  }

  private updateBatches() {
    this.subsMgr.cancel('updateBatches');
    this.state = 'ready';

    const showLocked = this.showLockedSource.value;
    const pageNumber = this.pageSource.value;
    this.state = 'loading';
    this.subsMgr.subs['updateBatches'] = this.processingSvc.getBatches(showLocked, (pageNumber - 1) * this.pageSize, this.pageSize).pipe(
      map(x => this.pagedData = x),
      catchReportResume(() => this.notificationsSvc.addError('Unable to load batches.  Please reload the page and try again.')),
      finalize(() => this.state = 'ready')
    ).subscribe();
  }
}
