import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersService } from './users.service';
import { UserAccessGuard } from './user-access.guard';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    UserAccessGuard,
    UsersService
  ]
})
export class UsersModule { }
