<form (ngSubmit)="onSubmit()">
  <tcc-simplePanel [options]="{ isFloating: true }" buttons="close" (buttonClick)="panelButtonClick($event)">
    <div panel-header>
      New Bonus
    </div>
    <div panel-body *ngIf="request; else NoRequest" class="small">

      <app-request-lease [request]="request"></app-request-lease>

      <div class="form-group row">
        <label class="col-2 col-form-label" tccLabelForNext>Bonus Type</label>
        <div class="col">
          <tcc-flexi-list [itemSource]="bonusScheduleItems" nonMemberLabel="-- Select One --"
                         [(ngModel)]="request.bonusSchedule" name="bonusSchedule"
                         (ngModelChange)="onBonusScheduleChange()"
                         controlsClass="form-control form-control-sm"
                         [appBootstrapValidity]
                         appNotEmpty></tcc-flexi-list>
          <small>{{ request.bonusSchedule?.description }}</small>
          <div class="invalid-tooltip">Bonus Type is required.</div>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-2 col-form-label" tccLabelForNext>Description</label>
        <div class="col">
          <input type="text" class="form-control form-control-sm"
                 [(ngModel)]="request.description" name="description"
                 [appBootstrapValidity]
                 required />
          <div class="invalid-tooltip">A description is required.</div>
        </div>
      </div>

      <div class="form-group row" *ngIf="(request.bonusSchedule || {}).isResponsibilityAssigned">
        <label class="col-2 col-form-label" tccLabelForNext>Bill to</label>
        <div class="col">
          <tcc-flexi-list [itemSource]="responsibleOrgItems" nonMemberLabel="-- Select One --"
                         [(ngModel)]="request.responsibleOrg" name="responsibleOrg"
                         controlsClass="form-control form-control-sm"
                         [appBootstrapValidity]
                         appNotEmpty></tcc-flexi-list>
          <div class="invalid-tooltip">A community responsible for billing is required.</div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-2 col-form-label" tccLabelForNext>Applies On</label>
        <div class="col-10">
          <input type="date" class="form-control form-control-sm col-4" useValueAsDate
                 [(ngModel)]="request.appliesOn"
                 (ngModelChange)="updateEmployees()"
                 name="appliesOn"
                 [appBootstrapValidity]
                 required />
          <div class="invalid-tooltip">Please Enter a valid date</div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-2 col-form-label text-nowrap">Bonus Total</label>
        <div class="col-4" *ngFor="let t of request.totals; trackBy: getRewardType">
          <ng-container *ngIf="t.rewardType == 'PTO'; else DefaultTotalTemplate">
            <input type="text" readonly class="form-control-plaintext form-control-sm" [value]="t.total + ' Hours'"
                   [appBootstrapValidity]="TotalCtrl" />
            <input type="hidden" [ngModel]="t.total" [name]="'total-' + t.rewardType" #TotalCtrl="ngModel" [appMinValue]="1" />
            <div class="invalid-tooltip">Total Must be greater than 0.</div>
          </ng-container>
          <ng-template #DefaultTotalTemplate>
            <input type="text" readonly class="form-control-plaintext form-control-sm" [value]="t.total | currency"
                   [appBootstrapValidity]="TotalCtrl">
            <input type="hidden" [ngModel]="t.total" [name]="'total-' + t.rewardType" #TotalCtrl="ngModel" [appMinValue]="0.01" />
            <div class="invalid-tooltip">Total Must be greater than $0.00</div>
          </ng-template>
        </div>
      </div>
      <div *ngIf="getRecipientsContainsTerminatedEmployees()" class="alert alert-danger">
        <div class="text-center fw-bold">
            Notice: At least one employee selected is currently terminated.
            <i class="fa-fw fas fa-exclamation-circle text-danger"></i>
        </div>
      </div>
      <hr />
      <ng-container [ngSwitch]="request.bonusSchedule?.formType">
        <ng-container *ngSwitchCase="'Referral'">
          <app-referral-bonus-form [(ngModel)]="request"
                                   [bonusSchedule]="request.bonusSchedule"
                                   [employeesSource]="employeesSource"
                                   name="referralBonusForm"></app-referral-bonus-form>
        </ng-container>
      </ng-container>
      <div class="form-group row" *ngFor="let pt of request.payouts">
        <div class="col-3">
          <label tccLabelForNext>{{pt.name}} Bonus</label>
          <div class="input-group input-group-sm mb-2">
            <div class="input-group-prepend">
              <div class="input-group-text">{{ rewardTypeUiMap.get(pt.rewardType).prefix }}</div>
            </div>
            <input type="number" class="form-control text-end"
                   [tccNumberAccessor]="{ format: rewardTypeUiMap.get(pt.rewardType).format , noGroupSeperator: true, defaultValue: 0 }"
                   [(ngModel)]="pt.amount"
                   (ngModelChange)="onPayoutAmountChanged()"
                   [name]="pt.payoutTypeId + ':' + pt.amount + '-amount'"
                   [appMinValue]="0"
                   [appBootstrapValidity]
                   [disabled]="isAmountsDisabled" min="0" required>
          </div>
        </div>
        <div class="col">
          <app-employee-selector [orgCode]="request.org?.orgCode" [employeesSource]="employeesSource"
                                 [(ngModel)]="pt.recipients" [name]="pt.payoutTypeId + ':' + pt.amount + '-recipients'"
                                 [appMinArrayLength]="pt.amount > 0 ? 1 : 0"
                                 [appBootstrapValidity]
                                 [disabled]="isEmployeesDisabled">
          </app-employee-selector>
          <div class="invalid-tooltip">You must select at lease one recipient for the {{pt.name}} Bonus.</div>
        </div>
      </div>

      <div class="position-relative" *ngIf="!hideAttachments">
        <hr />
        <app-attachments label="Upload Supporting Documentation"
                     name="attachments" (completeAll)="onUploadsComplete()"
                     ngModel
                     [appBootstrapValidity]></app-attachments>
        <div class="invalid-tooltip">You must upload supporting documentation for this bonus type.</div>
      </div>

      <tcc-loadingOverlay *ngIf="state != 'ready'"></tcc-loadingOverlay>
    </div>
    <ng-template #NoRequest>
      <div panel-body>
        Waiting for more information
      </div>
    </ng-template>
    <div panel-footer>
      <button class="btn btn-sm btn-primary" [disabled]="state != 'ready'">Submit</button>
    </div>
  </tcc-simplePanel>
</form>
