/* eslint-disable @typescript-eslint/member-ordering */
import { Directive, forwardRef, HostListener, Renderer2, ElementRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { formatDate } from '@angular/common';

export const DATE_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DateValueAccessorDirective),
  multi: true
};

const dateValueFormatter = (val: any) => formatDate(val, 'yyyy-MM-dd', 'en-US');
/**
 * copied from https://github.com/JohannesHoppe/angular-date-value-accessor
 */
@Directive({
  selector: '[useValueAsDate]',
  providers: [DATE_VALUE_ACCESSOR]
})
export class DateValueAccessorDirective implements ControlValueAccessor {

  // // This doesn't work for IE
  //@HostListener('input', ['$event.target.valueAsDate'])
  //onChange = (evt: any) => { };

  @HostListener('input', ['$event.target.value'])
  onChange = (evt: any) => { };

  @HostListener('blur', [])
  onTouched = () => { };

  constructor(private renderer: Renderer2, private elementRef: ElementRef) { }

  writeValue(value: any): void {
    if (typeof value === 'string') {
      this.renderer.setProperty(this.elementRef.nativeElement, 'value', value);
    }
    else if (value instanceof Date) {
      this.renderer.setProperty(this.elementRef.nativeElement, 'value', dateValueFormatter(value));
    }
    else {
      this.renderer.setProperty(this.elementRef.nativeElement, 'value', null);
    }
  }

  registerOnChange(fn: (evt: any) => void): void {
    // create change function that makes the value a date
    this.onChange = (value: string) => {
      const dt = this.stringToDate(value);
      fn(dt);
    };
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
  }

  private stringToDate(text: string) {
    if (!text) {
      return undefined;
    }
    const dt = new Date(Date.parse(text));
    if (isNaN(dt.valueOf())) {
      return undefined;
    }
    // correct time zone;
    return new Date(dt.getUTCFullYear(), dt.getUTCMonth(), dt.getUTCDate(), dt.getUTCHours(), dt.getUTCMinutes(), dt.getUTCSeconds());
  }
}
