/* eslint-disable @typescript-eslint/member-ordering */
import { Component, ExistingProvider, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl, ControlValueAccessor, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator, Validators
} from '@angular/forms';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { Employee, MetaFormType, ReferralForm } from '../../api-client';
import { MergedBonusSchedule } from '../../bonus-config/bonus-config.service';
import { FlexiListItem } from '../../shared/flexi-list-control.component';
import { ReferralFormStrong } from '../meta-form-factory';
import { RequestPayoutTypeVm } from '../request-payout-type-vm';
import { RequestVm } from '../request-vm';

export const REFERRAL_BONUS_ACCESSOR: ExistingProvider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ReferralBonusFormComponent),
  multi: true
};

export const REFERRAL_BONUS_VALIDATOR: ExistingProvider = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => ReferralBonusFormComponent),
  multi: true
};
/** There could be more fork controls, but right now there is only one. */
type ControlKey = 'referredEmail';
type ReferralFormGroupControls = { [key in ControlKey]: AbstractControl };

interface ReferralFormGroup extends FormGroup {
  controls: ReferralFormGroupControls;
}

@Component({
  selector: 'app-referral-bonus-form',
  templateUrl: './referral-bonus-form.component.html',
  styles: [],
  providers: [REFERRAL_BONUS_ACCESSOR, REFERRAL_BONUS_VALIDATOR]
})
export class ReferralBonusFormComponent implements OnInit, OnDestroy, Validator, ControlValueAccessor {

  private _bonusScedule: MergedBonusSchedule;

  private _employeesSource: Employee[] = [];

  /** when fired will signify that the component is destroyed.  use in takeUntil. */
  private destroyedSubject = new Subject();

  /** call back for registered change listener. */
  private onChange = (evt: any) => { };

  /** call back for registered touch listener. */
  private onTouched = () => { };

  @Input()
  get bonusSchedule() { return this._bonusScedule; }
  set bonusSchedule(value: MergedBonusSchedule) {
    this._bonusScedule = value;
    this.updateFormEnabledState();
  }

  @Input()
  get employeesSource() { return this._employeesSource; }
  set employeesSource(value: Employee[]) {
    this._employeesSource = value || [];
    this.employeeEmailItems = this._employeesSource.filter(x => !!x.email)
      .map(x => ({ label: x.email, value: x.email }));
  }

  employeeEmailItems: FlexiListItem<string>[] = [];

  isDisabled = false;

  referralForm: ReferralFormGroup = new FormGroup({
    referredEmail: new FormControl('', [Validators.required, Validators.minLength(1)])
  } as ReferralFormGroupControls) as ReferralFormGroup;


  request: RequestVm<ReferralForm>;

  payout: RequestPayoutTypeVm;

  constructor() {

  }

  ngOnInit() {
    this.referralForm.valueChanges.pipe(
      takeUntil(this.destroyedSubject),
      tap(() => this.onTouched()),
      filter(() => !!this.request),
      tap(() => {
        if (!this.request.description && this.referralForm.controls.referredEmail.valid) {
          const emp = this.employeesSource.find(x => (x.email || '').toLowerCase() ===
            (this.referralForm.controls.referredEmail.value as string).toLowerCase());
          if (emp) {
            this.request.description = `Referral bonus for ${emp.name}`;
          }
        }
        if (this.referralForm.valid) {
          const { referredEmail } = this.referralForm.controls;
          const strongForm: ReferralFormStrong = { $formType: MetaFormType.Referral, referredEmployeeEmail: referredEmail.value };
          this.request.meta = strongForm;
        }
        this.onChange(this.request);
      })
    ).subscribe();

    this.updateFormEnabledState();
  }

  ngOnDestroy() {
    this.destroyedSubject.next();
  }

  registerOnChange(fn: (evt) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  registerOnValidatorChange?(_: () => void): void {

  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.referralForm.disable();
    }
    else {
      this.referralForm.enable();
    }
  }

  writeValue(req: RequestVm<ReferralForm>): void {
    this.request = req;
    const { referredEmail } = this.referralForm.controls;
    referredEmail.setValue(req && req.meta && req.meta.referredEmployeeEmail || undefined);
  }

  validate(_: FormControl) {
    return (this.referralForm.valid)
      ? null
      : {
        referralForm: { valid: false }
      };
  }

  private updateFormEnabledState() {
    if (!this._bonusScedule) {
      this.referralForm.disable();
    }
    else {
      this.referralForm.enable();
    }
  }
}
