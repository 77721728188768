import { ApiException } from '../api-client';


export class ExceptionInfo {
  /**
   * Returns an ExceptionInfo object from JSON string
   */
  static fromJsonString(str: string) {
    if (str) {
      return ExceptionInfo.fromObject(JSON.parse(str));
    }
  }

  /**
   * Returns an ExceptionInfo object from and object.
   */
  static fromObject(obj: any) {
    if (!obj) {
      return;
    }
    const dest = new ExceptionInfo();
    dest.discriminator = obj.discriminator;
    dest.message = obj.message;
    dest.source = obj.source;
    dest.stackTrace = obj.stackTrace;
    dest.innerException = ExceptionInfo.fromObject(obj.innerException);
    return dest;
  }

  discriminator?: string;
  innerException?: ExceptionInfo;
  message?: string;
  source?: string;
  stackTrace?: string;
}


export class SwaggerExceptionWithServerInfo extends ApiException {

  static isSwaggerExceptionWithServerInfo(obj: any): obj is SwaggerExceptionWithServerInfo {
    return obj.isSwaggerExceptionWithAdditionalInfo === true;
  }

  static hasServerMessage(err: SwaggerExceptionWithServerInfo) {
    return err.serverException && err.serverException.message && true;
  }
  serverException: ExceptionInfo;

  protected isSwaggerExceptionWithAdditionalInfo = true;

  constructor(err: ApiException) {
    super(err.message, err.status, err.response, err.headers, err.result);

    this.serverException = ExceptionInfo.fromJsonString(err.response);
    this.stack = err.stack;
  }
}
