import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BonusesService } from './bonuses.service';
import { NotificationsService, SubsManager } from '@tcc/ui';
import { BonusDetail } from '../api-client';
import { ModalComponentResultType } from '../shared/modal.component';
import { map, finalize } from 'rxjs/operators';
import { catchReportResume } from '../shared/catch-report-resume-operator';

@Component({
  selector: 'app-bonus-delete-modal',
  template: `
<app-modal *ngIf="bonus" [title]="'Delete ' + bonus.bonusId + '?'" (modalResult)="onDeleteModalResult($event)">
  Are you sure you want to delete bonus {{bonus.bonusId}}, <em>{{bonus.title}}</em>,
  created on {{bonus.createdOn | date : 'M/d/yyyy'}}?
</app-modal>
`
})
export class BonusDeleteModalComponent implements OnDestroy, OnInit {
  bonus: BonusDetail;
  state: 'loading' | 'ready';

  private readonly subsMgr = new SubsManager();
  constructor(private bonusesSvc: BonusesService, private notificationsSvc: NotificationsService,
    private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.state = 'loading';
    this.subsMgr.subs[0] = this.route.data.subscribe((x: { bonus: BonusDetail }) => {
      this.bonus = x.bonus;
      if (this.bonus === null) {
        this.notificationsSvc.addWarning('Unable to load lease, possibly because it has already been hidden.');
        this.router.navigate([{ outlets: { popups: null } }], { queryParams: { reload: new Date().valueOf() } });
      }
      this.state = (this.bonus) ? 'loading' : 'ready';
    });
  }

  ngOnDestroy() {
    this.subsMgr.onDestroy();
  }

  onDeleteModalResult(resultType: ModalComponentResultType) {
    switch (resultType) {
      case 'cancel':
        this.router.navigate([{ outlets: { popups: null } }]);
        break;
      case 'confirm':
        const bonusId = this.bonus.bonusId;
        this.state = 'loading';
        this.bonusesSvc.deleteBonus(bonusId).pipe(
          map(() => this.notificationsSvc.addSuccess(`Bonus ${bonusId} successfully deleted.`)),
          catchReportResume(() => this.notificationsSvc.addError(`Failed deleting ${bonusId}.`)),
          finalize(() => {
            this.state = 'ready';
            this.router.navigate([{ outlets: { popups: null } }], { queryParams: { reload: new Date().valueOf() } });
          })
        ).subscribe();
        break;
    }
  }
}
