import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequestEditorComponent } from './request-editor.component';
import { RequestLeaseComponent } from './request-lease.component';
import { RequestViewComponent } from './request-view.component';
import { RequestsService } from './requests.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TccUiModule } from '@tcc/ui';
import { SharedModule } from '../shared/shared.module';
import { OrgsModule } from '../orgs/orgs.module';
import { EmployeesModule } from '../employees/employees.module';
import { RequestEditorParamsResolver } from './request-editor-params.resolver';
import { AttachmentsModule } from '../attachments/attachments.module';
import { ReferralBonusFormComponent } from './referral-bonus/referral-bonus-form.component';
import { ReferralBonusViewComponent } from './referral-bonus/referral-bonus-view.component';
import { RouterModule } from '@angular/router';
import { AuditsModule } from '../audits/audits.module';

@NgModule({
  imports: [
    AttachmentsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TccUiModule,
    AuditsModule,
    EmployeesModule,
    OrgsModule,
    SharedModule,
    RouterModule
  ],
  declarations: [
    RequestEditorComponent,
    RequestLeaseComponent,
    RequestViewComponent,
    ReferralBonusFormComponent,
    ReferralBonusViewComponent
  ],
  providers: [
    RequestEditorParamsResolver,
    RequestsService
  ]
})
export class RequestsModule { }
