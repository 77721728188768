<tcc-simplePanel>
  <div panel-header>
    Home
  </div>
  <div panel-body>
    <ng-container [ngSwitch]="overviews?.length || 0">
      <ng-container *ngSwitchCase="0">
        No Communities Loaded
      </ng-container>
      <ng-container *ngSwitchCase="1">
        <ng-template #SingleOrgTemplate let-org="org">
          <h1>{{org.name}} ({{org.orgCode}})</h1>
          <p>Below are items of interest created over the last {{lookbackDays}} days.</p>
          <div class="h3 text-center">
            <div class="row pt-4">
              <div class="col">
                <div>Leases Without Bonus</div>
                <a class="d-block" [routerLink]="getLeasesWithoutBonusLink()" [queryParams]="{ orgCode: org.orgCode }">{{org.leasesWithoutBonus}}</a>
              </div>
              <div class="col">
                <div>Non Lease Bonuses</div>
                <a class="d-block" [routerLink]="getNonLeaseBonusLink()" [queryParams]="{ orgCode: org.orgCode }">{{org.nonLeaseBonuses}}</a>
              </div>
            </div>
            <div class="row pt-4">
              <div class="col">
                <div>Unapproved Bonuses</div>
                <a class="d-block" [routerLink]="getUnapprovedLink$() | async" [queryParams]="{ orgCode: org.orgCode }">{{org.unapprovedBonuses}}</a>
              </div>
              <div class="col">
                <div>Unpaid Bonuses</div>
                <a class="d-block" [routerLink]="getUnpaidLink$() | async" [queryParams]="{ orgCode: org.orgCode }">{{org.unpaidBonuses}}</a>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-container *ngTemplateOutlet="SingleOrgTemplate; context: { org: overviews[0]}"></ng-container>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <p>Below are items of interest created over the last {{lookbackDays}} days.</p>
        <tcc-table tableClass="table-sm small" [rows]="overviews"
                   [columns]="[
                    { name: 'Community', cellTemplateName: 'Community'},
                    { name: 'leasesWithoutBonus', cellTemplateName: 'leasesWithoutBonus', title: 'No Bonus' },
                    { name: 'unapprovedBonuses', cellTemplateName: 'unapprovedBonuses', title: 'Unapproved' },
                    { name: 'unpaidBonuses', cellTemplateName: 'unpaidBonuses', title: 'Unpaid' },
                    { name: 'nonLeaseBonuses', cellTemplateName: 'nonLeaseBonuses', title: 'Non-Lease' }
                   ]">
          <ng-template cellTemplateName="Community" let-org="row">
            {{org.name}} ({{org.orgCode}})
          </ng-template>
          <ng-template cellTemplateName="leasesWithoutBonus" let-org="row">
            <a class="d-block" [routerLink]="getLeasesWithoutBonusLink()" [queryParams]="{ orgCode: org.orgCode }">{{org.leasesWithoutBonus}}</a>
          </ng-template>
          <ng-template cellTemplateName="unapprovedBonuses" let-org="row">
            <a class="d-block" [routerLink]="getUnapprovedLink$() | async" [queryParams]="{ orgCode: org.orgCode }">{{org.unapprovedBonuses}}</a>
          </ng-template>
          <ng-template cellTemplateName="unpaidBonuses" let-org="row">
            <a class="d-block" [routerLink]="getUnpaidLink$() | async" [queryParams]="{ orgCode: org.orgCode }">{{org.unpaidBonuses}}</a>
          </ng-template>
          <ng-template cellTemplateName="nonLeaseBonuses" let-org="row">
            <a class="d-block" [routerLink]="getNonLeaseBonusLink()" [queryParams]="{ orgCode: org.orgCode }">{{org.nonLeaseBonuses}}</a>
          </ng-template>
        </tcc-table>
      </ng-container>
    </ng-container>
    <tcc-loadingOverlay *ngIf="state != 'ready'"></tcc-loadingOverlay>
  </div>
</tcc-simplePanel>
