import { Injectable } from '@angular/core';
import { combineLatest, forkJoin, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { BaseMetaForm, BonusCreationInfo, BonusDetail, Employee, EventType, LeaseType, PayoutType, Recipient } from '../api-client';
import { BonusConfigService } from '../bonus-config/bonus-config.service';
import { EmployeesService } from '../employees/employees.service';
import { LeasesService } from '../leases/leases.service';
import { OrgsService } from '../orgs/orgs.service';
import { UsersService } from '../users/users.service';
import { RequestPayoutTypeCreationInfo, RequestPayoutTypeVm } from './request-payout-type-vm';
import { calculatRequestTotals, RequestCreationInfo, RequestVm } from './request-vm';

@Injectable()
export class RequestsService {
  readonly fullTimeShare = 2;
  readonly partTimeShare = 1;

  constructor(
    private bonusConfigSvc: BonusConfigService,
    private empsSvc: EmployeesService,
    private leasesSvc: LeasesService,
    private orgsSvc: OrgsService,
    private usersSvc: UsersService) {
  }

  bonusToRequest(bonus: BonusDetail) {
    const approvalDecisionEvent = (bonus.events || []).find(x => x.eventType === EventType.Denied)
      || (bonus.events || []).find(x => x.eventType === EventType.Approved);
    const paidEvent = (bonus.events || []).find(x => x.eventType === EventType.Paid);

    return forkJoin([
      this.bonusConfigSvc.getApplicableBonusSchedule(bonus.bonusTypeId, bonus.orgCode, bonus.leaseType),
      (bonus.leasingActivityId) ? this.leasesSvc.getLease(bonus.leasingActivityId) : of(undefined),
      this.orgsSvc.getOrgByOrgCode(bonus.orgCode),
      this.orgsSvc.getOrgByOrgCode(bonus.responsibleOrgCode),
      this.usersSvc.userMap$,
      this.bonusConfigSvc.payoutType$
    ]).pipe(
      map(([bonusSchedule, lease, org, responsibleOrg, userMap, payoutTypes]) => {

        const request: RequestVm<BaseMetaForm> = {
          appliesOn: bonus.appliesOn,
          approvalDecisionOn: approvalDecisionEvent ? approvalDecisionEvent.createdOn : undefined,
          approver: approvalDecisionEvent ? userMap.get(approvalDecisionEvent.creatorId) : undefined,
          bonusSchedule,
          bonusStatus: bonus.bonusStatus,
          creator: userMap.get(bonus.creatorId),
          description: bonus.title,
          lease,
          meta: bonus.metaJson ? JSON.parse(bonus.metaJson) : undefined,
          org,
          paidOn: paidEvent ? paidEvent.createdOn : undefined,
          payer: paidEvent ? userMap.get(paidEvent.creatorId) : undefined,
          payouts: [],
          referenceBonusId: bonus.referenceBonusId,
          responsibleOrg,
          totals: []
        };
        for (const recip of bonus.recipients) {
          let payout = request.payouts.find(x => x.payoutTypeId === recip.payoutTypeId);
          if (!payout) {
            // create and add payout if it doesn't exist.
            payout = {
              amount: 0,
              name: payoutTypes.find(x => x.payoutTypeId === recip.payoutTypeId).name,
              payoutTypeId: recip.payoutTypeId,
              recipients: [],
              rewardType: payoutTypes.find(x => x.payoutTypeId === recip.payoutTypeId).rewardType
            };
            request.payouts.push(payout);
          }
          payout.amount += recip.amount;
          payout.recipients.push(recip);
        }
        calculatRequestTotals(request);
        return request;
      })
    );
  }
  /**
   * converts a request to a bonus suitable for creating a new bonus
   *
   * @param request
   */
  requestToBonusCreationInfo(request: RequestCreationInfo<any>) {
    return combineLatest([this.empsSvc.empMap$, this.bonusConfigSvc.payoutTypeMap$]).pipe(
      take(1),
      map(([empMap, pt]) => ({
        appliesOn: request.appliesOn,
        bonusTypeId: request.bonusSchedule.bonusTypeId,
        leaseType: request.lease ? request.lease.leaseType : LeaseType.NonLease,
        leasingActivityId: request.lease ? request.lease.leasingActivityId : undefined,
        metaForm: request.meta,
        orgCode: request.org.orgCode,
        recipients: request.payouts.reduce((p, c) => p.concat(this.createPayoutRecipients(c, pt, empMap)), []),
        responsibleOrgCode: (request.responsibleOrg || {}).orgCode,
        title: request.description
      } as BonusCreationInfo)));
  }

  /** Creates an array of recipients from payout creation info. */
  private createPayoutRecipients(payout: RequestPayoutTypeCreationInfo, payoutTypes: Map<number, PayoutType>,
    empMap: Map<number, Employee>): Recipient[] {

    const { amount, recipients, payoutTypeId } = payout;

    if (!recipients || recipients.length === 0 || amount <= 0) { return []; }

    const recipientEmployees = recipients.map(empId => empMap.get(empId))
      .filter((x): x is Employee => !!x);

    if (recipientEmployees.length < recipients.length) {
      throw new Error('Unable to find employee record for at least one recipient.');
    }

    // full time employees get two shares, part time just one.
    const fullTimeCount = recipientEmployees.filter(x => x.isFullTime && true).length * 1;
    const shares = (fullTimeCount) * this.fullTimeShare + (recipients.length - fullTimeCount) * this.partTimeShare;
    const shareAmt = this.currencyFloat(amount / shares);
    const transformedRecipients = recipientEmployees.map(x => {
      const empAmount = this.currencyFloat(shareAmt * ((x.isFullTime && true) ? this.fullTimeShare : this.partTimeShare));
      return this.createRecipient(empAmount, x, payoutTypes.get(payoutTypeId));
    });

    // make sure if the total is off by a penny to fix the last recipient
    const totalShareDifference = this.currencyFloat((shareAmt * shares) - amount);
    if (totalShareDifference !== 0) {
      this.fixSharedAmounts(transformedRecipients, totalShareDifference);
    }

    return transformedRecipients;
  }

  /**
   * Creates a bonus recipient
   */
  private createRecipient(amount: number, src: Employee, payoutType: PayoutType): Recipient {
    return {
      amount,
      createdOn: new Date(Date.now()),
      description: `${payoutType.name} bonus for ${src.name}`,
      empId: src.employeeId,
      isFullTime: src.isFullTime,
      name: src.name,
      paidOn: undefined,
      payoutTypeId: payoutType.payoutTypeId,
      recipientId: 0
    };
  }

  /**
   * returns a float that was first converted to 2 decimal to avoid rounding errors
   *
   * @param num floating point number to fix
   */
  private currencyFloat(num: number) {
    return parseFloat(num.toFixed(2));
  }

  /**
   * Adds or subtracts pennies to ensure that recipients amounts balance
   */
  private fixSharedAmounts(recipients: Recipient[], amountToFix: number) {
    amountToFix = this.currencyFloat(amountToFix);

    const effectAmt = amountToFix > 0 ? -.01 : .01;
    const availableRecipients = recipients.slice(0);

    while (amountToFix !== 0 && availableRecipients.length > 0) {
      // get random index of available indexes not yet used
      const index = availableRecipients.length - 1 - Math.floor(Math.random() * availableRecipients.length);
      // remove that recipient
      const recipient = availableRecipients.splice(index, 1)[0];
      recipient.amount = this.currencyFloat(recipient.amount + effectAmt);
      amountToFix = this.currencyFloat(amountToFix + effectAmt);
    }
  }
}
