<nav class="navbar navbar-fixed-top bg-gradient-collier text-white">
  <a class="navbar-brand text-white">
    <img src="assets/images/icon.png" />
    Community Bonuses
  </a>
  <tcc-user-status>
      <div class="text-end ng-star-inserted">
        <div class="ng-star-inserted">Not logged in</div>
        <div class="ng-star-inserted">
            <a class="text-light" ng-reflect-router-link="./auth/login" href="/tccui/auth/login">SIGN IN</a>
        </div>
      </div>
    </tcc-user-status>
</nav>
<div class="contentPanel" #container>
  <simpleMenu [isMainMenu]="true" [isExpandable]="true" *ngIf="!hideNav">
    <button type="button" class="btn btn-sm btn-light" title="Home" [routerLink]="['/home']" routerLinkActive="active">
        <i class="fa fa-home fa-fw"></i><span class="ms-1 visible-expanded">Home</span>
    </button>
    <button type="button" class="btn btn-sm btn-light" title="Leases" [routerLink]="['/leases']" routerLinkActive="active">
        <i class="far fa-file-alt fa-fw"></i><span class="ms-1 visible-expanded">Leases</span>
    </button>
    <button type="button" class="btn btn-sm btn-light" title="Bonuses" [routerLink]="['/bonuses']" routerLinkActive="active">
        <i class="far fa-credit-card fa-fw"></i><span class="ms-1 visible-expanded">Bonuses</span>
    </button>
    <button type="button" class="btn btn-sm btn-light" *ngIf="access?.isApprover" title="Approvals" [routerLink]="['/approvals']"
      routerLinkActive="active">
        <i class="far fa-thumbs-up fa-fw"></i><span class="ms-1 visible-expanded">Approvals</span>
    </button>
    <button type="button" class="btn btn-sm btn-light" *ngIf="access?.isPayer" title="Payouts"
          [routerLink]="['/payouts']" routerLinkActive="active">
        <i class="fas fa-dollar-sign fa-fw"></i><span class="ms-1 visible-expanded">Payouts</span>
    </button>
    <button type="button" class="btn btn-sm btn-light" *ngIf="access?.isPayer" title="Batches"
          [routerLink]="['/batches']" routerLinkActive="active">
        <i class="fas fa-archive fa-fw"></i><span class="ms-1 visible-expanded">Batches</span>
    </button>
    <button type="button" class="btn btn-sm btn-light" title="Bonus Summary Report"
          [routerLink]="['/bonus-summary-report']" routerLinkActive="active">
        <i class="fas fa-th fa-fw"></i><span class="ms-1 visible-expanded">Bonus Summary Report</span>
    </button>
  </simpleMenu>
  <router-outlet></router-outlet>
  <router-outlet name="popups"></router-outlet>
  <tcc-loadingOverlay *ngIf="(state$ | async)  === 'transitioning'"></tcc-loadingOverlay>
</div>
<tcc-preloader *ngIf="(state$ | async) === 'preloading'">Community Bonuses</tcc-preloader>
<tcc-notifications [defaultDuration]="10000"></tcc-notifications>
