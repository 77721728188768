import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { AUTH_CONFIG, AppInsightsModule, AppInsightsTelemtryItem, AuthModule, TccUiModule } from '@tcc/ui';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { ApiClient, API_BASE_URL } from './api-client';

import { BonusConfigModule } from './bonus-config/bonus-config.module';
import { BonusesModule } from './bonuses/bonuses.module';
import { EmployeesModule } from './employees/employees.module';
import { LeasesModule } from './leases/leases.module';
import { OrgsModule } from './orgs/orgs.module';
import { ProcessingModule } from './processing/processing.module';
import { RequestsModule } from './requests/requests.module';
import { UsersModule } from './users/users.module';
import { SharedModule } from './shared/shared.module';
import { AuditsModule } from './audits/audits.module';
import { ConfigService } from './config.service';

/** sets the cloud role in app insights telemetry */
export function aiTelemInitializer(env: AppInsightsTelemtryItem) {
  env.tags['ai.cloud.role'] = 'TccBonusApprovals Web Client';
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    NgbDropdownModule,
    NgbPaginationModule,
    NgbTypeaheadModule,
    TccUiModule.forRoot(),
    AppInsightsModule.forRoot(
      { instrumentationKey: environment.appInsightsId },
      [aiTelemInitializer]
    ),
    AuthModule.forRoot(),
    AppRoutingModule,
    AuditsModule,
    BonusConfigModule,
    BonusesModule,
    EmployeesModule,
    LeasesModule,
    OrgsModule,
    ProcessingModule,
    RequestsModule,
    SharedModule,
    UsersModule
  ],
  providers: [
    ApiClient,
    ConfigService,
    {
      provide: APP_INITIALIZER,
      deps: [ConfigService],
      useFactory: (configService: ConfigService) => () => configService.load(environment),
      multi: true
    },
    {
      provide: API_BASE_URL,
      deps: [ConfigService, APP_INITIALIZER],
      useFactory: (configService: ConfigService) => configService?.get('apiBaseUrl')
    },
    {
      provide: AUTH_CONFIG,
      deps: [ConfigService, APP_INITIALIZER],
      useFactory: (configService: ConfigService) => configService?.getAuthConfig()
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
