import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Params, Router, RouterStateSnapshot, UrlSegment, UrlSegmentGroup } from '@angular/router';



@Injectable({ providedIn: 'root'})
export class RouteUtilService {

  constructor(private router: Router) { }

  /**
   * Searches from routeSnapshot up to find param with matching name.
   */
  findParamValue(routeSnapshot: ActivatedRouteSnapshot, paramName: string): { isSuccess: boolean; value: any } {
    while (routeSnapshot) {
      if (routeSnapshot.params?.hasOwnProperty(paramName)) {
        return { isSuccess: true, value: routeSnapshot.params[paramName] };
      }
      routeSnapshot = routeSnapshot.parent;
    }
    return { isSuccess: false, value: undefined };
  }

  /**
   * Searches from searchRoot down to find a param matching paramName
   */
  findParamValueGlobal(searchRoot: ActivatedRouteSnapshot, paramName: string): { isSuccess: boolean; value: any } {
    let snapshot: ActivatedRouteSnapshot | undefined;
    const queue = [searchRoot];
    while ((snapshot = queue.shift()) != null) {
      const params = [snapshot.params, ...snapshot.url.map(x => x.parameters)].find(x => x.hasOwnProperty(paramName));
      if (params) {
        return { isSuccess: true, value: params[paramName] };
      }
      queue.push(...snapshot.children);
    }
    return { isSuccess: false, value: undefined };
  }

  /**
   * Cosntructs parent url tree from state and routeUrl, preserving all other outlets
   */
  getParentTree(stateUrl: string, routeUrl: UrlSegment[]) {
    const urlTree = this.router.parseUrl(stateUrl);
    const matchInfo = this.findTreeSegment(urlTree.root, routeUrl[routeUrl.length - 1]);
    const lastRouteSegmentIndex = matchInfo.group.segments.indexOf(matchInfo.segment);
    matchInfo.group.segments.splice(lastRouteSegmentIndex, 1);
    this.pruneUrlTree(urlTree.root);
    return urlTree;
  }

  navigateToParent(stateOrUrl: { url: string } | string, routeUrl: UrlSegment[]) {
    const url = typeof stateOrUrl === 'string' ? stateOrUrl : stateOrUrl.url;
    const urlTree = this.getParentTree(url, routeUrl);
    return this.router.navigateByUrl(urlTree);
  }

  /**
   * Updates route with params
   *
   * @param route the route to update
   * @param params params to set on the route
   * @param preserveParams if true, keeps route params not specified in params parameter
   */
  updateRouteParams(route: ActivatedRoute, params: { [key: string]: any }, preserveParams: boolean) {
    return this.updateSegmentParams(this.router.routerState.snapshot, route.snapshot.url, params, preserveParams);
  }

  updateSegmentParams(state: RouterStateSnapshot, routeUrl: UrlSegment[], params: { [key: string]: any }, preserveParams: boolean) {
    const urlTree = this.router.parseUrl(state.url);
    const matchInfo = this.findTreeSegment(urlTree.root, routeUrl[routeUrl.length - 1]);
    const processedParams = this.updateSegmentParameters(matchInfo.segment.parameters, params, preserveParams);

    if (processedParams.isUpdateNeeded) {
      matchInfo.segment.parameters = processedParams.params;
      this.pruneUrlTree(urlTree.root);
      return this.router.navigateByUrl(urlTree);
    }
  }

  /**
   * finds a segment from rootSegmentGroup that is equivelant to the passed segment or returns undefined
   */
  private findTreeSegment(rootSegmentGroup: UrlSegmentGroup, segment: UrlSegment): { group: UrlSegmentGroup; segment: UrlSegment } {
    let currentGroup: UrlSegmentGroup | undefined;
    const searchQueue = [rootSegmentGroup];
    while ((currentGroup = searchQueue.shift()) != null) {
      for (const groupSegment of currentGroup.segments) {
        // find matching segment
        if (groupSegment.path === segment.path && this.paramKeysEqual(groupSegment.parameters, segment.parameters)) {
          return { group: currentGroup, segment: groupSegment };
        }
      }
      for (const childKey of Object.keys(currentGroup.children)) {
        // no match found so add children to search
        searchQueue.push(currentGroup.children[childKey]);
      }
    }
  }

  /** Determines if params collections have the same keys. */
  private paramKeysEqual(a: Params | undefined, b: Params | undefined) {
    if (!a && !b) {
      return true;
    }
    if (a && !b || !a && b) {
      return false;
    }
    const aKeys = Object.keys(a);
    const bKeys = Object.keys(b);
    return (aKeys.length === bKeys.length && aKeys.every(x => bKeys.includes(x)));
  }

  /**
   * removes any children that have no segments with a path or parameters
   */
  private pruneUrlTree(root: UrlSegmentGroup) {
    for (const [key, child] of Object.entries(root.children)) {
      const shouldPrune = !child.segments.some(x => x.path || x.parameterMap.keys.length !== 0);
      if (shouldPrune) {
        delete root.children[key];
      }
      else {
        this.pruneUrlTree(child);
      }
    }
  }

  /** Determines if params need updating, and compiles the updated params. */
  private updateSegmentParameters(routeParams: { [key: string]: any }, params: { [key: string]: any }, preserveParams: boolean) {
    let isUpdateNeeded = false;

    if (!preserveParams) {
      // if not preserving params then check to see if there is an existing param not in params that should be removed
      isUpdateNeeded = Object.entries(routeParams).some(([key, value]) => value !== undefined && params[key] === undefined);
    }

    if (!isUpdateNeeded) {
      // check to see if any existing params need to be updated
      isUpdateNeeded = Object.entries(params).some(([key, value]) => routeParams[key] !== value);
    }

    return (!isUpdateNeeded)
      ? { isUpdateNeeded, params: undefined }
      : { isUpdateNeeded, params: preserveParams ? { ...routeParams, ...params } : params };
  }
}
