import { Injectable } from '@angular/core';
import { ApiClient, LeaseBonusStatusFilter, ApiException } from '../api-client';
import { PageInfo } from '../shared/page-info';
import { throwError, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class LeasesService {

  constructor(private apiClient: ApiClient) { }

  /**
   * Gets a lease by id.  If 404 status is returned, returns null.
   */
  getLease(leaseId: number) {
    return this.apiClient.getLease(leaseId).pipe(
      map(x => x.result),
      catchError(err => { //catchError<Lease, Lease>(err => {
        if (ApiException.isApiException(err)) {
          if (err.status === 404) {
            return of(null);
          }
        }
        return throwError(err);
      })
    );
  }

  getLeaseDuplicates(leaseId: number) {
    return this.apiClient.getLeasePossibleDuplicates(leaseId).pipe(map(x => x.result));
  }

  /**
   * gets pages leases
   */
  getLeases(orgCodes: string[], bonusStatus: LeaseBonusStatusFilter, customerNameFilter: string, skip: number, top: number) {
    return this.apiClient.getLeases(orgCodes, bonusStatus, customerNameFilter, skip, top, undefined, true).pipe(
      map(x => ({
        data: x.result,
        pageInfo: new PageInfo(top, skip, x.headers['$count'])
      }))
    );
  }

  updateLeaseBonusInfo(leaseId: number, isIneligibleForBonus: boolean) {
    return this.apiClient.postLeaseBonusEligibility(leaseId, { value: isIneligibleForBonus });
  }
}
