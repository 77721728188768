import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AuditResultType, BonusAudit } from '../api-client';

@Component({
  selector: 'app-audit-failures',
  templateUrl: './audit-failures.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuditFailuresComponent implements OnInit {

  @Input()
  set audits(value: BonusAudit[] | null | undefined) {
    this.failedAudits = value.filter(x => x.resultType === AuditResultType.Failed);
  }

  failedAudits: BonusAudit[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}
