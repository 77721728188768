/* eslint-disable @typescript-eslint/member-ordering */
import { Component, ElementRef, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Employee, EmployeeSummaryDated, Recipient } from '../api-client';

@Component({
  selector: 'app-employee-selector',
  templateUrl: './employee-selector.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EmployeeSelectorComponent),
      multi: true
    }]
})
export class EmployeeSelectorComponent implements ControlValueAccessor {

  /** should only org employees be shown, or all */
  employeesSourceFilter: 'org' | 'all' = 'org';

  /** sets html input controls disabled attribute */
  isDisabled = false;

  /** is multiple selections allowed - defaults to true. */
  @Input()
  isMultiple = true;

  /** the model that is being modified */
  model: number[];


  /** callback when any change occurs */
  propagateChange = (_: any) => { };

  /** callback when a touch event occurs */
  propagateTouch = () => { };

  visibleEmployees: EmployeeSummaryDated[];

  private _employeesSource: EmployeeSummaryDated[];
  private _orgCode: string;
  /** a model saved in case employee source is temporarily empty */
  private savedModel: number[];

  constructor(private el: ElementRef) { }

  @Input()
  set employeesSource(value: EmployeeSummaryDated[]) {
    this.updateEmployeesSource(value);
  }


  /** the orgCode to limit the employees to if employeesSourceFilter is org */
  @Input()
  set orgCode(value: string) {
    if (this._orgCode !== value) {
      this._orgCode = value;
      this.updateVisibleEmployees();
    }
  }


  /** css class from host */
  get cssClass() {
    return this.el.nativeElement.className;
  }

  clearSelection() {
    this.onModelChange([]);
  }
  getEmployeeLabel(employee: EmployeeSummaryDated) {
    if (employee) {
      const showLocation = this.employeesSourceFilter === 'all';
      const locationPart = (showLocation) ? ' - ' + employee.orgCode : '';
      const partTimePart = (employee.isFullTime) ? '' : ' [PT]';
      return `${employee.name} (${employee.jobTitle}${locationPart})${partTimePart}`;
    }
  }

  getSelectedCountLabel() {
    if (!this.model || this.model.length === 0) {
      return 'No employees selected';
    }
    else if (this.model.length === 1) {
      return '1 employee selected';
    }
    else {
      return this.model.length + ' employees selected';
    }
  }

  setEmployeeSourceFilter(filter: 'org' | 'all') {
    this.employeesSourceFilter = filter;
    this.updateVisibleEmployees();
  }

  /**
   * callback when the model is changed on the html select ctrl
   */
  onModelChange(value: any) {
    if (value !== this.model) {
      this.model = value;
      this.propagateChange(value);
    }
  }

  /** ControlValueAccessor: Registers propagateChange callback function */
  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  /** ControlValueAccessor: Registers propagateTouch callback function */
  registerOnTouched(fn: any) {
    this.propagateTouch = fn;
  }

  /**
   * ControlValueAccessor: set disabled for controls
   *
   * @param isDisabled
   */
  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  /** Implementation of ControlValueAccessor.writeValue that sets the value of model */
  writeValue(obj: any) {
    let normalizedValues: unknown[];
    if (typeof (obj) === 'string') {
      try {
        const tempObj = JSON.parse(obj);
        if (Array.isArray(tempObj)) {
          normalizedValues = tempObj;
        }
      }
      catch {
        normalizedValues = [ obj ];
      }
    }
    else if (Array.isArray(obj)) {
      normalizedValues = obj;
    }
    else if (typeof obj === 'number') {
      normalizedValues = [ obj ];
    }

    if (Array.isArray(normalizedValues)) {
      this.model = normalizedValues.map(value => {
        switch (typeof value) {
          case 'number': return value;
          case 'string': return parseInt(value, 10);
          case 'object':
            const tempItem = value as Recipient & EmployeeSummaryDated; // the source obj could be a recipient or employee
            return tempItem.employeeId ?? tempItem.empId;
          default:
            return undefined;
        }
      }).filter((num): num is number => num != null && !isNaN(num));
    }
    else {
      this.model = [];
    }
  }

  private updateEmployeesSource(employees: EmployeeSummaryDated[]) {
    if (this._employeesSource && this._employeesSource.length && (!employees || !employees.length)) {
      // clear old model and save because the new employee source is empty
      this.savedModel = [...this.model];
      this.onModelChange([]);
    }
    else if (this._employeesSource !== employees && employees && employees.length) {
      // restore saved selections because the the employee source has changed.
      const prevSelections = (this.model && this.model.length ? this.model : this.savedModel) || [];
      this.savedModel = [];
      const savedSelections = employees.filter(x => prevSelections.some(y => y === x.employeeId));
      this.onModelChange(savedSelections);
    }

    this._employeesSource = employees;
    this.updateVisibleEmployees();
  }
  private updateVisibleEmployees() {
    const employees = this._employeesSource || [];
    this.visibleEmployees = this.employeesSourceFilter === 'all'
      ? employees
      : employees.filter(x => x.orgCode === this._orgCode);
  }
}
