import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuditFailuresComponent } from './audit-failures.component';



@NgModule({
  declarations: [
    AuditFailuresComponent
  ],
  exports: [
    AuditFailuresComponent
  ],
  imports: [
    CommonModule
  ]
})
export class AuditsModule { }
