import { BonusProcessAggregateState, BonusProcessState } from './bonus-process-state';
import { RewardType } from '../api-client';

export class ProcessSummary {

  failed = 0;
  hidden = 0;
  noAction = 0;
  queued = 0;
  processed = 0;
  toApprove = 0;
  toDeny = 0;

  /** all aggregate categories */
  readonly aggregates: BonusProcessAggregateState[] = [];

  constructor() {

  }


  /** calculates the total number of bonuses */
  get total() {
    return this.failed + this.hidden + this.noAction + this.processed + this.toApprove + this.toDeny;
  }

  addBonusAggregate(title: string) {
    const aggregate = {
      payouts: new Map<number, number>(),
      rewardTypes: new Map<RewardType, { total: number }>([
        [RewardType.Monetary, { total: 0 }],
        [RewardType.PTO, { total: 0 }],
        ]),
      title
    };
    this.aggregates.push(aggregate);
    return aggregate;
  }
}
