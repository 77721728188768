import { Directive, Input, forwardRef } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidatorFn } from '@angular/forms';

export function minArrayLengthValidator(minArrayLength: number): ValidatorFn {
  return (ctrl: AbstractControl) =>
    (minArrayLength && (!ctrl.value || ctrl.value.length < minArrayLength)) ? { minArrayLength: false } : null;
}

@Directive({
  selector: '[appMinArrayLength][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: forwardRef(() => MinArrayLengthValidator), multi: true }]
})
export class MinArrayLengthValidator implements Validator {

  private minArrayLength = 0;
  private onChange: () => void = () => undefined;

  /** input from appMinArrayLength="number" */
  @Input()
  set appMinArrayLength(value: number) {
    if (this.minArrayLength !== value) {
      this.minArrayLength = value;
      this.onChange();
    }
  }

  registerOnValidatorChange(fn: () => void) {
    this.onChange = fn;
  }

  validate(ctrl: AbstractControl) {
    return minArrayLengthValidator(this.minArrayLength)(ctrl);
  }

}
