import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { NotificationsService } from '@tcc/ui';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiException, BonusDetail } from '../api-client';
import { BonusesService } from './bonuses.service';

@Injectable()
export class BonusParamResolver implements Resolve<BonusDetail> {

  constructor(private bonusesSvc: BonusesService, private notificationSvc: NotificationsService, private router: Router) {

  }

  resolve(route: ActivatedRouteSnapshot): Observable<BonusDetail> {
    const value = route.params['bonusId'];
    if (value) {
      return this.bonusesSvc.getBonus(parseInt(value, 10)).pipe(
        catchError(err => {
          if (ApiException.isApiException(err)) {
            // eslint-disable-next-line eqeqeq
            if (err.status == 403) {
              this.notificationSvc.addError('Access is denied to bonus');
              this.router.navigateByUrl(this.router.url);
              return of(undefined);
            }
          }
          return throwError(err);
        })
      );
    }
    return of<BonusDetail>(undefined);
  }

}
