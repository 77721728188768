import { BonusStatus, Lease, OrgSummary, UserSummary, BaseMetaForm, RewardType, BonusAudit } from '../api-client';
import { MergedBonusSchedule } from '../bonus-config/bonus-config.service';
import { RequestPayoutTypeCommon, RequestPayoutTypeCreationInfo, RequestPayoutTypeVm } from './request-payout-type-vm';

export interface RequestModelCommon<TMeta extends BaseMetaForm> {
  appliesOn?: Date;
  approvalDecisionOn?: Date;
  approver?: UserSummary;
  bonusSchedule?: MergedBonusSchedule;
  bonusStatus?: BonusStatus;
  creator?: UserSummary;
  description?: string;
  lease?: Lease;
  meta?: TMeta;
  org?: OrgSummary;
  paidOn?: Date;
  payer?: UserSummary;
  payouts: RequestPayoutTypeCommon[];
  referenceBonusId?: number;
  responsibleOrg?: OrgSummary;
  totals: { rewardType: RewardType; total: number }[];
}
export interface RequestVm<TMeta extends BaseMetaForm> extends RequestModelCommon<TMeta> {
  payouts: RequestPayoutTypeVm[];
}
export interface RequestCreationInfo<TMeta extends BaseMetaForm> extends RequestModelCommon<TMeta> {
  payouts: RequestPayoutTypeCreationInfo[];
}

export function calculatRequestTotals(request: RequestModelCommon<BaseMetaForm>): void {
  request.totals = request.payouts.reduce((m, cur) => {
    const rewardTotal = m.find(x => x.rewardType === cur.rewardType);
    if (!rewardTotal) {
      m.push({ rewardType: cur.rewardType, total: cur.amount });
    }
    else {
      rewardTotal.total += cur.amount;
    }
    return m;
  }, [] as { rewardType: RewardType; total: number }[]);
}
