<tcc-simplePanel size="small" [options]="{ isFloating: true }" buttons="close" (buttonClick)="emitResult('cancel')">
  <div panel-header>
    {{ title }}
  </div>
  <div panel-body>
    <ng-content></ng-content>
    <div class="pt-2">
      <button type="button" class="btn btn-secondary me-2" (click)="emitResult('cancel')">Cancel</button>
      <button type="button" class="btn btn-primary" (click)="emitResult('confirm')">Ok</button>
    </div>
  </div>
</tcc-simplePanel>
