import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BonusDeleteModalComponent } from './bonus-delete-modal.component';
import { BonusesListComponent } from './bonuses-list.component';
import { BonusSummaryComponent } from './bonus-summary.component';
import { BonusSummaryGroupComponent } from './bonus-summary-group.component';
import { BonusesService } from './bonuses.service';
import { BonusParamResolver } from './bonus-param.resolver';
import { BonusesComponent } from './bonuses.component';
import { SharedModule } from '../shared/shared.module';
import { OrgsModule } from '../orgs/orgs.module';
import { FormsModule } from '@angular/forms';
import { TccUiModule } from '@tcc/ui';
import { RouterModule } from '@angular/router';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { BonusConfigModule } from '../bonus-config/bonus-config.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NgbPaginationModule,
    TccUiModule,
    SharedModule,
    BonusConfigModule,
    OrgsModule
  ],
  declarations: [
    BonusDeleteModalComponent,
    BonusesComponent,
    BonusesListComponent,
    BonusSummaryComponent,
    BonusSummaryGroupComponent
  ],
  exports: [
    BonusDeleteModalComponent,
    BonusesComponent,
    BonusSummaryComponent,
  ],
  providers: [
    BonusesService,
    BonusParamResolver
  ]
})
export class BonusesModule { }
