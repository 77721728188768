import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { BonusConfigService } from './bonus-config.service';
import { BonusType } from '../api-client';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

@Injectable()
export class BonusTypesParamResolver implements Resolve<BonusType[]>  {

  constructor(private bonusConfigSvc: BonusConfigService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<BonusType[]> {
    const value = route.params['bonusTypeIds'];

    if (value) {
      const bonusTypeIds = this.paramToIntArray(value);
      return this.bonusConfigSvc.userBonusTypeMap$.pipe(
        take(1),
        map(btMap => bonusTypeIds
          .map(x => btMap.get(x))
          .filter((x): x is BonusType => !!x))
      );
    }

    return of([]);
  }

  private paramToIntArray(value: unknown): number[] {
    if (typeof value === 'string') {
      if (value.trim().startsWith('[')) {
        return JSON.parse(value) as number[];
      }
      if (value.includes(',')) {
        return value.split(',').map(x => parseInt(x, 10));
      }
      return [parseInt(value, 10)];
    }
    if (typeof value === 'number') {
      return [value];
    }
    if (Array.isArray(value)) {
      return value.map(x => typeof x === 'number' ? x : parseInt(x, 10));
    }
    return [];
  }
}
