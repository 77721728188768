import { AccessType, Rule, UserDetail } from '../api-client';


export class UserAccess {
  static readonly categoryWildCard = -1;
  static readonly orgCodeWildCard = '*';

  /** map of all access items associated to AccessTypes */
  readonly accessTypeIndex: Map<AccessType, Rule[]>;
  /** map of all access items associated to bonusTypeCategoryIds */
  readonly categoryIndex: Map<number, Rule[]>;
  /** true if there is at least one item associated to admin access */
  readonly isAdmin: boolean;
  /** true if there is at least one item associated to approver access */
  readonly isApprover: boolean;
  /** true if there is at least one item associated to creator access */
  readonly isCreator: boolean;
  /** true if there is at least one item associated to payer access */
  readonly isPayer: boolean;
  /** map of all access items associated to orgCodes */
  readonly orgCodeIndex: Map<string, Rule[]>;

  constructor(source: UserDetail) {
    this.accessTypeIndex = new Map<AccessType, Rule[]>();
    this.categoryIndex = new Map<number, Rule[]>();
    this.orgCodeIndex = new Map<string, Rule[]>();

    for (const item of source.access.ruleSource) {
      setIndexItem(this.accessTypeIndex, item.accessType, item);
      setIndexItem(this.categoryIndex, item.bonusTypeCategoryId, item);
      setIndexItem(this.orgCodeIndex, item.orgCode, item);
    }

    this.isAdmin = this.accessTypeIndex.has(AccessType.Administrator);
    this.isApprover = this.accessTypeIndex.has(AccessType.Approver);
    this.isCreator = this.accessTypeIndex.has(AccessType.Creator);
    this.isPayer = this.accessTypeIndex.has(AccessType.Payer);

    function setIndexItem<TKey>(index: Map<TKey, Rule[]>, key: TKey, item: Rule) {
      (index.get(key) ?? index.set(key, []).get(key)).push(item);
    }
  }

  filterBonusTypeCategories(items: { bonusTypeCategoryId: number }[]) {
    const categoryIds = Array.from(this.categoryIndex.keys());
    return items.filter(x => categoryIds.indexOf(x.bonusTypeCategoryId) !== -1);
  }

  hasAccessType(accessType: AccessType) {
    return this.accessTypeIndex.has(accessType);
  }

  hasCategory(bonusTypeCategoryId: number) {
    return this.categoryIndex.has(UserAccess.categoryWildCard) || this.categoryIndex.has(bonusTypeCategoryId);
  }

  hasOrg(orgCode: string) {
    return this.orgCodeIndex.has(UserAccess.orgCodeWildCard) || this.orgCodeIndex.has(orgCode);
  }

  /**
   * Returns the intersection of the different dimensions, pass undefined to not filter by dimenesion
   */
  query(accessType?: AccessType, bonusTypeCategoryId?: number, orgCode?: string) {
    const listOfIndexes: Rule[][] = [];

    if (accessType !== undefined) {
      listOfIndexes.push(this.accessTypeIndex.get(accessType) || []);
    }
    if (bonusTypeCategoryId !== undefined) {
      listOfIndexes.push(
        (this.categoryIndex.get(UserAccess.categoryWildCard) || []).concat(this.categoryIndex.get(bonusTypeCategoryId) || []));
    }
    if (orgCode !== undefined) {
      listOfIndexes.push((this.orgCodeIndex.get(UserAccess.orgCodeWildCard) || []).concat(this.orgCodeIndex.get(orgCode) || []));
    }

    return listOfIndexes.reduce((listA, listB) => listA.filter(a => listB.indexOf(a) !== -1));
  }

}
