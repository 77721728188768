import { BatchPayout } from './../api-client';
import { formatDate } from '@angular/common';
import { RewardType } from '../api-client';

export interface ExportInfo {
  exporter: (created: Date, payouts: BatchPayout[], LOCALE_ID: string) => any[];
  fileNameFormat?: (name: string, rewardType: RewardType) => string;
};

export class BatchesConfig {

  static readonly exportMap: Map<RewardType, ExportInfo> = new Map([
    [RewardType.Monetary, { exporter: (c, p, l) => BatchesConfig.exportMonetary(c, p, l), fileNameFormat: BatchesConfig.createFileName }],
    [RewardType.PTO, { exporter: (_, p, __) => BatchesConfig.exportPto(p), fileNameFormat: BatchesConfig.createFileName }]
  ]);

  private static createFileName(name: string, rewardType: RewardType) {
    return `bonuses batch ${name} ${rewardType}.csv`;
  }

  private static exportMonetary(createdOn: Date, payouts: BatchPayout[], locale: string) {

    type ConsolidatedPayout = [string, string, string, null, null, string, null, null, null, null, number];
    const data: ConsolidatedPayout[] = [];
    const consolidatedPayouts: { [key: string]: { dept: string; empId: string; earningsCode: string; amount: number } } = {};
    const batchDateText = formatDate(createdOn, 'M/dd/yyyy', locale);

    for (const payout of payouts) {
      const key = `${payout.fileNumber}:${payout.department}:${payout.earningsCode}`;
      if (consolidatedPayouts[key]) {
        consolidatedPayouts[key].amount += payout.amount;
      }
      else {
        consolidatedPayouts[key] = {
          empId: payout.fileNumber,
          earningsCode: payout.earningsCode,
          amount: payout.amount,
          dept: payout.department
        };
      }
    }
    for (const key of Object.keys(consolidatedPayouts)) {
      const payout = consolidatedPayouts[key];
      data.push([payout.empId, payout.dept, batchDateText, null, null, payout.earningsCode, null, null, null, null, payout.amount]);
    }
    return data;
  }

  /** this used to be a true export, now it just a report. */
  private static exportPto(payouts: BatchPayout[]) {
    const data: string[][] = [];
    const sortedPayouts = payouts.sort((a, b) =>
      a.orgCode < b.orgCode ? -1
        : a.orgCode > b.orgCode ? 1
          : a.fileNumber < b.fileNumber ? -1
            : a.fileNumber > b.fileNumber ? 1
              : (a.bonusId || 0 - b.bonusId || 0)
    );
    // add a header
    data.push(['OrgCode', 'Employee ID', 'Bonus ID', 'Recipient Name', 'PTO Amt', 'Created On']);
    for (const payout of sortedPayouts) {

      data.push([payout.orgCode, payout.fileNumber, payout.bonusId?.toString(10), payout.empName, payout.amount.toString(10)
        , formatDate(payout.createdOn, 'MM/dd/yyyy', 'en-US')]);
    }
    return data;
  }
}
