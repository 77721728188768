import { Component, OnInit, OnDestroy } from '@angular/core';
import { Lease } from '../api-client';
import { LeasesService } from './leases.service';
import { NotificationsService, SubsManager } from '@tcc/ui';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalComponentResultType } from '../shared/modal.component';
import { map, finalize } from 'rxjs/operators';
import { catchReportResume } from '../shared/catch-report-resume-operator';

@Component({
  selector: 'app-lease-hide-modal',
  template: `
<app-modal *ngIf="lease" [title]="'Hide Lease'" (modalResult)="onHideModalResult($event)">
  Are you sure you want to hide the lease from {{lease.leaseFrom | date : 'M/d/yyyy'}} to {{lease.leaseTo | date : 'M/d/yyyy'}}
  for customer <app-customer [customerCode]="lease.customerCode" [customerName]="lease.customerName"></app-customer>?
</app-modal>
`
})
export class LeaseHideModalComponent implements OnDestroy, OnInit {
  lease: Lease;
  state: 'loading' | 'ready';

  private readonly subsMgr = new SubsManager();
  constructor(private leasesSvc: LeasesService, private notificationsSvc: NotificationsService,
    private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.state = 'loading';
    this.subsMgr.subs[0] = this.route.data.subscribe((x: { lease: Lease }) => {
      this.lease = x.lease;
      if (this.lease === null) {
        this.notificationsSvc.addWarning('Unable to load lease, possibly because it has already been hidden.');
        this.close(true);
      }
      this.state = (this.lease) ? 'loading' : 'ready';
    });
  }

  ngOnDestroy() {
    this.subsMgr.onDestroy();
  }

  onHideModalResult(resultType: ModalComponentResultType) {
    switch (resultType) {
      case 'cancel':
        this.close(false);
        break;
      case 'confirm':
        this.state = 'loading';
        this.leasesSvc.updateLeaseBonusInfo(this.lease.leasingActivityId, true).pipe(
          map(() => this.notificationsSvc.addSuccess(`Lease for ${this.lease.customerName} successfully deleted.`)),
          catchReportResume(() => this.notificationsSvc.addError(`Failed deleting lease for ${this.lease.customerName}.`)),
          finalize(() => {
            this.state = 'ready';
            this.close(true);
          })
        ).subscribe();

        break;
    }
  }

  private close(reload: boolean): void {
    const queryParams = reload ? { reload: Date.now() } : undefined;
    this.router.navigate([{ outlets: { popups: null } }], { queryParamsHandling: 'merge', queryParams });
  }
}
