import { RewardType } from '../api-client';

export class PayoutTypeUi {
  /** information about how the enum should appear in the ui. */
  static readonly payoutTypeUiInfo = [
    { payoutTypeId: 1, title: 'Individual Amount', shortName: 'Idv', rewardType: RewardType.Monetary, suffix: '' },
    { payoutTypeId: 2, title: 'Office Amount', shortName: 'Ofc', rewardType: RewardType.Monetary, suffix: '' },
    { payoutTypeId: 3, title: 'Service Amount', shortName: 'Svc', rewardType: RewardType.Monetary, suffix: '' },
    { payoutTypeId: 4, title: 'PTO', shortName: 'Pto', rewardType: RewardType.PTO, suffix: ' Hours' },
    { payoutTypeId: 5, title: 'Service Manager', shortName: 'SvcMan', rewardType: RewardType.Monetary, suffix: '' },
    { payoutTypeId: 6, title: 'Service Tech', shortName: 'SvcTek', rewardType: RewardType.Monetary, suffix: '' },
  ];

  /** payoutTypeUI keyed by enum */
  static readonly payoutTypeUiMap = new Map(PayoutTypeUi.payoutTypeUiInfo.map(x => [x.payoutTypeId, x]));

  static readonly rewardTypeUiMap = new Map(PayoutTypeUi.payoutTypeUiInfo.map(x => [x.rewardType, x]));
}
