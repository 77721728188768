import { InjectionToken, NgModule } from '@angular/core';
import { Routes, RouterModule, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute, UrlSegment } from '@angular/router';
import { LeaseBonusStatusFilter, BonusStatus, LeaseType, BonusType, AccessType } from './api-client';
import { Observable } from 'rxjs';

import { BonusTypesParamResolver } from './bonus-config/bonus-types-param.resolver';
import { OrgParamResolver } from './orgs/org-param.resolver';
import { OverviewComponent } from './orgs/overview.component';
import { ProcessingComponent } from './processing/processing.component';
import { BatchesComponent } from './processing/batches.component';
import { ProcessType } from './processing/process-type';
import { RequestViewComponent } from './requests/request-view.component';
import { RequestEditorComponent } from './requests/request-editor.component';
import { LeasesComponent } from './leases/leases.component';
import { LeaseHideModalComponent } from './leases/lease-hide-modal.component';
import { LeaseParamResolver } from './leases/lease-param.resolver';
import { BonusSummaryComponent } from './bonuses/bonus-summary.component';
import { BonusesComponent } from './bonuses/bonuses.component';
import { BonusParamResolver } from './bonuses/bonus-param.resolver';
import { BonusDeleteModalComponent } from './bonuses/bonus-delete-modal.component';
import { UserAccessGuard, UserAccessGuardConfig } from './users/user-access.guard';
import { RequestEditorParamsResolver } from './requests/request-editor-params.resolver';
import { RecipientParamResolver } from './employees/recipient-param.resolver';
import { MsalGuard } from '@azure/msal-angular';

// even though injection tokens are used only in app-routing, AOT couldn't handle them unless they were exported.
export const BONUS_STATUS_RESOLVER = new InjectionToken('bonusStatusResolver');
export const BONUS_TYPE_IDS_RESOLVER = new InjectionToken('bonusTypeIdsResolver');
export const CUSTOMER_NAME_FILTER_RESOLVER = new InjectionToken('customerNameFilterResolver');
export const LEASE_BONUS_STATUS_RESOLVER = new InjectionToken('leaseBonusStatusResolver');
export const LEASE_TYPE_RESOLVER = new InjectionToken('leaseTypeResolver');
export const PROCESS_TYPE_APPROVALS_RESOLVER = new InjectionToken('processTypeApprovalsResolver');
export const PROCESS_TYPE_PAYOUTS_RESOLVER = new InjectionToken('processTypePayoutsResolver');
export const RECIPIENT_NAME_FILTER_RESOLVER = new InjectionToken('recipientNameFilterResolver');
export const RELOAD_RESOLVER = new InjectionToken('reloadResolver');

const bonusStatusResolver = {
  provide: BONUS_STATUS_RESOLVER,
  useValue: (route: ActivatedRouteSnapshot) => route.params['bonusStatus'] as BonusStatus
};
const customerNameFilterResolver = {
  provide: CUSTOMER_NAME_FILTER_RESOLVER,
  useValue: (route: ActivatedRouteSnapshot) => route.params['customerNameFilter']
};
const leaseBonusStatusResolver = {
  provide: LEASE_BONUS_STATUS_RESOLVER,
  useValue: (route: ActivatedRouteSnapshot) => route.params['leaseBonusStatus'] as LeaseBonusStatusFilter
};
const leaseTypeResolver = {
  provide: LEASE_TYPE_RESOLVER,
  useValue: (route: ActivatedRouteSnapshot) => route.params['leaseType'] as LeaseType
};
const processTypeApprovalsResolver = {
  provide: PROCESS_TYPE_APPROVALS_RESOLVER,
  useValue: (_: ActivatedRouteSnapshot) => ProcessType.Approvals
};
const processTypePayoutsResolver = {
  provide: PROCESS_TYPE_PAYOUTS_RESOLVER,
  useValue: (_: ActivatedRouteSnapshot) => ProcessType.Payouts
};
const recipientNameFilterResolver = {
  provide: RECIPIENT_NAME_FILTER_RESOLVER,
  useValue: (route: ActivatedRouteSnapshot) => route.params['recipientNameFilter']
};
/** Get a reload value from queryString.  Because this uses query params runGuardsAndResolvers should equal 'paramsOrQueryParamsChange'. */
const reloadResolver = {
  provide: RELOAD_RESOLVER,
  useValue: (route: ActivatedRouteSnapshot) => route.queryParams['reload'] ? parseInt(route.queryParams['reload'], 10) : undefined
};

const childRequestsRoutes: Routes = [
  {
    path: 'requests-view',
    component: RequestViewComponent,
    resolve: {
      bonus: BonusParamResolver
    },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  }
];
const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/home'
  },
  {
    path: 'approvals',
    component: ProcessingComponent,
    canActivate: [MsalGuard, UserAccessGuard],
    data: { 'user-access': { accessType: AccessType.Approver } as UserAccessGuardConfig },
    resolve: {
      bonusTypes: BonusTypesParamResolver,
      org: OrgParamResolver,
      recipient: RecipientParamResolver,
      reload: RELOAD_RESOLVER,
      processType: PROCESS_TYPE_APPROVALS_RESOLVER
    },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    children: childRequestsRoutes
  },
  {
    path: 'batches',
    component: BatchesComponent,
    canActivate: [MsalGuard, UserAccessGuard],
    data: { 'user-access': { accessType: AccessType.Payer } as UserAccessGuardConfig },
  },
  {
    path: 'bonus-delete',
    component: BonusDeleteModalComponent,
    canActivate: [MsalGuard, UserAccessGuard],
    data: { 'user-access': { accessType: AccessType.Creator } as UserAccessGuardConfig },
    resolve: {
      bonus: BonusParamResolver
    },
    outlet: 'popups'
  },
  {
    path: 'bonuses',
    component: BonusesComponent,
    canActivate: [MsalGuard],
    resolve: {
      bonusStatus: BONUS_STATUS_RESOLVER,
      bonusTypes: BonusTypesParamResolver,
      leaseType: LEASE_TYPE_RESOLVER,
      org: OrgParamResolver,
      recipientNameFilter: RECIPIENT_NAME_FILTER_RESOLVER,
      reload: RELOAD_RESOLVER
    },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    children: childRequestsRoutes
  },
  {
    path: 'bonus-summary-report',
    component: BonusSummaryComponent,
    canActivate: [MsalGuard],
    resolve: {
      org: OrgParamResolver
    },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    children: childRequestsRoutes
  },
  {
    path: 'home',
    canActivate: [MsalGuard],
    component: OverviewComponent
  },
  {
    path: 'leases',
    component: LeasesComponent,
    canActivate: [MsalGuard],
    resolve: {
      customerNameFilter: CUSTOMER_NAME_FILTER_RESOLVER,
      leaseBonusStatus: LEASE_BONUS_STATUS_RESOLVER,
      org: OrgParamResolver,
      reload: RELOAD_RESOLVER,
    },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    children: childRequestsRoutes
  },
  {
    path: 'lease-hide',
    canActivate: [MsalGuard, UserAccessGuard],
    data: { 'user-access': { accessType: AccessType.Creator } as UserAccessGuardConfig },
    component: LeaseHideModalComponent,
    resolve: {
      lease: LeaseParamResolver
    },
    outlet: 'popups'
  },
  {
    path: 'payouts',
    component: ProcessingComponent,
    canActivate: [MsalGuard, UserAccessGuard],
    data: { 'user-access': { accessType: AccessType.Payer } as UserAccessGuardConfig},
    resolve: {
      bonusTypes: BonusTypesParamResolver,
      org: OrgParamResolver,
      recipient: RecipientParamResolver,
      reload: RELOAD_RESOLVER,
      processType: PROCESS_TYPE_PAYOUTS_RESOLVER
    },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    children: childRequestsRoutes
  },
  {
    path: 'requests-edit',
    component: RequestEditorComponent,
    canActivate: [MsalGuard, UserAccessGuard],
    data: { 'user-access': { accessType: AccessType.Creator } as UserAccessGuardConfig },
    resolve: {
      config: RequestEditorParamsResolver
    },
    outlet: 'popups'
  },
  { path: '**', redirectTo: 'home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false, paramsInheritanceStrategy: 'always', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: [
    bonusStatusResolver,
    customerNameFilterResolver,
    leaseBonusStatusResolver,
    leaseTypeResolver,
    recipientNameFilterResolver,
    reloadResolver,
    processTypeApprovalsResolver,
    processTypePayoutsResolver
  ]
})
export class AppRoutingModule { }
