import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NotificationsService, SubsManager, TableColumn } from '@tcc/ui';
import { forkJoin, Observable } from 'rxjs';
import { finalize, map, shareReplay, tap } from 'rxjs/operators';
import { BonusStatus, BonusType, Employee, EventType, OrgSummary, PayoutType, ProcessResultType, RewardType } from '../api-client';
import { BonusConfigService } from '../bonus-config/bonus-config.service';
import { BonusesService } from '../bonuses/bonuses.service';
import { catchReportResume } from '../shared/catch-report-resume-operator';
import { PayoutTypeUi } from '../shared/payout-type-ui';
import { RouteUtilService } from '../shared/route-util.service';
import { SortUtil } from '../shared/sort-util';
import { UsersService } from '../users/users.service';
import { BonusProcessState } from './bonus-process-state';
import { ProcessSummary } from './process-summary';
import { ProcessType } from './process-type';
import { ProcessingService } from './processing.service';


interface ApprovalsComponentRouteData {
  bonusTypes?: BonusType[];
  org?: OrgSummary;
  recipient?: Employee;
  reload?: number;
  processType: ProcessType;
}
@Component({
  selector: 'app-processing',
  templateUrl: './processing.component.html'
})
export class ProcessingComponent implements OnInit, OnDestroy {
  /** marker for columns that can be hidden based on size of screen or panel */
  private readonly hideColClass = 'col-hideable';
  readonly payoutTypeUiMap = PayoutTypeUi.payoutTypeUiMap;
  bonusTypes: BonusType[] = [];
  bonusTypeMap: Map<number, BonusType>;
  payoutTypes: PayoutType[] = [];
  payoutTypeMap: Map<number, PayoutType>;

  columnDefs: TableColumn<BonusProcessState>[] = [
    {
      name: 'bonusId', title: 'Id', cellTemplateName: 'bonusId', sort: true,
      sorter: SortUtil.numericSortFactory<BonusProcessState>(x => x.bonusId)
    },
    {
      name: 'title', title: 'Description', colClass: this.hideColClass, sort: true,
      sorter: SortUtil.caseInsensitiveStringSortFactory<BonusProcessState>(x => x.title)
    },
    {
      name: 'createdOn', title: 'Created On', cellTemplateName: 'createdOn', colClass: this.hideColClass,
      sort: true, sorter: SortUtil.valueSortFactory<BonusProcessState>(x => x.createdOn)
    },
    {
      name: 'bonusTypeId', title: 'Type', cellTemplateName: 'bonusTypeId', colClass: this.hideColClass,
      sort: true, sorter: SortUtil.valueSortFactory<BonusProcessState>(x => this.bonusTypeMap.get(x.bonusTypeId).name)
    },
    { name: 'orgCode', title: 'Community', sort: true, sorter: SortUtil.valueSortFactory<BonusProcessState>(x => x.orgCode) },
    { name: 'creator', title: 'Creator', cellTemplateName: 'creator', colClass: this.hideColClass },
    { name: 'approve', title: 'Approve', cellTemplateName: 'approve', colClass: 'text-center' },
    { name: 'deny', title: 'Deny', cellTemplateName: 'deny', colClass: 'text-center', }
  ];

  processActionApproved: EventType;
  /** aot farted when trying to use a string value to assign the EventType. */
  readonly processActionDenied = EventType.Denied;
  filteredBonuses: BonusProcessState[];
  minTotal: number;
  maxTotal: number;
  org: OrgSummary;
  reload: number;
  panelSize: 'large' | 'full' = 'full';
  processSummary = new ProcessSummary();
  processType: ProcessType;
  recipient: Employee;

  state: 'loading' | 'processing' | 'ready' = 'loading';

  private allBonuses: BonusProcessState[];
  private bonuses$: Observable<BonusProcessState[]>;
  private subsMgr = new SubsManager();

  constructor(
    private bonusesSvc: BonusesService, private bonusConfigSvc: BonusConfigService, private notificationsSvc: NotificationsService,
    private processingSvc: ProcessingService, private route: ActivatedRoute, private router: Router, private routeUtil: RouteUtilService,
    private usersSvc: UsersService) { }


  ngOnInit() {
    this.updateUiFromRouteChange();
    this.updateProcessSummary();


    this.state = 'loading';
    this.subsMgr.addSub = forkJoin(
      this.bonusConfigSvc.bonusTypeMap$.pipe(tap(x => this.bonusTypeMap = x)),
      this.bonusConfigSvc.payoutType$.pipe(tap(x => this.payoutTypes = x)),
      this.bonusConfigSvc.payoutTypeMap$.pipe(tap(x => this.payoutTypeMap = x)),
    ).pipe(
      finalize(() => {
        this.state = 'ready';
        this.addPayoutTypeColumnDefs();
        this.subsMgr.addSub = this.router.events.subscribe(x => { if (x instanceof NavigationEnd) { this.updateUiFromRouteChange(); } });
        this.subsMgr.addSub = this.route.data.subscribe((params: ApprovalsComponentRouteData) => this.onRouteDataChange(params));
      })
    ).subscribe();

  }

  ngOnDestroy() {
    this.subsMgr.onDestroy();
  }

  /**
   * takes in the payoutType string and parses it back into an int
   */
  convertStrToInt(payoutStr: string) {
    return parseInt(payoutStr, 10);
  }

  getBonusTypeName(bonusTypeId: number) {
    return this.bonusConfigSvc.bonusTypeMap$.pipe(map(x => (x.get(bonusTypeId) || {}).name));
  }

  getBonusDetailVisible(bonusId: number) {
    const bonusIdText = bonusId.toString();
    return this.route.children.some(
      x => x.snapshot.url.some(y => y.path.indexOf('requests-view') !== -1 && y.parameters['bonusId'] === bonusIdText));
  }

  getPayoutAmount(payoutTypeIdTextOrNbr: string | number, bonus: BonusProcessState) {
    const payoutTypeId = (typeof payoutTypeIdTextOrNbr === 'string') ? parseInt(payoutTypeIdTextOrNbr, 10) : payoutTypeIdTextOrNbr;
    return bonus.payouts.get(payoutTypeId) || 0;
  }

  getUserName(userId: number) {
    return this.usersSvc.userMap$.pipe(map(x => (x.get(userId) || {}).name));
  }

  /**
   * filters allBonuses from filter values
   */
  processFilters() {
    this.subsMgr.cancel('processFilters');
    this.state = 'loading';
    this.subsMgr.subs.processFilters = this.bonuses$.pipe(
      map(allBonuses => {
        this.allBonuses = allBonuses;
        const filters: ((BonusVm) => boolean)[] = [];
        if (this.org) {
          filters.push((b: BonusProcessState) => (b.orgCode === this.org.orgCode));
        }
        if (this.bonusTypes && this.bonusTypes.length !== 0) {
          const bonusTypeIds = this.bonusTypes.map(x => x.bonusTypeId);
          filters.push((b: BonusProcessState) => bonusTypeIds.indexOf(b.bonusTypeId) !== -1);
        }
        if (!isNaN(this.minTotal) && this.minTotal != null) {
          filters.push((b: BonusProcessState) => b.total >= this.minTotal);
        }
        if (!isNaN(this.maxTotal) && this.minTotal != null) {
          filters.push((b: BonusProcessState) => b.total <= this.maxTotal);
        }
        this.filteredBonuses = allBonuses.filter(b => !filters.some(f => !f(b)));
        this.updateProcessSummary();
      }),
      catchReportResume(() => this.notificationsSvc.addError('Failed loading bonuses.  Please reload the page and try again.')),
      finalize(() => this.state = 'ready')
    ).subscribe();
  }

  runProcess() {
    this.state = 'processing';
    this.updateProcessSummary();
    const bonusesToProcess =
      this.filteredBonuses.filter(x => x.processAction && (!x.processResultType || x.processResultType === ProcessResultType.NotRun));

    const bonusMap = new Map(bonusesToProcess.map<[number, BonusProcessState]>(x => [x.bonusId, x]));
    this.processingSvc.processBonuses(bonusesToProcess).pipe(
      map(batchResult => {
        for (const bonusResult of batchResult.batchResults) {
          const bonus = bonusMap.get(bonusResult.bonusId);
          bonus.processResultMessage = bonusResult.message;
          bonus.processResultType = bonusResult.processResultType;
        }
        this.updateProcessSummary();
        if (batchResult.batchCount === batchResult.processedBatchCount) {
          if (batchResult.failures) {
            this.notificationsSvc.addWarning('Batch processing complete with failures.');
          }
          else {
            this.notificationsSvc.addSuccess('Batch processing complete.');
          }
        }
      }),
      finalize(() => this.state = 'ready')
    ).subscribe();
  }

  /**
   * Sets the process action on all bonuses and updates processSummary.
   */
  setAllProcessAction(processAction?: EventType) {
    for (const bonus of this.filteredBonuses) {
      bonus.processAction = processAction;
    }
    this.updateProcessSummary();
  }
  /**
   * Sets the process action on a bonus if it is different and updates processSummary.
   */
  setBonusProcessAction(bonus: BonusProcessState, processAction?: EventType) {
    if (processAction !== bonus.processAction) {
      bonus.processAction = processAction;
      this.updateProcessSummary();
    }
  }

  /**
   * sets the bonus types by updating the route
   */
  setBonusTypes(bonusTypes: BonusType[]) {
    const bonusTypeIds = (bonusTypes || []).map(x => x.bonusTypeId);
    this.routeUtil.updateRouteParams(this.route, { bonusTypeIds }, true);
  }

  /** updates the route with the given org.  If no org is set, then unsets the current org in the route. */
  setOrg(org: OrgSummary | null | undefined) {
    this.router.navigate([], { relativeTo: this.route, queryParams: { orgCode: org?.orgCode ?? '' }, queryParamsHandling: 'merge' });
  }

  setRecipient(recipient: Employee) {
    this.routeUtil.updateRouteParams(this.route, { recipientId: recipient ? recipient.employeeId : undefined }, true);
  }

  private addPayoutTypeColumnDefs() {
    const startIndex = this.columnDefs.findIndex(x => x.name === 'creator') + 1;
    const payoutTypeCols = this.payoutTypes.map(pt => {
      const cellTemplateName = (pt.rewardType === 'PTO') ? 'nonMonetaryAmt' : 'monetaryAmt';
      return {
        name: `payoutType_${pt.name}`, externalKey: pt.payoutTypeId.toString(), title: pt.name, cellTemplateName, colClass: 'text-end',
        sort: true, sorter: SortUtil.numericSortFactory<BonusProcessState>(x => this.getPayoutAmount(pt.payoutTypeId, x))
      } as TableColumn<any>;
    });

    this.columnDefs = [...this.columnDefs.slice(0, startIndex), ...payoutTypeCols, ...this.columnDefs.slice(startIndex)];
  }


  /**
   * sets variables from route params and reloads bonuses if necessary
   */
  private onRouteDataChange(params: ApprovalsComponentRouteData) {
    const loadBonuses = !this.bonuses$
      || (params.processType !== this.processType)
      || (params.reload && params.reload !== this.reload)
      || (params.recipient && !this.recipient)
      || (this.recipient && !params.recipient)
      || (params.recipient && this.recipient && params.recipient.employeeId !== this.recipient.employeeId);
    this.processType = params.processType;
    this.processActionApproved = (this.processType === ProcessType.Payouts) ? EventType.Paid : EventType.Approved;
    this.bonusTypes = params.bonusTypes || [];
    this.org = params.org;
    this.recipient = params.recipient;
    this.reload = params.reload;

    if (loadBonuses) {
      const bonusStatus = (this.processType === ProcessType.Payouts) ? BonusStatus.Approved : BonusStatus.Unapproved;
      const bonusParams = {
        bonusStatus,
        recipientIds: this.recipient ? [this.recipient.employeeId] : undefined
      };
      this.bonuses$ = this.bonusesSvc.getBonuses(bonusParams, 0, 10000).pipe(
        map(res => res.data.map(
          (bonus) => {
            const rewardTypes = new Map((bonus.payouts || []).map(x => [this.payoutTypeMap.get(x.payoutTypeId).rewardType, { total: 0 }]));
            const payouts = new Map((bonus.payouts || []).map(x => [x.payoutTypeId, x.amount]));
            const bonusProcessState: BonusProcessState = { ...bonus, payouts, rewardTypes, total: 0 };
            bonusProcessState.total += (bonus.payouts || []).reduce((total, p) => {
              const payoutType = this.payoutTypeMap.get(p.payoutTypeId);
              return total + ((payoutType.rewardType === RewardType.Monetary) ? p.amount : 0);
            }, 0);
            return bonusProcessState;
          }
        )),
        shareReplay(1)
      );
    }
    this.processFilters();
  }



  /**
   * updates processSummary from the state of the component
   */
  private updateProcessSummary() {
    this.processSummary = this.processingSvc.calculateProcessSummary(
      this.filteredBonuses, (this.allBonuses || []).length, this.state === 'processing', this.payoutTypes);
  }

  /**
   * detects child routes and updates the ui appropriately.
   */
  private updateUiFromRouteChange() {
    // hideColClass is a trigger we use to detect a column that is hideable when panel is small.
    // We don't want to lose marker so all cell classes begin with it even though it does nothing.
    let colClass = this.hideColClass;
    if (this.route.children.length === 0) {
      this.panelSize = 'full';
      colClass += ' d-sm-none d-md-table-cell';
    }
    else {
      this.panelSize = 'large';
      colClass += ' d-none';
    }
    const hideableCols = this.columnDefs.filter(x => typeof x.colClass === 'string' && x.colClass.startsWith(this.hideColClass));
    for (const colDef of hideableCols) {
      colDef.colClass = colClass;
    }
  }
}

