import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { NotificationsService } from '@tcc/ui';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { UsersService } from '../users/users.service';
import { ProcessType } from './process-type';

@Injectable()
export class ProcessTypeGuard implements CanActivate {

  constructor(private notificationsSvc: NotificationsService, private usersSvc: UsersService) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const value = route['processType'];
    const processType = value ? value as ProcessType : undefined;

    const checker$ = (!processType)
      ? of(false)
      : this.usersSvc.myAccess$.pipe(
        take(1),
        map(x =>
          (processType === ProcessType.Approvals && x.isApprover) || (processType === ProcessType.Payouts && x.isPayer)
        )
      );

    return checker$.pipe(
      map(result => {
        if (!result) {
          this.notificationsSvc.addError('You do have insufficient rights to access this tab.');
        }
        return result;
      })
    );
  }

}
