import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SimplePanelComponent, SubsManager } from '@tcc/ui';
import { forkJoin, of, Subject } from 'rxjs';
import { filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { BonusDetail, BonusType, RewardType } from '../api-client';
import { BonusConfigService } from '../bonus-config/bonus-config.service';
import { calculatRequestTotals, RequestVm } from './request-vm';
import { RequestsService } from './requests.service';
import { PayoutTypeUi } from '../shared/payout-type-ui';

@Component({
  selector: 'app-request-view',
  templateUrl: './request-view.component.html'
})
export class RequestViewComponent implements OnInit, AfterViewInit, OnDestroy {

  bonus: BonusDetail;
  request: RequestVm<any>;
  @ViewChild(SimplePanelComponent) simplePanel: SimplePanelComponent;
  state: 'ready' | 'loading';

  private subsMgr = new SubsManager();
  private bonusTypeMap: Map<number, BonusType>;
  private bonusLoadedSubject = new Subject<boolean>();

  readonly bonusLoadedObservable$ = this.bonusLoadedSubject.pipe(shareReplay(1));
  readonly payoutTypeUiInfo = PayoutTypeUi.payoutTypeUiInfo;
  readonly payoutTypeUiMap = PayoutTypeUi.payoutTypeUiMap;

  constructor(
    private requestsSvc: RequestsService, private route: ActivatedRoute,
    private router: Router, private bonusConfigSvc: BonusConfigService
  ) { }

  ngOnInit() {
    this.state = 'loading';
    this.subsMgr.addSub = this.route.data.pipe(
      switchMap(x => {
        this.request = undefined;
        this.bonus = x.bonus;
        const request$ = forkJoin([
          this.requestsSvc.bonusToRequest(x.bonus || {}).pipe(tap(r => this.request = r)),
          this.bonusConfigSvc.bonusTypeMap$.pipe(tap(btm => this.bonusTypeMap = btm))
        ]);
        return (x.bonus)
          ? request$
          : of(undefined);
      })
    ).subscribe(() => {
      if (this.bonus !== undefined) {
        this.state = 'ready';
        this.bonusLoadedSubject.next(true);
        calculatRequestTotals(this.request);
      }
      else {
        this.state = 'loading';
      }
    });
  }

  ngAfterViewInit() {
    this.subsMgr.addSub = this.bonusLoadedSubject.pipe(
      filter(x => x),
      tap(this.simplePanel.scrollIntoView)
    ).subscribe();
  }

  ngOnDestroy() {
    this.subsMgr.onDestroy();
  }

  getRewardType(id: number) {
    return this.payoutTypeUiInfo.find(x => x.payoutTypeId === id).rewardType;
  }

  getPayoutSuffix(rt: RewardType) {
    return this.payoutTypeUiInfo.find(x => x.rewardType === rt).suffix;
  }

  isResponsibleOrgPresent() {
    return this.request
      ? this.bonusTypeMap.get(this.bonus.bonusTypeId).isResponsibilityAssigned
      : false;
  }

  panelButtonClick(action: string) {
    switch (action) {
      case 'close':
        this.router.navigate(['../'], { relativeTo: this.route });
        break;
    }
  }
}
