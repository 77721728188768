
<table class="table table-sm small table-striped">
  <thead>
    <tr>
      <th></th>
      <th class="text-center border-start" colspan="5" *ngFor="let heading of bonusStatusHeadings">
        {{heading.label}}
      </th>
    </tr>
    <tr>
      <th>Name</th>
      <ng-container *ngFor="let heading of bonusStatusHeadings">
        <ng-container *ngFor="let pt of payoutTypeUiInfo; let i=index;">
          <ng-container *ngIf="i === 0; else noBorderTemplate">
            <th class="text-end border-start" title={{pt.title}}>{{pt.shortName}}</th>
          </ng-container>
          <ng-template #noBorderTemplate>
            <th class="text-end">{{pt.shortName}}</th>
          </ng-template>
        </ng-container>
        <th class="text-end" title="Count">Cnt</th>
      </ng-container>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let grp of groups">
      <td class="pe-2" style="width: 1%; white-space: nowrap">{{grp.name}}</td>
      <ng-container *ngFor="let heading of bonusStatusHeadings">
        <ng-container *ngFor="let pt of payoutTypeUiInfo; let i=index;">
          <ng-container *ngIf="i === 0; else noBorderDetailsTemplate">
            <td class="text-end border-start" title="{{grp.name}}: {{pt.payoutTypeId}}">
              {{grp.aggregates.get(heading.bonusStatus).payoutTypeAmounts.get(pt.payoutTypeId) | rewardTypeValue:pt.rewardType}}
            </td>
          </ng-container>
          <ng-template #noBorderDetailsTemplate>
            <td class="text-end" title="{{grp.name}}: {{pt.payoutTypeId}}">
              {{grp.aggregates.get(heading.bonusStatus).payoutTypeAmounts.get(pt.payoutTypeId) | rewardTypeValue:pt.rewardType}}
            </td>
          </ng-template>
        </ng-container>
        <td class="text-end" title="{{grp.name}} count">{{grp.aggregates.get(heading.bonusStatus).count}}</td>
      </ng-container>
    </tr>
  </tbody>
  <tfoot>
    <tr class="bg-dark text-light">
      <td><strong>Total</strong></td>
      <ng-container *ngFor="let heading of bonusStatusHeadings">
        <ng-container *ngFor="let pt of payoutTypeUiInfo; let i=index;">
          <ng-container *ngIf="i === 0; else noBorderTotalTemplate">
            <td class="text-end border-start" title="{{pt.payoutTypeId}} total">
              {{total.aggregates.get(heading.bonusStatus).payoutTypeAmounts.get(pt.payoutTypeId) | rewardTypeValue:pt.rewardType}}
            </td>
          </ng-container>
          <ng-template #noBorderTotalTemplate>
            <td class="text-end" title="{{pt.payoutTypeId}} total">
              {{total.aggregates.get(heading.bonusStatus).payoutTypeAmounts.get(pt.payoutTypeId) | rewardTypeValue:pt.rewardType}}
            </td>
          </ng-template>
        </ng-container>
        <td class="text-end" title="{{heading.label}} count tot">
          {{total.aggregates.get(heading.bonusStatus).count}}
        </td>
      </ng-container>
    </tr>
  </tfoot>
</table>
