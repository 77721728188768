<tcc-simplePanel [size]="panelSize"  [options]="{respondsToMainMenu: true}">
  <div panel-header>
    {{processType || 'Processing'}}
  </div>
  <div panel-body>

    <div class="row small">

      <div class="form-group col-auto">
        <label tccLabelForNext>Community</label>
        <app-org-selector noOrgLabel="All Communities (*)" [org]="org" (orgChange)="setOrg($event)"></app-org-selector>
      </div>
      <div class="form-group col-auto" style="width:16.5rem">
        <div class="d-flex" >
            <label class="flex-grow-1" tccLabelForNext >Bonus Type</label>
            <button type="button" class="btn btn-sm btn-skinny btn-light" (click)="setBonusTypes([])"
                    [disabled]="!bonusTypes || bonusTypes.length == 0">
              Clear
            </button>
        </div>
        <app-bonus-types-selector [bonusTypes]="bonusTypes" (bonusTypesChange)="setBonusTypes($event)"></app-bonus-types-selector>
      </div>
      <div class="form-group col-auto">
        <label tccLabelForNext>Min Total</label>
        <input type="number" class="form-control form-control-sm" style="width:5rem"
                [ngModel]="minTotal" (ngModelChange)="minTotal = $event; processFilters()" />
      </div>
      <div class="form-group  col-auto">
        <label tccLabelForNext>Max Total</label>
        <input type="number" class="form-control form-control-sm" style="width:5rem"
                [ngModel]="maxTotal" (ngModelChange)="maxTotal = $event; processFilters()" />
      </div>
      <div class="form-group col-auto">
        <label tccLabelForNext>Recipient</label>
        <app-employee-dropdown noEmployeeLabel="All Recipients"
                                [employee]="recipient"
                                (employeeChange)="setRecipient($event)"></app-employee-dropdown>
      </div>

      <div class="col-auto">
        <app-process-summary [summary]="processSummary"></app-process-summary>
      </div>
    </div>
    <tcc-table tableClass="table-sm small"
               [rows]="filteredBonuses"
               [columns]="columnDefs"
               [enableClientSort]="true">
      <ng-template cellTemplateName="bonusId" let-row="row">
        <a [routerLink]="['./requests-view', { bonusId: row.bonusId }]" class="d-block"
          [title]="row.hasFailedAudits ? 'This bonus has some audits issues.  View it for more details.' : ''"
          [ngClass]="getBonusDetailVisible(row.bonusId) ? 'active fw-bold' : ''">
          {{row.bonusId}}
          <i *ngIf="row.hasFailedAudits" class="fa-fw fas fa-exclamation-triangle text-warning"></i>
        </a>
      </ng-template>
      <ng-template cellTemplateName="createdOn" let-row="row">
        {{row.createdOn | date:'MM/dd/yyyy'}}
      </ng-template>
      <ng-template cellTemplateName="bonusTypeId" let-row="row">
        {{getBonusTypeName(row.bonusTypeId) | async}}
      </ng-template>
      <ng-template cellTemplateName="creator" let-row="row">
        {{getUserName(row.creatorId) | async}}
      </ng-template>
      <ng-template cellTemplateName="monetaryAmt" let-row="row" let-col="col">
        {{getPayoutAmount(col.externalKey, row) | currency}}
      </ng-template>
      <ng-template cellTemplateName="nonMonetaryAmt" let-row="row" let-col="col">
        {{getPayoutAmount(col.externalKey, row)}}{{payoutTypeUiMap.get(convertStrToInt(col.externalKey)).suffix}}
      </ng-template>
      <ng-template cellTemplateName="approve" let-row="row">
        <ng-container *ngTemplateOutlet="ProcessActionTemplate; context: { row: row, processAction: processActionApproved }"></ng-container>
      </ng-template>
      <ng-template cellTemplateName="deny" let-row="row">
        <ng-container *ngTemplateOutlet="ProcessActionTemplate; context: { row: row, processAction: processActionDenied }"></ng-container>
      </ng-template>
      <ng-template #ProcessActionTemplate let-row="row" let-processAction="processAction">
        <input *ngIf="state === 'ready' && (!row.processResultType || row.processResultType == 'NotRun'); else NotInput"
               type="checkbox" [ngModel]="row.processAction == processAction"
               (ngModelChange)="setBonusProcessAction(row, $event ? processAction : undefined)" />
        <ng-template #NotInput>
          <ng-container *ngIf="row.processAction == processAction">
            <ng-container [ngSwitch]="row.processResultType">
              <i *ngSwitchCase="['ValidationFailed', 'Excepted'].indexOf(row.processResultType) !== -1 ? row.processResultType : ''"
                 [title]="row.processResultMessage"
                 class="text-danger fas fa-times"></i>
              <i *ngSwitchCase="'Succeeded'" class="text-success fas fa-check"></i>
              <i *ngSwitchDefault class="fas fa-spinner fa-spin"></i>
            </ng-container>
          </ng-container>
        </ng-template>
      </ng-template>
    </tcc-table>
    <div>
      <app-process-summary-detail [processSummary]="processSummary"></app-process-summary-detail>
    </div>
    <tcc-loadingOverlay *ngIf="state == 'loading'"></tcc-loadingOverlay>
  </div>
  <div panel-footer>
    <div class="row">
      <div class="col-auto">
        <button type="button" class="btn btn-sm btn-secondary me-2"
                [disabled]="state == 'ready' && processSummary.noAction == 0 && processSummary.toDeny == 0"
                (click)="setAllProcessAction(processActionApproved)">
          Mark All Approved
        </button>
        <button type="button" class="btn btn-sm btn-secondary me-2"
                [disabled]="state == 'ready' && processSummary.noAction == 0 && processSummary.toApprove == 0"
                (click)="setAllProcessAction(processActionDenied)">
          Mark All Denied
        </button>
        <button type="button" class="btn btn-sm btn-secondary me-2"
                [disabled]="state == 'ready' && processSummary.toDeny == 0 && processSummary.toApprove == 0"
                (click)="setAllProcessAction(undefined)">
          Clear Selections
        </button>
      </div>
      <div class="col text-end">
        <button type="button" class="btn btn-sm btn-primary"
                [disabled]="state == 'processing' || state == 'ready' && processSummary.toApprove == 0 && processSummary.toDeny == 0"
                (click)="runProcess()">
          Process
        </button>
      </div>
    </div>
  </div>
</tcc-simplePanel>
<router-outlet></router-outlet>
<ng-template #AggRow let-row>
  <tr>
    <td class="text-start">{{row.title}}</td>
    <td>{{row.individualAmt | currency}}</td>
    <td>{{row.officeAmt | currency}}</td>
    <td>{{row.serviceAmt | currency}}</td>
  </tr>
</ng-template>
