import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BonusTypesSelectorComponent } from './bonus-types-selector.component';
import { BonusConfigService } from './bonus-config.service';
import { BonusTypesParamResolver } from './bonus-types-param.resolver';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbDropdownModule,
    SharedModule
  ],
  declarations: [
    BonusTypesSelectorComponent
  ],
  exports: [
    BonusTypesSelectorComponent
  ],
  providers: [
    BonusConfigService,
    BonusTypesParamResolver
  ]
})
export class BonusConfigModule { }
