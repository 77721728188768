/**
 * result from a secvice that is paged
 */
export class PageInfo {
  /** current page, starting at 1 */
  readonly pageNumber: number;

  /** total number of pages */
  readonly pageCount: number;

  constructor(public readonly pageSize: number, public readonly startIndex: number, public readonly totalCount: number) {
    this.pageNumber = Math.floor(startIndex / pageSize) + 1;
    this.pageCount = Math.floor((totalCount - 1) / pageSize) + 1;
  }
}
