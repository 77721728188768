import { PayoutTypeUi } from './../shared/payout-type-ui';
import { Component, OnInit, Input } from '@angular/core';
import { BonusStatus } from '../api-client';
import { SummaryGroup } from './bonus-summary-creator';

@Component({
  selector: 'app-bonus-summary-group',
  templateUrl: './bonus-summary-group.component.html',
  styles: []
})
export class BonusSummaryGroupComponent implements OnInit {
  readonly bonusStatusHeadings = [
    { bonusStatus: BonusStatus.Unknown, label: 'Created' },
    { bonusStatus: BonusStatus.Approved, label: 'Approved' },
    { bonusStatus: BonusStatus.Denied, label: 'Denied' },
    { bonusStatus: BonusStatus.Paid, label: 'Paid' }
  ];

  readonly payoutTypeUiInfo = PayoutTypeUi.payoutTypeUiInfo;

  @Input()
  groups: SummaryGroup[];

  @Input()
  total: SummaryGroup;

  constructor() { }

  ngOnInit() {
  }

}
