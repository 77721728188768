/* eslint-disable @typescript-eslint/member-ordering */
import { DecimalPipe, getLocaleNumberSymbol, NumberSymbol } from '@angular/common';
import { Directive, ElementRef, forwardRef, HostListener, Inject, Input, LOCALE_ID, OnInit, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export const NUMBER_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => NumberAccessorDirective),
  multi: true
};


@Directive({
  selector: '[tccNumberAccessor]',
  providers: [NUMBER_ACCESSOR]
})
export class NumberAccessorDirective implements ControlValueAccessor, OnInit {

  @Input('tccNumberAccessor')
  options: {
      defaultValue?: number;
      format: string;
      noGroupSeperator?: boolean;
  };

  @HostListener('input', ['$event.target.value'])
  onChange = (evt: any) => { };

  @HostListener('blur', [])
  onTouched = () => { };

  private decimalPipe: DecimalPipe;
  private groupSeperator: string;

  constructor(private renderer: Renderer2, private elementRef: ElementRef, @Inject(LOCALE_ID) private localeId: string) {

  }

  ngOnInit() {
    this.groupSeperator = getLocaleNumberSymbol(this.localeId, NumberSymbol.Group);
    this.decimalPipe = new DecimalPipe(this.localeId);
  }

  /**
   * Updates the value property on the directives element with a formatted value.
   */
  @HostListener('blur', ['$event.target.value'])
  writeValue(value: any): void {
    if (!this.isValidNumber(value)) {
      value = this.options.defaultValue;
    }
    this.renderer.setProperty(this.elementRef.nativeElement, 'value', this.formatValue(value));
  }

  registerOnChange(fn: (evt: any) => void): void {
    // create change function that makes a number
    this.onChange = (value: string) => {
      let val = this.convertToNumber(value);
      if (!this.isValidNumber(val)) {
        val = this.options.defaultValue;
      }
      fn(val);
    };
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
  }

  private convertToNumber(value: any) {
    let converted: number;
    if (typeof value === 'number') {
      converted = value;
    }
    else {
      if (typeof value === 'string') {
        value = value.replace(this.groupSeperator, '');
      }
      converted = parseFloat(value);
    }
    return converted;
  }

  private formatValue(value: any) {
    let formatted: string;
    if (typeof value === 'string') {
      value = this.convertToNumber(value);
    }
    if (!isNaN(value)) {
      formatted = this.decimalPipe.transform(value, this.options.format, this.localeId);
      if (formatted && this.options.noGroupSeperator) {
        formatted = formatted.replace(this.groupSeperator, '');
      }
    }
    return formatted;
  }

  /**
   * A little more specific then just isNaN
   */
  private isValidNumber(value: any) {
    return (!isNaN(this.convertToNumber(value)));
  }
}
