import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TccUiModule } from '@tcc/ui';
import { FileUploadModule } from 'ng2-file-upload';
import { AttachmentsService } from './attachments.service';
import { AttachmentsComponent } from './attachments.component';
import { AttachmentThumbnailComponent } from './attachment-thumbnail.component';
import { FileSizePipe } from './file-size.pipe';

@NgModule({
  imports: [
    CommonModule,
    FileUploadModule,
    FormsModule,
    TccUiModule
  ],
  declarations: [
    AttachmentsComponent,
    AttachmentThumbnailComponent,
    FileSizePipe
  ],
  exports: [
    AttachmentsComponent
  ],
  providers: [
    AttachmentsService
  ]
})
export class AttachmentsModule { }
