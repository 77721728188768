import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmployeeSelectorComponent } from './employee-selector.component';
import { EmployeesService } from './employees.service';
import { FormsModule } from '@angular/forms';
import { EmployeeDropdownComponent } from './employee-dropdown.component';
import { RecipientParamResolver } from './recipient-param.resolver';

@NgModule({
  imports: [
    CommonModule,
    FormsModule
  ],
  declarations: [
    EmployeeDropdownComponent,
    EmployeeSelectorComponent
  ],
  exports: [
    EmployeeDropdownComponent,
    EmployeeSelectorComponent
  ],
  providers: [
    EmployeesService,
    RecipientParamResolver
  ]
})
export class EmployeesModule { }
