<div (click)="openAttachment()" class="thumbnailFrame" [ngClass]="{'iconVisible': state == 'loading' || state == 'error'}">
  <img [src]="thumbnailSrc" />
  <i *ngIf="state == 'loading'" class="fa fa-spinner fa-pulse fa-2x innerIcon"></i>
  <i *ngIf="state == 'error'" class="text-danger fa fa-exclamation-triangle fa-2x innerIcon"></i>
</div>
<div>
  <span (click)="openAttachment()" class="thumbnail-name">
    {{attachment?.name}}
  </span>
  <ng-container *ngIf="!readOnly">
    <button *ngIf="!isDeleting" (click)="deleteStart()" type="button" class="btn btn-sm btn-link" style="padding:2px 1px" title="Delete Attachment">
      <i class="fa fa-trash"></i>
    </button>
    <button *ngIf="isDeleting" (click)="deleteComplete()" type="button" class="btn btn-sm btn-link text-danger" style="padding:2px 1px" title="Confirm Delete">
      <i class="fa fa-check text-danger"></i>
    </button>
  </ng-container>
</div>
