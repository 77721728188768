import { Directive, Input, forwardRef } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[appMinValue]',
  providers: [{ provide: NG_VALIDATORS, useExisting: forwardRef(() => MinValueValidator), multi: true }]
})
export class MinValueValidator implements Validator {
  private _minValue: number;
  private onChange: () => void = () => undefined;

  @Input()
  set appMinValue(value: number) {
    if (this._minValue !== value) {
      this._minValue = value;
      this.onChange();
    }
  }

  registerOnValidatorChange(fn: () => void) {
    this.onChange = fn;
  }

  validate(control: AbstractControl): { [key: string]: any } {
    if (control.value !== null && this._minValue !== null && control.value < this._minValue) {
      return {
        minValue: false
      };
    }
    return null;
  }

}
