<tcc-table tableClass="table-sm small"
           [rows]="listRows"
           [columns]="listColumns">
  <ng-template cellTemplateName="isExportDisabled" let-row>
    <span class="d-block"
      [title]="row.isExportDisabled ? 'This batch can not be exported.' : 'This batch is not locked and can be exported.'">
      <i class="fas" [ngClass]="row.isExportDisabled ? 'fa-lock text-dark' : 'fa-lock-open text-muted'"></i>
    </span>
  </ng-template>
  <ng-template cellTemplateName="createdOn" let-row>
    {{row.createdOn | date: 'M/d/yyyy'}}
  </ng-template>
  <ng-template cellTemplateName="lastExportOn" let-row>
    {{(row.lastExportOn | date: 'M/d/yyyy') || 'Never'}}
  </ng-template>
  <ng-template cellTemplateName="total" let-row>
    <ng-container *ngFor="let summary of row.rewardTypeSummary">
      <span class="d-block">
        {{summary.totalCount}}:
        {{summary.totalAmount | rewardTypeValue: summary.rewardType}}{{payoutTypeUI.get(summary.rewardType).suffix}}
      </span>
    </ng-container>
  </ng-template>
  <ng-template cellTemplateName="actionButtons" let-row>
    <span class="btn-group btn-group-sm" style="vertical-align:top">
      <button type="button" class="btn px-1 py-0 btn-secondary" title="Audit Report" (click)="emitEvent(row, 'audit')">
        <i class="fas fa-clipboard-check fa-fw"></i>
      </button>
      <button type="button" class="btn px-1 py-0 btn-warning" title="Lock Batch" *ngIf="!row.isExportDisabled"
        (click)="emitEvent(row, 'lock')">
        <i class="fas fa-lock fa-fw"></i>
      </button>
      <ng-container *ngFor="let r of rewardTypeUi">
        <button type="button" class="btn px-1 py-0 btn-info"
                [title]="'Export ' + r.rewardType + ' Batch'"
                [disabled]="!hasRewardPayouts(row, r.rewardType)"
                *ngIf="!row.isExportDisabled"
                (click)="emitEvent(row,'export', r.rewardType)">
          <i [ngClass]="r.batchIconClass"></i>
        </button>
      </ng-container>      
    </span>
  </ng-template>
</tcc-table>
