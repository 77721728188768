<span [ngSwitch]="status" [title]="status | tccHumanCase">
  <ng-container *ngSwitchCase="'Approved'"><i class="text-success fa fa-thumbs-up fa-fw"></i></ng-container>
  <ng-container *ngSwitchCase="'Denied'"><i class="text-danger fa fa-thumbs-down fa-fw"></i></ng-container>
  <ng-container *ngSwitchCase="'Paid'">
    <span class="fa-stack fa-1x" style="height: 1em; width:1.25em">
      <i class="text-success fas fa-circle fa-stack-1x" style="font-size:1.4em; margin-left:-.2em; line-height: .7em; width:1.25em"></i>
      <i class="text-light fas fa-dollar-sign fa-stack-1x" style="line-height: 1em; width:1.25em"></i>
    </span>
  </ng-container>
  <ng-container *ngSwitchCase="'NoBonus'"><i class="text-muted far fa-circle fa-fw"></i></ng-container>
  <ng-container *ngSwitchCase="'Unapproved'"><i class="text-dark far fa-clock fa-fw"></i></ng-container>
  <ng-container *ngSwitchDefault>{{status | tccHumanCase}}</ng-container>
</span>
