<tcc-simplePanel buttons="close" (buttonClick)="panelButtonClick($event)">
  <div panel-header>
    {{(bonus) ? 'Bonus ' + bonus.bonusId : 'No Bonus Loaded' }}
  </div>
  <div panel-body *ngIf="request; else NoRequest" class="small">
    <app-request-lease [request]="request"></app-request-lease>

    <div class="form-group row">
      <label class="col-2">Description</label>
      <div class="col">
        {{request.description}}
      </div>
    </div>
    <div class="form-group row" *ngIf="request.creator">
      <label class="col-2">Creator</label>
      <div class="col">
        {{request.creator.name}}
      </div>
    </div>
    <div class="form-group row">
      <label class="col-2">Bonus Type</label>
      <div class="col">
        {{request.bonusSchedule?.bonusTypeName}}
      </div>
      <label class="col-2 text-end">Applies On</label>
      <div class="col">
        {{request.appliesOn | date:'M/d/yyyy'}}
      </div>
    </div>
    <div class="form-group row" *ngIf="isResponsibleOrgPresent()">
      <label class="col-2">Bill To</label>
      <div class="col">
        {{request.responsibleOrg?.name}}
      </div>
    </div>
    <div class="form-group row">
      <label class="col-2">Bonus Total</label>
      <div class="col" *ngFor="let t of request.totals">
        {{t.total | rewardTypeValue:t.rewardType}}{{getPayoutSuffix(t.rewardType)}}
      </div>
      <ng-container>
        <label class="col-2 text-end">Status</label>
        <div class="col-4">
          {{request.bonusStatus | tccHumanCase }}
          <app-status-icon [status]="request.bonusStatus"></app-status-icon>
        </div>
      </ng-container>
    </div>
    <div class="form-group row" *ngIf="request.referenceBonusId">
      <label class="col-2">Source</label>
      <div class="col">
        <a [routerLink]="['./', { bonusId: request.referenceBonusId }]" [queryParamsHandling]="'preserve'" >{{request.referenceBonusId}}</a>
      </div>
    </div>
    <div class="form-group row" *ngIf="['Approved', 'Denied', 'Paid'].indexOf(request.bonusStatus) !== -1">
      <label class="col-2">
        <ng-container *ngIf="request.bonusStatus == 'Denied'; else isApproved">Denied By</ng-container>
        <ng-template #isApproved>Approved</ng-template>
      </label>
      <div class="col">
        {{request.approver?.name}}
        on
        {{request.approvalDecisionOn | date: 'M/d/yyyy'}}
      </div>
    </div>
    <div class="form-group row" *ngIf="request.bonusStatus == 'Paid'">
      <label class="col-2">Paid</label>
      <div class="col">
        {{request.payer?.name}}
        on
        {{request.paidOn | date: 'M/d/yyyy'}}
      </div>
    </div>
    <ng-container [ngSwitch]="request.meta?.$formType">
      <ng-container *ngSwitchCase="'Referral'">
        <app-referral-bonus-view [request]="request"></app-referral-bonus-view>
      </ng-container>
    </ng-container>
    <app-audit-failures [audits]="bonus.audits"></app-audit-failures>
    <hr />
    <ng-container *ngFor="let pt of request.payouts">
      <div class="form-group row" *ngIf="pt.amount !== 0">
        <div class="col-3">
          <label tccLabelForNext>{{pt.name}} Bonus</label>
        </div>
        <div class="col" *ngIf="pt.recipients?.length; else NoRecipients">
          <div *ngFor="let r of pt.recipients" class="row">
            <div class="col">
              <div class="float-start">{{r.name}}</div>
              <div class="float-end">
                {{r.amount | rewardTypeValue:getRewardType(r.payoutTypeId)}}{{payoutTypeUiMap.get(r.payoutTypeId).suffix}}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="float-start"><strong>Total</strong></div>
              <div class="float-end">
                <strong>
                  {{pt.amount | rewardTypeValue:getRewardType(pt.payoutTypeId)}}{{payoutTypeUiMap.get(pt.payoutTypeId).suffix}}
                </strong>
              </div>
            </div>
          </div>
        </div>
        <ng-template #NoRecipients><div class="col">No Recipients</div></ng-template>
      </div>
    </ng-container>

    <ng-container *ngIf="bonus.attachments && bonus.attachments.length > 0">
      <hr />
      <app-attachments label="Supporting Documentation"
                   [readOnly]="true"
                   [attachments]="bonus.attachments"></app-attachments>
    </ng-container>
    <tcc-loadingOverlay *ngIf="state != 'ready'"></tcc-loadingOverlay>
  </div>
  <ng-template #NoRequest>
    <div panel-body>
      <ng-container *ngIf="bonus !== null; else LoadFailed">
        Waiting for more information.
        <tcc-loadingOverlay *ngIf="state != 'ready'"></tcc-loadingOverlay>
      </ng-container>
      <ng-template #LoadFailed>
        No information was loaded, possibly because the bonus has been deleted.
      </ng-template>
    </div>
  </ng-template>
</tcc-simplePanel>
