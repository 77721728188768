import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerComponent } from './customer.component';
import { FlexiListControlComponent } from './flexi-list-control.component';
import { MinArrayLengthValidator } from './validators/min-array-length-validator.directive';
import { MinValueValidator } from './validators/min-value-validator.directive';
import { ModalComponent } from './modal.component';
import { NoCommaPipe } from './no-comma.pipe';
import { NotEmptyValidator } from './validators/not-empty-validator.directive';
import { StatusIconComponent } from './status-icon.component';
import { FormsModule } from '@angular/forms';
import { TccUiModule } from '@tcc/ui';
import { DateValueAccessorDirective } from './date-value-accessor.directive';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { NumberAccessorDirective } from './number-accessor.directive';
import { BootstrapValidityDirective } from './bootstrap-validity.directive';
import { RewardTypeValueConverterPipe } from './reward-type-value.pipe';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbTypeaheadModule,
    TccUiModule,
  ],
  declarations: [
    CustomerComponent,
    FlexiListControlComponent,
    MinArrayLengthValidator,
    MinValueValidator,
    ModalComponent,
    NoCommaPipe,
    NotEmptyValidator,
    StatusIconComponent,
    DateValueAccessorDirective,
    NumberAccessorDirective,
    BootstrapValidityDirective,
    RewardTypeValueConverterPipe
  ],
  exports: [
    CustomerComponent,
    FlexiListControlComponent,
    MinArrayLengthValidator,
    MinValueValidator,
    ModalComponent,
    NoCommaPipe,
    NotEmptyValidator,
    StatusIconComponent,
    DateValueAccessorDirective,
    NumberAccessorDirective,
    BootstrapValidityDirective,
    RewardTypeValueConverterPipe
  ]
})
export class SharedModule { }
