import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';


export type ModalComponentResultType = 'cancel' | 'confirm';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html'
})
export class ModalComponent implements OnInit {

  @Output()
  readonly modalResult = new EventEmitter<ModalComponentResultType>();

  @Input()
  title: string;

  constructor() { }

  ngOnInit() {
  }

  emitResult(resultType: ModalComponentResultType) {
    this.modalResult.next(resultType);
  }
}
