<div class="form-row containerDiv">
  <ng-container class="containerNg" *ngTemplateOutlet="ItemTemplate; context: { $implicit: { label: 'Total', value: summary.total } }"></ng-container>
  <ng-container class="containerNg" *ngTemplateOutlet="ItemTemplate; context: { $implicit: { label: 'Hidden', value: summary.hidden } }"></ng-container>
  <ng-container class="containerNg" *ngTemplateOutlet="ItemTemplate; context: { $implicit: { label: 'No Action', value: summary.noAction } }"></ng-container>
  <ng-container class="containerNg" *ngTemplateOutlet="ItemTemplate; context: { $implicit: { label: 'To Approve', value: summary.toApprove } }"></ng-container>
  <ng-container class="containerNg" *ngTemplateOutlet="ItemTemplate; context: { $implicit: { label: 'To Deny', value: summary.toDeny } }"></ng-container>
  <ng-container class="containerNg" *ngTemplateOutlet="ItemTemplate; context: { $implicit: { label: 'Queued', value: summary.queued, valueCssSuffix: 'info' } }"></ng-container>
  <ng-container class="containerNg" *ngTemplateOutlet="ItemTemplate; context: { $implicit: { label: 'Processed', value: summary.processed, valueCssSuffix: 'success' } }"></ng-container>
  <ng-container class="containerNg" *ngTemplateOutlet="ItemTemplate; context: { $implicit: { label: 'Errors', value: summary.failed, valueCssSuffix: 'danger' } }">
  </ng-container>
</div>
<ng-template #ItemTemplate let-item>
  <div class="form-group col-auto text-center" [ngClass]="getTextCssClass(item)" style="min-width:4.2rem">
    <label>{{item.label}}</label>
    <span class="form-control form-control-sm" [ngClass]="getBorderCssClass(item)">{{item.value || 0}}</span>
  </div>
</ng-template>
